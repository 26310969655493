@charset "UTF-8";
/*
  Project: PA Botanicals
  Author: Aleksandra Worhacz
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Sizes
   ========================================================================== */
/* Indexes
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Book.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/Gotham-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("../assets/fonts/GothamBold.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

body {
  overflow-x: hidden;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 0.625rem;
  /* [1] */
  /* 10px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  text-decoration: none;
  color: currentColor;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

main {
  overflow: hidden;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

p,
span,
article {
  font-size: clamp(1.3rem, 1vw, 1.5rem);
  font-weight: 300;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  p,
  span,
  article {
    font-size: 1.4rem;
  }
}

.o-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.o-article__content--narrow {
  max-width: 79rem;
}

.o-article__content--super-narrow {
  max-width: 66rem;
}

.o-article__verse {
  position: relative;
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  line-height: 1.8em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-article__verse {
    font-size: 1.5rem;
  }
}

.o-article__verse p,
.o-article__verse span,
.o-article__verse div {
  line-height: inherit;
}

.o-article__verse--decorated::after {
  content: '';
  position: absolute;
  bottom: -4.5rem;
  display: block;
  width: 5.2rem;
  height: 1.1rem;
  background-color: #71a300;
}

/* stylelint-disable */
/* stylelint-enable */
.o-btn, .c-contact-form_wrapper .gform_button,
.c-contact-form_wrapper .gform_page_footer .button,
.pum-container .gform_button,
.pum-container .gform_page_footer .button,
.c-free-sample__form .gform_button,
.c-free-sample__form .gform_page_footer .button, .gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button, .woocommerce a.added_to_cart,
a.added_to_cart {
  display: inline-block;
  padding: 1.1em 1.56em;
  margin: 0.8em 0;
  border: 0;
  font-family: "Gotham";
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: 0.34em;
  color: currentColor;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  appearance: none;
  transition: 0.3s ease;
  vertical-align: middle;
}

.o-btn--primary {
  background-color: #cff071;
}

.o-btn--secondary {
  background-color: #fce800;
}

.o-btn--tertiary {
  background-color: #fa5746;
  color: white;
}

.o-btn--quaternary {
  background-color: #91c800;
  color: white;
}

.o-btn--quinary {
  background-color: #eaf7d6;
}

.o-btn--septenary {
  font-family: "Roboto", sans-serif;
  font-size: clamp(1.2rem, 0.9vw, 1.4rem);
  font-weight: 300;
  letter-spacing: 0.08em;
  background-color: #fff;
  color: #000;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-btn--septenary {
    font-size: 1.4rem;
  }
}

.o-btn--octonary {
  font-family: "Roboto", sans-serif;
  font-size: clamp(1.2rem, 0.9vw, 1.4rem);
  font-weight: 300;
  letter-spacing: 0.08em;
  background-color: #85b210;
  color: #fff;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-btn--octonary {
    font-size: 1.4rem;
  }
}

.o-btn--round {
  border-radius: 19px;
}

.o-btn--humble {
  padding: 0.7em 1.3em;
  font-size: clamp(1.2rem, 1.3vw, 1.6rem);
  font-weight: 300;
  text-transform: unset;
  letter-spacing: unset;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-btn--humble {
    font-size: 1.6rem;
  }
}

.o-btn--narrow {
  padding: 0.5rem;
}

.o-btn--short {
  padding: 0.7em 1.56em;
}

.o-btn--big {
  font-size: clamp(1.4rem, 1.27vw, 2rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-btn--big {
    font-size: 1.4rem;
  }
  @media (min-width: 768px) {
    .o-btn--big {
      font-size: 2rem;
    }
  }
}

/* stylelint-disable selector-no-qualifying-type */
a.o-btn:hover, .c-contact-form_wrapper a.gform_button:hover,
.c-contact-form_wrapper .gform_page_footer a.button:hover,
.pum-container a.gform_button:hover,
.pum-container .gform_page_footer a.button:hover,
.c-free-sample__form a.gform_button:hover,
.c-free-sample__form .gform_page_footer a.button:hover,
a.added_to_cart:hover, a.o-btn:active, .c-contact-form_wrapper a.gform_button:active,
.c-contact-form_wrapper .gform_page_footer a.button:active,
.pum-container a.gform_button:active,
.pum-container .gform_page_footer a.button:active,
.c-free-sample__form a.gform_button:active,
.c-free-sample__form .gform_page_footer a.button:active,
a.added_to_cart:active, a.o-btn:focus, .c-contact-form_wrapper a.gform_button:focus,
.c-contact-form_wrapper .gform_page_footer a.button:focus,
.pum-container a.gform_button:focus,
.pum-container .gform_page_footer a.button:focus,
.c-free-sample__form a.gform_button:focus,
.c-free-sample__form .gform_page_footer a.button:focus,
a.added_to_cart:focus,
button.o-btn:hover,
.c-contact-form_wrapper button.gform_button:hover,
.c-contact-form_wrapper .gform_page_footer button.button:hover,
.pum-container button.gform_button:hover,
.pum-container .gform_page_footer button.button:hover,
.c-free-sample__form button.gform_button:hover,
.c-free-sample__form .gform_page_footer button.button:hover,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button:hover,
button.o-btn:active,
.c-contact-form_wrapper button.gform_button:active,
.c-contact-form_wrapper .gform_page_footer button.button:active,
.pum-container button.gform_button:active,
.pum-container .gform_page_footer button.button:active,
.c-free-sample__form button.gform_button:active,
.c-free-sample__form .gform_page_footer button.button:active,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button:active,
button.o-btn:focus,
.c-contact-form_wrapper button.gform_button:focus,
.c-contact-form_wrapper .gform_page_footer button.button:focus,
.pum-container button.gform_button:focus,
.pum-container .gform_page_footer button.button:focus,
.c-free-sample__form button.gform_button:focus,
.c-free-sample__form .gform_page_footer button.button:focus,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button:focus,
input[type='submit'].o-btn:hover,
.c-contact-form_wrapper input.gform_button[type='submit']:hover,
.c-contact-form_wrapper .gform_page_footer input.button[type='submit']:hover,
.pum-container input.gform_button[type='submit']:hover,
.pum-container .gform_page_footer input.button[type='submit']:hover,
.c-free-sample__form input.gform_button[type='submit']:hover,
.c-free-sample__form .gform_page_footer input.button[type='submit']:hover,
input[type='submit'].o-btn:active,
.c-contact-form_wrapper input.gform_button[type='submit']:active,
.c-contact-form_wrapper .gform_page_footer input.button[type='submit']:active,
.pum-container input.gform_button[type='submit']:active,
.pum-container .gform_page_footer input.button[type='submit']:active,
.c-free-sample__form input.gform_button[type='submit']:active,
.c-free-sample__form .gform_page_footer input.button[type='submit']:active,
input[type='submit'].o-btn:focus,
.c-contact-form_wrapper input.gform_button[type='submit']:focus,
.c-contact-form_wrapper .gform_page_footer input.button[type='submit']:focus,
.pum-container input.gform_button[type='submit']:focus,
.pum-container .gform_page_footer input.button[type='submit']:focus,
.c-free-sample__form input.gform_button[type='submit']:focus,
.c-free-sample__form .gform_page_footer input.button[type='submit']:focus {
  border-radius: 0;
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.5);
}

/* stylelint-enable selector-no-qualifying-type */
.o-h1 {
  margin-bottom: 0.37em;
  font-family: "Gotham";
  font-size: clamp(2.5rem, 3.25vw, 5.1rem);
  line-height: 1em;
  font-weight: 500;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h1 {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    .o-h1 {
      font-size: 5.1rem;
    }
  }
}

.o-h1--medium {
  font-size: clamp(2.5rem, 3.25vw, 4rem);
  margin-bottom: 1em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h1--medium {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    .o-h1--medium {
      font-size: 4rem;
    }
  }
}

.o-h1 em {
  font-style: normal;
  color: #8ccd00;
}

.o-h2 {
  margin-bottom: 1.33em;
  font-family: "Gotham";
  font-size: clamp(1.8rem, 1.91vw, 3rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: #8ccd00;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h2 {
    font-size: 1.8rem;
  }
  @media (min-width: 768px) {
    .o-h2 {
      font-size: 3rem;
    }
  }
}

.o-h3 {
  font-family: "Gotham";
  font-size: clamp(2rem, 3.25vw, 5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h3 {
    font-size: 2rem;
  }
  @media (min-width: 1024px) {
    .o-h3 {
      font-size: 5em;
    }
  }
}

.o-h3--medium {
  font-size: clamp(2.5rem, 3.25vw, 4rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h3--medium {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    .o-h3--medium {
      font-size: 4rem;
    }
  }
}

.o-h3 em,
.o-h3 b {
  color: #fa5746;
  font-weight: 500;
}

.o-h4 {
  display: block;
  font-size: clamp(1.3rem, 1.28vw, 2rem);
  font-weight: 400;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h4 {
    font-size: 2rem;
  }
}

.o-h5 {
  font-size: clamp(1.3rem, 1.47vw, 2.7rem);
  font-weight: 400;
  color: #41760f;
  letter-spacing: 0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h5 {
    font-size: 2.7rem;
  }
}

.o-h5--small {
  font-size: clamp(1.3rem, 1.47vw, 2rem);
  line-height: 1.3em;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h5--small {
    font-size: 2rem;
  }
}

.o-h6 {
  display: block;
  font-family: "Gotham";
  font-size: clamp(1.3rem, 1.6vw, 2.5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h6 {
    font-size: 2.5rem;
  }
}

.o-h6--small {
  font-size: clamp(1.3rem, 1.28vw, 2rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-h6--small {
    font-size: 2rem;
  }
}

.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}

.o-icon--inline {
  display: inline-flex;
}

.o-icon--icon-back-arrow {
  width: 1.57143em;
}

.o-icon--icon-forward-arrow {
  width: 1.57143em;
}

.o-icon__symbol {
  display: block;
  width: 100%;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
  flex-wrap: wrap;
  min-width: 100%;
}

.o-layout--mobile {
  display: flex;
}

.o-layout--mobile-c-reversed {
  display: flex;
}

@media (max-width: 767.98px) {
  .o-layout--mobile-c-reversed {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout__item--0 {
  width: 0%;
}

.o-layout__item--1 {
  width: 1%;
}

.o-layout__item--2 {
  width: 2%;
}

.o-layout__item--3 {
  width: 3%;
}

.o-layout__item--4 {
  width: 4%;
}

.o-layout__item--5 {
  width: 5%;
}

.o-layout__item--6 {
  width: 6%;
}

.o-layout__item--7 {
  width: 7%;
}

.o-layout__item--8 {
  width: 8%;
}

.o-layout__item--9 {
  width: 9%;
}

.o-layout__item--10 {
  width: 10%;
}

.o-layout__item--11 {
  width: 11%;
}

.o-layout__item--12 {
  width: 12%;
}

.o-layout__item--13 {
  width: 13%;
}

.o-layout__item--14 {
  width: 14%;
}

.o-layout__item--15 {
  width: 15%;
}

.o-layout__item--16 {
  width: 16%;
}

.o-layout__item--17 {
  width: 17%;
}

.o-layout__item--18 {
  width: 18%;
}

.o-layout__item--19 {
  width: 19%;
}

.o-layout__item--20 {
  width: 20%;
}

.o-layout__item--21 {
  width: 21%;
}

.o-layout__item--22 {
  width: 22%;
}

.o-layout__item--23 {
  width: 23%;
}

.o-layout__item--24 {
  width: 24%;
}

.o-layout__item--25 {
  width: 25%;
}

.o-layout__item--26 {
  width: 26%;
}

.o-layout__item--27 {
  width: 27%;
}

.o-layout__item--28 {
  width: 28%;
}

.o-layout__item--29 {
  width: 29%;
}

.o-layout__item--30 {
  width: 30%;
}

.o-layout__item--31 {
  width: 31%;
}

.o-layout__item--32 {
  width: 32%;
}

.o-layout__item--33 {
  width: 33%;
}

.o-layout__item--34 {
  width: 34%;
}

.o-layout__item--35 {
  width: 35%;
}

.o-layout__item--36 {
  width: 36%;
}

.o-layout__item--37 {
  width: 37%;
}

.o-layout__item--38 {
  width: 38%;
}

.o-layout__item--39 {
  width: 39%;
}

.o-layout__item--40 {
  width: 40%;
}

.o-layout__item--41 {
  width: 41%;
}

.o-layout__item--42 {
  width: 42%;
}

.o-layout__item--43 {
  width: 43%;
}

.o-layout__item--44 {
  width: 44%;
}

.o-layout__item--45 {
  width: 45%;
}

.o-layout__item--46 {
  width: 46%;
}

.o-layout__item--47 {
  width: 47%;
}

.o-layout__item--48 {
  width: 48%;
}

.o-layout__item--49 {
  width: 49%;
}

.o-layout__item--50 {
  width: 50%;
}

.o-layout__item--51 {
  width: 51%;
}

.o-layout__item--52 {
  width: 52%;
}

.o-layout__item--53 {
  width: 53%;
}

.o-layout__item--54 {
  width: 54%;
}

.o-layout__item--55 {
  width: 55%;
}

.o-layout__item--56 {
  width: 56%;
}

.o-layout__item--57 {
  width: 57%;
}

.o-layout__item--58 {
  width: 58%;
}

.o-layout__item--59 {
  width: 59%;
}

.o-layout__item--60 {
  width: 60%;
}

.o-layout__item--61 {
  width: 61%;
}

.o-layout__item--62 {
  width: 62%;
}

.o-layout__item--63 {
  width: 63%;
}

.o-layout__item--64 {
  width: 64%;
}

.o-layout__item--65 {
  width: 65%;
}

.o-layout__item--66 {
  width: 66%;
}

.o-layout__item--67 {
  width: 67%;
}

.o-layout__item--68 {
  width: 68%;
}

.o-layout__item--69 {
  width: 69%;
}

.o-layout__item--70 {
  width: 70%;
}

.o-layout__item--71 {
  width: 71%;
}

.o-layout__item--72 {
  width: 72%;
}

.o-layout__item--73 {
  width: 73%;
}

.o-layout__item--74 {
  width: 74%;
}

.o-layout__item--75 {
  width: 75%;
}

.o-layout__item--76 {
  width: 76%;
}

.o-layout__item--77 {
  width: 77%;
}

.o-layout__item--78 {
  width: 78%;
}

.o-layout__item--79 {
  width: 79%;
}

.o-layout__item--80 {
  width: 80%;
}

.o-layout__item--81 {
  width: 81%;
}

.o-layout__item--82 {
  width: 82%;
}

.o-layout__item--83 {
  width: 83%;
}

.o-layout__item--84 {
  width: 84%;
}

.o-layout__item--85 {
  width: 85%;
}

.o-layout__item--86 {
  width: 86%;
}

.o-layout__item--87 {
  width: 87%;
}

.o-layout__item--88 {
  width: 88%;
}

.o-layout__item--89 {
  width: 89%;
}

.o-layout__item--90 {
  width: 90%;
}

.o-layout__item--91 {
  width: 91%;
}

.o-layout__item--92 {
  width: 92%;
}

.o-layout__item--93 {
  width: 93%;
}

.o-layout__item--94 {
  width: 94%;
}

.o-layout__item--95 {
  width: 95%;
}

.o-layout__item--96 {
  width: 96%;
}

.o-layout__item--97 {
  width: 97%;
}

.o-layout__item--98 {
  width: 98%;
}

.o-layout__item--99 {
  width: 99%;
}

.o-layout__item--100 {
  width: 100%;
}

@media (max-width: 479.98px) {
  .o-layout__item--small-down-hide {
    display: none;
  }
}

.o-layout__item--small-0 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-0 {
    width: 0%;
  }
}

.o-layout__item--small-1 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-1 {
    width: 1%;
  }
}

.o-layout__item--small-2 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-2 {
    width: 2%;
  }
}

.o-layout__item--small-3 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-3 {
    width: 3%;
  }
}

.o-layout__item--small-4 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-4 {
    width: 4%;
  }
}

.o-layout__item--small-5 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-5 {
    width: 5%;
  }
}

.o-layout__item--small-6 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-6 {
    width: 6%;
  }
}

.o-layout__item--small-7 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-7 {
    width: 7%;
  }
}

.o-layout__item--small-8 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-8 {
    width: 8%;
  }
}

.o-layout__item--small-9 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-9 {
    width: 9%;
  }
}

.o-layout__item--small-10 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-10 {
    width: 10%;
  }
}

.o-layout__item--small-11 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-11 {
    width: 11%;
  }
}

.o-layout__item--small-12 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-12 {
    width: 12%;
  }
}

.o-layout__item--small-13 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-13 {
    width: 13%;
  }
}

.o-layout__item--small-14 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-14 {
    width: 14%;
  }
}

.o-layout__item--small-15 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-15 {
    width: 15%;
  }
}

.o-layout__item--small-16 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-16 {
    width: 16%;
  }
}

.o-layout__item--small-17 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-17 {
    width: 17%;
  }
}

.o-layout__item--small-18 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-18 {
    width: 18%;
  }
}

.o-layout__item--small-19 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-19 {
    width: 19%;
  }
}

.o-layout__item--small-20 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-20 {
    width: 20%;
  }
}

.o-layout__item--small-21 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-21 {
    width: 21%;
  }
}

.o-layout__item--small-22 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-22 {
    width: 22%;
  }
}

.o-layout__item--small-23 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-23 {
    width: 23%;
  }
}

.o-layout__item--small-24 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-24 {
    width: 24%;
  }
}

.o-layout__item--small-25 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-25 {
    width: 25%;
  }
}

.o-layout__item--small-26 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-26 {
    width: 26%;
  }
}

.o-layout__item--small-27 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-27 {
    width: 27%;
  }
}

.o-layout__item--small-28 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-28 {
    width: 28%;
  }
}

.o-layout__item--small-29 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-29 {
    width: 29%;
  }
}

.o-layout__item--small-30 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-30 {
    width: 30%;
  }
}

.o-layout__item--small-31 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-31 {
    width: 31%;
  }
}

.o-layout__item--small-32 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-32 {
    width: 32%;
  }
}

.o-layout__item--small-33 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-33 {
    width: 33%;
  }
}

.o-layout__item--small-34 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-34 {
    width: 34%;
  }
}

.o-layout__item--small-35 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-35 {
    width: 35%;
  }
}

.o-layout__item--small-36 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-36 {
    width: 36%;
  }
}

.o-layout__item--small-37 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-37 {
    width: 37%;
  }
}

.o-layout__item--small-38 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-38 {
    width: 38%;
  }
}

.o-layout__item--small-39 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-39 {
    width: 39%;
  }
}

.o-layout__item--small-40 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-40 {
    width: 40%;
  }
}

.o-layout__item--small-41 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-41 {
    width: 41%;
  }
}

.o-layout__item--small-42 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-42 {
    width: 42%;
  }
}

.o-layout__item--small-43 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-43 {
    width: 43%;
  }
}

.o-layout__item--small-44 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-44 {
    width: 44%;
  }
}

.o-layout__item--small-45 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-45 {
    width: 45%;
  }
}

.o-layout__item--small-46 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-46 {
    width: 46%;
  }
}

.o-layout__item--small-47 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-47 {
    width: 47%;
  }
}

.o-layout__item--small-48 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-48 {
    width: 48%;
  }
}

.o-layout__item--small-49 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-49 {
    width: 49%;
  }
}

.o-layout__item--small-50 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-50 {
    width: 50%;
  }
}

.o-layout__item--small-51 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-51 {
    width: 51%;
  }
}

.o-layout__item--small-52 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-52 {
    width: 52%;
  }
}

.o-layout__item--small-53 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-53 {
    width: 53%;
  }
}

.o-layout__item--small-54 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-54 {
    width: 54%;
  }
}

.o-layout__item--small-55 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-55 {
    width: 55%;
  }
}

.o-layout__item--small-56 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-56 {
    width: 56%;
  }
}

.o-layout__item--small-57 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-57 {
    width: 57%;
  }
}

.o-layout__item--small-58 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-58 {
    width: 58%;
  }
}

.o-layout__item--small-59 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-59 {
    width: 59%;
  }
}

.o-layout__item--small-60 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-60 {
    width: 60%;
  }
}

.o-layout__item--small-61 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-61 {
    width: 61%;
  }
}

.o-layout__item--small-62 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-62 {
    width: 62%;
  }
}

.o-layout__item--small-63 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-63 {
    width: 63%;
  }
}

.o-layout__item--small-64 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-64 {
    width: 64%;
  }
}

.o-layout__item--small-65 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-65 {
    width: 65%;
  }
}

.o-layout__item--small-66 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-66 {
    width: 66%;
  }
}

.o-layout__item--small-67 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-67 {
    width: 67%;
  }
}

.o-layout__item--small-68 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-68 {
    width: 68%;
  }
}

.o-layout__item--small-69 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-69 {
    width: 69%;
  }
}

.o-layout__item--small-70 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-70 {
    width: 70%;
  }
}

.o-layout__item--small-71 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-71 {
    width: 71%;
  }
}

.o-layout__item--small-72 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-72 {
    width: 72%;
  }
}

.o-layout__item--small-73 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-73 {
    width: 73%;
  }
}

.o-layout__item--small-74 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-74 {
    width: 74%;
  }
}

.o-layout__item--small-75 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-75 {
    width: 75%;
  }
}

.o-layout__item--small-76 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-76 {
    width: 76%;
  }
}

.o-layout__item--small-77 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-77 {
    width: 77%;
  }
}

.o-layout__item--small-78 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-78 {
    width: 78%;
  }
}

.o-layout__item--small-79 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-79 {
    width: 79%;
  }
}

.o-layout__item--small-80 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-80 {
    width: 80%;
  }
}

.o-layout__item--small-81 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-81 {
    width: 81%;
  }
}

.o-layout__item--small-82 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-82 {
    width: 82%;
  }
}

.o-layout__item--small-83 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-83 {
    width: 83%;
  }
}

.o-layout__item--small-84 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-84 {
    width: 84%;
  }
}

.o-layout__item--small-85 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-85 {
    width: 85%;
  }
}

.o-layout__item--small-86 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-86 {
    width: 86%;
  }
}

.o-layout__item--small-87 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-87 {
    width: 87%;
  }
}

.o-layout__item--small-88 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-88 {
    width: 88%;
  }
}

.o-layout__item--small-89 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-89 {
    width: 89%;
  }
}

.o-layout__item--small-90 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-90 {
    width: 90%;
  }
}

.o-layout__item--small-91 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-91 {
    width: 91%;
  }
}

.o-layout__item--small-92 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-92 {
    width: 92%;
  }
}

.o-layout__item--small-93 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-93 {
    width: 93%;
  }
}

.o-layout__item--small-94 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-94 {
    width: 94%;
  }
}

.o-layout__item--small-95 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-95 {
    width: 95%;
  }
}

.o-layout__item--small-96 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-96 {
    width: 96%;
  }
}

.o-layout__item--small-97 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-97 {
    width: 97%;
  }
}

.o-layout__item--small-98 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-98 {
    width: 98%;
  }
}

.o-layout__item--small-99 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-99 {
    width: 99%;
  }
}

.o-layout__item--small-100 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 480px) {
  .o-layout__item--small-100 {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .o-layout__item--medium-down-hide {
    display: none;
  }
}

.o-layout__item--medium-0 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-0 {
    width: 0%;
  }
}

.o-layout__item--medium-1 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-1 {
    width: 1%;
  }
}

.o-layout__item--medium-2 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-2 {
    width: 2%;
  }
}

.o-layout__item--medium-3 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-3 {
    width: 3%;
  }
}

.o-layout__item--medium-4 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-4 {
    width: 4%;
  }
}

.o-layout__item--medium-5 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-5 {
    width: 5%;
  }
}

.o-layout__item--medium-6 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-6 {
    width: 6%;
  }
}

.o-layout__item--medium-7 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-7 {
    width: 7%;
  }
}

.o-layout__item--medium-8 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-8 {
    width: 8%;
  }
}

.o-layout__item--medium-9 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-9 {
    width: 9%;
  }
}

.o-layout__item--medium-10 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-10 {
    width: 10%;
  }
}

.o-layout__item--medium-11 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-11 {
    width: 11%;
  }
}

.o-layout__item--medium-12 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-12 {
    width: 12%;
  }
}

.o-layout__item--medium-13 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-13 {
    width: 13%;
  }
}

.o-layout__item--medium-14 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-14 {
    width: 14%;
  }
}

.o-layout__item--medium-15 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-15 {
    width: 15%;
  }
}

.o-layout__item--medium-16 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-16 {
    width: 16%;
  }
}

.o-layout__item--medium-17 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-17 {
    width: 17%;
  }
}

.o-layout__item--medium-18 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-18 {
    width: 18%;
  }
}

.o-layout__item--medium-19 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-19 {
    width: 19%;
  }
}

.o-layout__item--medium-20 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-20 {
    width: 20%;
  }
}

.o-layout__item--medium-21 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-21 {
    width: 21%;
  }
}

.o-layout__item--medium-22 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-22 {
    width: 22%;
  }
}

.o-layout__item--medium-23 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-23 {
    width: 23%;
  }
}

.o-layout__item--medium-24 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-24 {
    width: 24%;
  }
}

.o-layout__item--medium-25 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-25 {
    width: 25%;
  }
}

.o-layout__item--medium-26 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-26 {
    width: 26%;
  }
}

.o-layout__item--medium-27 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-27 {
    width: 27%;
  }
}

.o-layout__item--medium-28 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-28 {
    width: 28%;
  }
}

.o-layout__item--medium-29 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-29 {
    width: 29%;
  }
}

.o-layout__item--medium-30 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-30 {
    width: 30%;
  }
}

.o-layout__item--medium-31 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-31 {
    width: 31%;
  }
}

.o-layout__item--medium-32 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-32 {
    width: 32%;
  }
}

.o-layout__item--medium-33 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-33 {
    width: 33%;
  }
}

.o-layout__item--medium-34 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-34 {
    width: 34%;
  }
}

.o-layout__item--medium-35 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-35 {
    width: 35%;
  }
}

.o-layout__item--medium-36 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-36 {
    width: 36%;
  }
}

.o-layout__item--medium-37 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-37 {
    width: 37%;
  }
}

.o-layout__item--medium-38 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-38 {
    width: 38%;
  }
}

.o-layout__item--medium-39 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-39 {
    width: 39%;
  }
}

.o-layout__item--medium-40 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-40 {
    width: 40%;
  }
}

.o-layout__item--medium-41 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-41 {
    width: 41%;
  }
}

.o-layout__item--medium-42 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-42 {
    width: 42%;
  }
}

.o-layout__item--medium-43 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-43 {
    width: 43%;
  }
}

.o-layout__item--medium-44 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-44 {
    width: 44%;
  }
}

.o-layout__item--medium-45 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-45 {
    width: 45%;
  }
}

.o-layout__item--medium-46 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-46 {
    width: 46%;
  }
}

.o-layout__item--medium-47 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-47 {
    width: 47%;
  }
}

.o-layout__item--medium-48 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-48 {
    width: 48%;
  }
}

.o-layout__item--medium-49 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-49 {
    width: 49%;
  }
}

.o-layout__item--medium-50 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-50 {
    width: 50%;
  }
}

.o-layout__item--medium-51 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-51 {
    width: 51%;
  }
}

.o-layout__item--medium-52 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-52 {
    width: 52%;
  }
}

.o-layout__item--medium-53 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-53 {
    width: 53%;
  }
}

.o-layout__item--medium-54 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-54 {
    width: 54%;
  }
}

.o-layout__item--medium-55 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-55 {
    width: 55%;
  }
}

.o-layout__item--medium-56 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-56 {
    width: 56%;
  }
}

.o-layout__item--medium-57 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-57 {
    width: 57%;
  }
}

.o-layout__item--medium-58 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-58 {
    width: 58%;
  }
}

.o-layout__item--medium-59 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-59 {
    width: 59%;
  }
}

.o-layout__item--medium-60 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-60 {
    width: 60%;
  }
}

.o-layout__item--medium-61 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-61 {
    width: 61%;
  }
}

.o-layout__item--medium-62 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-62 {
    width: 62%;
  }
}

.o-layout__item--medium-63 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-63 {
    width: 63%;
  }
}

.o-layout__item--medium-64 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-64 {
    width: 64%;
  }
}

.o-layout__item--medium-65 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-65 {
    width: 65%;
  }
}

.o-layout__item--medium-66 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-66 {
    width: 66%;
  }
}

.o-layout__item--medium-67 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-67 {
    width: 67%;
  }
}

.o-layout__item--medium-68 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-68 {
    width: 68%;
  }
}

.o-layout__item--medium-69 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-69 {
    width: 69%;
  }
}

.o-layout__item--medium-70 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-70 {
    width: 70%;
  }
}

.o-layout__item--medium-71 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-71 {
    width: 71%;
  }
}

.o-layout__item--medium-72 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-72 {
    width: 72%;
  }
}

.o-layout__item--medium-73 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-73 {
    width: 73%;
  }
}

.o-layout__item--medium-74 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-74 {
    width: 74%;
  }
}

.o-layout__item--medium-75 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-75 {
    width: 75%;
  }
}

.o-layout__item--medium-76 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-76 {
    width: 76%;
  }
}

.o-layout__item--medium-77 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-77 {
    width: 77%;
  }
}

.o-layout__item--medium-78 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-78 {
    width: 78%;
  }
}

.o-layout__item--medium-79 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-79 {
    width: 79%;
  }
}

.o-layout__item--medium-80 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-80 {
    width: 80%;
  }
}

.o-layout__item--medium-81 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-81 {
    width: 81%;
  }
}

.o-layout__item--medium-82 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-82 {
    width: 82%;
  }
}

.o-layout__item--medium-83 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-83 {
    width: 83%;
  }
}

.o-layout__item--medium-84 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-84 {
    width: 84%;
  }
}

.o-layout__item--medium-85 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-85 {
    width: 85%;
  }
}

.o-layout__item--medium-86 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-86 {
    width: 86%;
  }
}

.o-layout__item--medium-87 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-87 {
    width: 87%;
  }
}

.o-layout__item--medium-88 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-88 {
    width: 88%;
  }
}

.o-layout__item--medium-89 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-89 {
    width: 89%;
  }
}

.o-layout__item--medium-90 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-90 {
    width: 90%;
  }
}

.o-layout__item--medium-91 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-91 {
    width: 91%;
  }
}

.o-layout__item--medium-92 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-92 {
    width: 92%;
  }
}

.o-layout__item--medium-93 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-93 {
    width: 93%;
  }
}

.o-layout__item--medium-94 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-94 {
    width: 94%;
  }
}

.o-layout__item--medium-95 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-95 {
    width: 95%;
  }
}

.o-layout__item--medium-96 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-96 {
    width: 96%;
  }
}

.o-layout__item--medium-97 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-97 {
    width: 97%;
  }
}

.o-layout__item--medium-98 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-98 {
    width: 98%;
  }
}

.o-layout__item--medium-99 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-99 {
    width: 99%;
  }
}

.o-layout__item--medium-100 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 768px) {
  .o-layout__item--medium-100 {
    width: 100%;
  }
}

@media (max-width: 1023.98px) {
  .o-layout__item--large-down-hide {
    display: none;
  }
}

.o-layout__item--large-0 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-0 {
    width: 0%;
  }
}

.o-layout__item--large-1 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-1 {
    width: 1%;
  }
}

.o-layout__item--large-2 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-2 {
    width: 2%;
  }
}

.o-layout__item--large-3 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-3 {
    width: 3%;
  }
}

.o-layout__item--large-4 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-4 {
    width: 4%;
  }
}

.o-layout__item--large-5 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-5 {
    width: 5%;
  }
}

.o-layout__item--large-6 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-6 {
    width: 6%;
  }
}

.o-layout__item--large-7 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-7 {
    width: 7%;
  }
}

.o-layout__item--large-8 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-8 {
    width: 8%;
  }
}

.o-layout__item--large-9 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-9 {
    width: 9%;
  }
}

.o-layout__item--large-10 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-10 {
    width: 10%;
  }
}

.o-layout__item--large-11 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-11 {
    width: 11%;
  }
}

.o-layout__item--large-12 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-12 {
    width: 12%;
  }
}

.o-layout__item--large-13 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-13 {
    width: 13%;
  }
}

.o-layout__item--large-14 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-14 {
    width: 14%;
  }
}

.o-layout__item--large-15 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-15 {
    width: 15%;
  }
}

.o-layout__item--large-16 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-16 {
    width: 16%;
  }
}

.o-layout__item--large-17 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-17 {
    width: 17%;
  }
}

.o-layout__item--large-18 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-18 {
    width: 18%;
  }
}

.o-layout__item--large-19 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-19 {
    width: 19%;
  }
}

.o-layout__item--large-20 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-20 {
    width: 20%;
  }
}

.o-layout__item--large-21 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-21 {
    width: 21%;
  }
}

.o-layout__item--large-22 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-22 {
    width: 22%;
  }
}

.o-layout__item--large-23 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-23 {
    width: 23%;
  }
}

.o-layout__item--large-24 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-24 {
    width: 24%;
  }
}

.o-layout__item--large-25 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-25 {
    width: 25%;
  }
}

.o-layout__item--large-26 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-26 {
    width: 26%;
  }
}

.o-layout__item--large-27 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-27 {
    width: 27%;
  }
}

.o-layout__item--large-28 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-28 {
    width: 28%;
  }
}

.o-layout__item--large-29 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-29 {
    width: 29%;
  }
}

.o-layout__item--large-30 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-30 {
    width: 30%;
  }
}

.o-layout__item--large-31 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-31 {
    width: 31%;
  }
}

.o-layout__item--large-32 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-32 {
    width: 32%;
  }
}

.o-layout__item--large-33 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-33 {
    width: 33%;
  }
}

.o-layout__item--large-34 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-34 {
    width: 34%;
  }
}

.o-layout__item--large-35 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-35 {
    width: 35%;
  }
}

.o-layout__item--large-36 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-36 {
    width: 36%;
  }
}

.o-layout__item--large-37 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-37 {
    width: 37%;
  }
}

.o-layout__item--large-38 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-38 {
    width: 38%;
  }
}

.o-layout__item--large-39 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-39 {
    width: 39%;
  }
}

.o-layout__item--large-40 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-40 {
    width: 40%;
  }
}

.o-layout__item--large-41 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-41 {
    width: 41%;
  }
}

.o-layout__item--large-42 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-42 {
    width: 42%;
  }
}

.o-layout__item--large-43 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-43 {
    width: 43%;
  }
}

.o-layout__item--large-44 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-44 {
    width: 44%;
  }
}

.o-layout__item--large-45 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-45 {
    width: 45%;
  }
}

.o-layout__item--large-46 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-46 {
    width: 46%;
  }
}

.o-layout__item--large-47 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-47 {
    width: 47%;
  }
}

.o-layout__item--large-48 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-48 {
    width: 48%;
  }
}

.o-layout__item--large-49 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-49 {
    width: 49%;
  }
}

.o-layout__item--large-50 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-50 {
    width: 50%;
  }
}

.o-layout__item--large-51 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-51 {
    width: 51%;
  }
}

.o-layout__item--large-52 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-52 {
    width: 52%;
  }
}

.o-layout__item--large-53 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-53 {
    width: 53%;
  }
}

.o-layout__item--large-54 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-54 {
    width: 54%;
  }
}

.o-layout__item--large-55 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-55 {
    width: 55%;
  }
}

.o-layout__item--large-56 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-56 {
    width: 56%;
  }
}

.o-layout__item--large-57 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-57 {
    width: 57%;
  }
}

.o-layout__item--large-58 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-58 {
    width: 58%;
  }
}

.o-layout__item--large-59 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-59 {
    width: 59%;
  }
}

.o-layout__item--large-60 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-60 {
    width: 60%;
  }
}

.o-layout__item--large-61 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-61 {
    width: 61%;
  }
}

.o-layout__item--large-62 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-62 {
    width: 62%;
  }
}

.o-layout__item--large-63 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-63 {
    width: 63%;
  }
}

.o-layout__item--large-64 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-64 {
    width: 64%;
  }
}

.o-layout__item--large-65 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-65 {
    width: 65%;
  }
}

.o-layout__item--large-66 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-66 {
    width: 66%;
  }
}

.o-layout__item--large-67 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-67 {
    width: 67%;
  }
}

.o-layout__item--large-68 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-68 {
    width: 68%;
  }
}

.o-layout__item--large-69 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-69 {
    width: 69%;
  }
}

.o-layout__item--large-70 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-70 {
    width: 70%;
  }
}

.o-layout__item--large-71 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-71 {
    width: 71%;
  }
}

.o-layout__item--large-72 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-72 {
    width: 72%;
  }
}

.o-layout__item--large-73 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-73 {
    width: 73%;
  }
}

.o-layout__item--large-74 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-74 {
    width: 74%;
  }
}

.o-layout__item--large-75 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-75 {
    width: 75%;
  }
}

.o-layout__item--large-76 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-76 {
    width: 76%;
  }
}

.o-layout__item--large-77 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-77 {
    width: 77%;
  }
}

.o-layout__item--large-78 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-78 {
    width: 78%;
  }
}

.o-layout__item--large-79 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-79 {
    width: 79%;
  }
}

.o-layout__item--large-80 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-80 {
    width: 80%;
  }
}

.o-layout__item--large-81 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-81 {
    width: 81%;
  }
}

.o-layout__item--large-82 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-82 {
    width: 82%;
  }
}

.o-layout__item--large-83 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-83 {
    width: 83%;
  }
}

.o-layout__item--large-84 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-84 {
    width: 84%;
  }
}

.o-layout__item--large-85 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-85 {
    width: 85%;
  }
}

.o-layout__item--large-86 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-86 {
    width: 86%;
  }
}

.o-layout__item--large-87 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-87 {
    width: 87%;
  }
}

.o-layout__item--large-88 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-88 {
    width: 88%;
  }
}

.o-layout__item--large-89 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-89 {
    width: 89%;
  }
}

.o-layout__item--large-90 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-90 {
    width: 90%;
  }
}

.o-layout__item--large-91 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-91 {
    width: 91%;
  }
}

.o-layout__item--large-92 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-92 {
    width: 92%;
  }
}

.o-layout__item--large-93 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-93 {
    width: 93%;
  }
}

.o-layout__item--large-94 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-94 {
    width: 94%;
  }
}

.o-layout__item--large-95 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-95 {
    width: 95%;
  }
}

.o-layout__item--large-96 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-96 {
    width: 96%;
  }
}

.o-layout__item--large-97 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-97 {
    width: 97%;
  }
}

.o-layout__item--large-98 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-98 {
    width: 98%;
  }
}

.o-layout__item--large-99 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-99 {
    width: 99%;
  }
}

.o-layout__item--large-100 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1024px) {
  .o-layout__item--large-100 {
    width: 100%;
  }
}

@media (max-width: 1279.98px) {
  .o-layout__item--xlarge-down-hide {
    display: none;
  }
}

.o-layout__item--xlarge-0 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-0 {
    width: 0%;
  }
}

.o-layout__item--xlarge-1 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-1 {
    width: 1%;
  }
}

.o-layout__item--xlarge-2 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-2 {
    width: 2%;
  }
}

.o-layout__item--xlarge-3 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-3 {
    width: 3%;
  }
}

.o-layout__item--xlarge-4 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-4 {
    width: 4%;
  }
}

.o-layout__item--xlarge-5 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-5 {
    width: 5%;
  }
}

.o-layout__item--xlarge-6 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-6 {
    width: 6%;
  }
}

.o-layout__item--xlarge-7 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-7 {
    width: 7%;
  }
}

.o-layout__item--xlarge-8 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-8 {
    width: 8%;
  }
}

.o-layout__item--xlarge-9 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-9 {
    width: 9%;
  }
}

.o-layout__item--xlarge-10 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-10 {
    width: 10%;
  }
}

.o-layout__item--xlarge-11 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-11 {
    width: 11%;
  }
}

.o-layout__item--xlarge-12 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-12 {
    width: 12%;
  }
}

.o-layout__item--xlarge-13 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-13 {
    width: 13%;
  }
}

.o-layout__item--xlarge-14 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-14 {
    width: 14%;
  }
}

.o-layout__item--xlarge-15 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-15 {
    width: 15%;
  }
}

.o-layout__item--xlarge-16 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-16 {
    width: 16%;
  }
}

.o-layout__item--xlarge-17 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-17 {
    width: 17%;
  }
}

.o-layout__item--xlarge-18 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-18 {
    width: 18%;
  }
}

.o-layout__item--xlarge-19 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-19 {
    width: 19%;
  }
}

.o-layout__item--xlarge-20 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-20 {
    width: 20%;
  }
}

.o-layout__item--xlarge-21 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-21 {
    width: 21%;
  }
}

.o-layout__item--xlarge-22 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-22 {
    width: 22%;
  }
}

.o-layout__item--xlarge-23 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-23 {
    width: 23%;
  }
}

.o-layout__item--xlarge-24 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-24 {
    width: 24%;
  }
}

.o-layout__item--xlarge-25 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-25 {
    width: 25%;
  }
}

.o-layout__item--xlarge-26 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-26 {
    width: 26%;
  }
}

.o-layout__item--xlarge-27 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-27 {
    width: 27%;
  }
}

.o-layout__item--xlarge-28 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-28 {
    width: 28%;
  }
}

.o-layout__item--xlarge-29 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-29 {
    width: 29%;
  }
}

.o-layout__item--xlarge-30 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-30 {
    width: 30%;
  }
}

.o-layout__item--xlarge-31 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-31 {
    width: 31%;
  }
}

.o-layout__item--xlarge-32 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-32 {
    width: 32%;
  }
}

.o-layout__item--xlarge-33 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-33 {
    width: 33%;
  }
}

.o-layout__item--xlarge-34 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-34 {
    width: 34%;
  }
}

.o-layout__item--xlarge-35 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-35 {
    width: 35%;
  }
}

.o-layout__item--xlarge-36 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-36 {
    width: 36%;
  }
}

.o-layout__item--xlarge-37 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-37 {
    width: 37%;
  }
}

.o-layout__item--xlarge-38 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-38 {
    width: 38%;
  }
}

.o-layout__item--xlarge-39 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-39 {
    width: 39%;
  }
}

.o-layout__item--xlarge-40 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-40 {
    width: 40%;
  }
}

.o-layout__item--xlarge-41 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-41 {
    width: 41%;
  }
}

.o-layout__item--xlarge-42 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-42 {
    width: 42%;
  }
}

.o-layout__item--xlarge-43 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-43 {
    width: 43%;
  }
}

.o-layout__item--xlarge-44 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-44 {
    width: 44%;
  }
}

.o-layout__item--xlarge-45 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-45 {
    width: 45%;
  }
}

.o-layout__item--xlarge-46 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-46 {
    width: 46%;
  }
}

.o-layout__item--xlarge-47 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-47 {
    width: 47%;
  }
}

.o-layout__item--xlarge-48 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-48 {
    width: 48%;
  }
}

.o-layout__item--xlarge-49 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-49 {
    width: 49%;
  }
}

.o-layout__item--xlarge-50 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-50 {
    width: 50%;
  }
}

.o-layout__item--xlarge-51 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-51 {
    width: 51%;
  }
}

.o-layout__item--xlarge-52 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-52 {
    width: 52%;
  }
}

.o-layout__item--xlarge-53 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-53 {
    width: 53%;
  }
}

.o-layout__item--xlarge-54 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-54 {
    width: 54%;
  }
}

.o-layout__item--xlarge-55 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-55 {
    width: 55%;
  }
}

.o-layout__item--xlarge-56 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-56 {
    width: 56%;
  }
}

.o-layout__item--xlarge-57 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-57 {
    width: 57%;
  }
}

.o-layout__item--xlarge-58 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-58 {
    width: 58%;
  }
}

.o-layout__item--xlarge-59 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-59 {
    width: 59%;
  }
}

.o-layout__item--xlarge-60 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-60 {
    width: 60%;
  }
}

.o-layout__item--xlarge-61 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-61 {
    width: 61%;
  }
}

.o-layout__item--xlarge-62 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-62 {
    width: 62%;
  }
}

.o-layout__item--xlarge-63 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-63 {
    width: 63%;
  }
}

.o-layout__item--xlarge-64 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-64 {
    width: 64%;
  }
}

.o-layout__item--xlarge-65 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-65 {
    width: 65%;
  }
}

.o-layout__item--xlarge-66 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-66 {
    width: 66%;
  }
}

.o-layout__item--xlarge-67 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-67 {
    width: 67%;
  }
}

.o-layout__item--xlarge-68 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-68 {
    width: 68%;
  }
}

.o-layout__item--xlarge-69 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-69 {
    width: 69%;
  }
}

.o-layout__item--xlarge-70 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-70 {
    width: 70%;
  }
}

.o-layout__item--xlarge-71 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-71 {
    width: 71%;
  }
}

.o-layout__item--xlarge-72 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-72 {
    width: 72%;
  }
}

.o-layout__item--xlarge-73 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-73 {
    width: 73%;
  }
}

.o-layout__item--xlarge-74 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-74 {
    width: 74%;
  }
}

.o-layout__item--xlarge-75 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-75 {
    width: 75%;
  }
}

.o-layout__item--xlarge-76 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-76 {
    width: 76%;
  }
}

.o-layout__item--xlarge-77 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-77 {
    width: 77%;
  }
}

.o-layout__item--xlarge-78 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-78 {
    width: 78%;
  }
}

.o-layout__item--xlarge-79 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-79 {
    width: 79%;
  }
}

.o-layout__item--xlarge-80 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-80 {
    width: 80%;
  }
}

.o-layout__item--xlarge-81 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-81 {
    width: 81%;
  }
}

.o-layout__item--xlarge-82 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-82 {
    width: 82%;
  }
}

.o-layout__item--xlarge-83 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-83 {
    width: 83%;
  }
}

.o-layout__item--xlarge-84 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-84 {
    width: 84%;
  }
}

.o-layout__item--xlarge-85 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-85 {
    width: 85%;
  }
}

.o-layout__item--xlarge-86 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-86 {
    width: 86%;
  }
}

.o-layout__item--xlarge-87 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-87 {
    width: 87%;
  }
}

.o-layout__item--xlarge-88 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-88 {
    width: 88%;
  }
}

.o-layout__item--xlarge-89 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-89 {
    width: 89%;
  }
}

.o-layout__item--xlarge-90 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-90 {
    width: 90%;
  }
}

.o-layout__item--xlarge-91 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-91 {
    width: 91%;
  }
}

.o-layout__item--xlarge-92 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-92 {
    width: 92%;
  }
}

.o-layout__item--xlarge-93 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-93 {
    width: 93%;
  }
}

.o-layout__item--xlarge-94 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-94 {
    width: 94%;
  }
}

.o-layout__item--xlarge-95 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-95 {
    width: 95%;
  }
}

.o-layout__item--xlarge-96 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-96 {
    width: 96%;
  }
}

.o-layout__item--xlarge-97 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-97 {
    width: 97%;
  }
}

.o-layout__item--xlarge-98 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-98 {
    width: 98%;
  }
}

.o-layout__item--xlarge-99 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-99 {
    width: 99%;
  }
}

.o-layout__item--xlarge-100 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1280px) {
  .o-layout__item--xlarge-100 {
    width: 100%;
  }
}

@media (max-width: 1599.98px) {
  .o-layout__item--full-down-hide {
    display: none;
  }
}

.o-layout__item--full-0 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-0 {
    width: 0%;
  }
}

.o-layout__item--full-1 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-1 {
    width: 1%;
  }
}

.o-layout__item--full-2 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-2 {
    width: 2%;
  }
}

.o-layout__item--full-3 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-3 {
    width: 3%;
  }
}

.o-layout__item--full-4 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-4 {
    width: 4%;
  }
}

.o-layout__item--full-5 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-5 {
    width: 5%;
  }
}

.o-layout__item--full-6 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-6 {
    width: 6%;
  }
}

.o-layout__item--full-7 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-7 {
    width: 7%;
  }
}

.o-layout__item--full-8 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-8 {
    width: 8%;
  }
}

.o-layout__item--full-9 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-9 {
    width: 9%;
  }
}

.o-layout__item--full-10 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-10 {
    width: 10%;
  }
}

.o-layout__item--full-11 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-11 {
    width: 11%;
  }
}

.o-layout__item--full-12 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-12 {
    width: 12%;
  }
}

.o-layout__item--full-13 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-13 {
    width: 13%;
  }
}

.o-layout__item--full-14 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-14 {
    width: 14%;
  }
}

.o-layout__item--full-15 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-15 {
    width: 15%;
  }
}

.o-layout__item--full-16 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-16 {
    width: 16%;
  }
}

.o-layout__item--full-17 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-17 {
    width: 17%;
  }
}

.o-layout__item--full-18 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-18 {
    width: 18%;
  }
}

.o-layout__item--full-19 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-19 {
    width: 19%;
  }
}

.o-layout__item--full-20 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-20 {
    width: 20%;
  }
}

.o-layout__item--full-21 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-21 {
    width: 21%;
  }
}

.o-layout__item--full-22 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-22 {
    width: 22%;
  }
}

.o-layout__item--full-23 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-23 {
    width: 23%;
  }
}

.o-layout__item--full-24 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-24 {
    width: 24%;
  }
}

.o-layout__item--full-25 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-25 {
    width: 25%;
  }
}

.o-layout__item--full-26 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-26 {
    width: 26%;
  }
}

.o-layout__item--full-27 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-27 {
    width: 27%;
  }
}

.o-layout__item--full-28 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-28 {
    width: 28%;
  }
}

.o-layout__item--full-29 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-29 {
    width: 29%;
  }
}

.o-layout__item--full-30 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-30 {
    width: 30%;
  }
}

.o-layout__item--full-31 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-31 {
    width: 31%;
  }
}

.o-layout__item--full-32 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-32 {
    width: 32%;
  }
}

.o-layout__item--full-33 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-33 {
    width: 33%;
  }
}

.o-layout__item--full-34 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-34 {
    width: 34%;
  }
}

.o-layout__item--full-35 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-35 {
    width: 35%;
  }
}

.o-layout__item--full-36 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-36 {
    width: 36%;
  }
}

.o-layout__item--full-37 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-37 {
    width: 37%;
  }
}

.o-layout__item--full-38 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-38 {
    width: 38%;
  }
}

.o-layout__item--full-39 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-39 {
    width: 39%;
  }
}

.o-layout__item--full-40 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-40 {
    width: 40%;
  }
}

.o-layout__item--full-41 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-41 {
    width: 41%;
  }
}

.o-layout__item--full-42 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-42 {
    width: 42%;
  }
}

.o-layout__item--full-43 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-43 {
    width: 43%;
  }
}

.o-layout__item--full-44 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-44 {
    width: 44%;
  }
}

.o-layout__item--full-45 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-45 {
    width: 45%;
  }
}

.o-layout__item--full-46 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-46 {
    width: 46%;
  }
}

.o-layout__item--full-47 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-47 {
    width: 47%;
  }
}

.o-layout__item--full-48 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-48 {
    width: 48%;
  }
}

.o-layout__item--full-49 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-49 {
    width: 49%;
  }
}

.o-layout__item--full-50 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-50 {
    width: 50%;
  }
}

.o-layout__item--full-51 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-51 {
    width: 51%;
  }
}

.o-layout__item--full-52 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-52 {
    width: 52%;
  }
}

.o-layout__item--full-53 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-53 {
    width: 53%;
  }
}

.o-layout__item--full-54 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-54 {
    width: 54%;
  }
}

.o-layout__item--full-55 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-55 {
    width: 55%;
  }
}

.o-layout__item--full-56 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-56 {
    width: 56%;
  }
}

.o-layout__item--full-57 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-57 {
    width: 57%;
  }
}

.o-layout__item--full-58 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-58 {
    width: 58%;
  }
}

.o-layout__item--full-59 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-59 {
    width: 59%;
  }
}

.o-layout__item--full-60 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-60 {
    width: 60%;
  }
}

.o-layout__item--full-61 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-61 {
    width: 61%;
  }
}

.o-layout__item--full-62 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-62 {
    width: 62%;
  }
}

.o-layout__item--full-63 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-63 {
    width: 63%;
  }
}

.o-layout__item--full-64 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-64 {
    width: 64%;
  }
}

.o-layout__item--full-65 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-65 {
    width: 65%;
  }
}

.o-layout__item--full-66 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-66 {
    width: 66%;
  }
}

.o-layout__item--full-67 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-67 {
    width: 67%;
  }
}

.o-layout__item--full-68 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-68 {
    width: 68%;
  }
}

.o-layout__item--full-69 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-69 {
    width: 69%;
  }
}

.o-layout__item--full-70 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-70 {
    width: 70%;
  }
}

.o-layout__item--full-71 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-71 {
    width: 71%;
  }
}

.o-layout__item--full-72 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-72 {
    width: 72%;
  }
}

.o-layout__item--full-73 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-73 {
    width: 73%;
  }
}

.o-layout__item--full-74 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-74 {
    width: 74%;
  }
}

.o-layout__item--full-75 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-75 {
    width: 75%;
  }
}

.o-layout__item--full-76 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-76 {
    width: 76%;
  }
}

.o-layout__item--full-77 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-77 {
    width: 77%;
  }
}

.o-layout__item--full-78 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-78 {
    width: 78%;
  }
}

.o-layout__item--full-79 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-79 {
    width: 79%;
  }
}

.o-layout__item--full-80 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-80 {
    width: 80%;
  }
}

.o-layout__item--full-81 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-81 {
    width: 81%;
  }
}

.o-layout__item--full-82 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-82 {
    width: 82%;
  }
}

.o-layout__item--full-83 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-83 {
    width: 83%;
  }
}

.o-layout__item--full-84 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-84 {
    width: 84%;
  }
}

.o-layout__item--full-85 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-85 {
    width: 85%;
  }
}

.o-layout__item--full-86 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-86 {
    width: 86%;
  }
}

.o-layout__item--full-87 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-87 {
    width: 87%;
  }
}

.o-layout__item--full-88 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-88 {
    width: 88%;
  }
}

.o-layout__item--full-89 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-89 {
    width: 89%;
  }
}

.o-layout__item--full-90 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-90 {
    width: 90%;
  }
}

.o-layout__item--full-91 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-91 {
    width: 91%;
  }
}

.o-layout__item--full-92 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-92 {
    width: 92%;
  }
}

.o-layout__item--full-93 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-93 {
    width: 93%;
  }
}

.o-layout__item--full-94 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-94 {
    width: 94%;
  }
}

.o-layout__item--full-95 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-95 {
    width: 95%;
  }
}

.o-layout__item--full-96 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-96 {
    width: 96%;
  }
}

.o-layout__item--full-97 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-97 {
    width: 97%;
  }
}

.o-layout__item--full-98 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-98 {
    width: 98%;
  }
}

.o-layout__item--full-99 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-99 {
    width: 99%;
  }
}

.o-layout__item--full-100 {
  /* stylelint-disable-next-line max-nesting-depth */
}

@media (min-width: 1600px) {
  .o-layout__item--full-100 {
    width: 100%;
  }
}

.o-layout--guttered {
  justify-content: space-between;
  margin: 0 -0.5rem;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
  padding: 0.5rem;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-picture {
  position: relative;
  width: 100%;
  height: 100%;
}

.o-picture--ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-picture__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.o-picture__image--contain {
  object-fit: contain;
}

.o-ratio-box {
  position: relative;
  z-index: 1;
  height: 0;
  padding-bottom: var(--ratio-percentage-basic);
}

@media (min-width: 480px) {
  .o-ratio-box {
    padding-bottom: var(--ratio-percentage-small);
  }
}

@media (min-width: 768px) {
  .o-ratio-box {
    padding-bottom: var(--ratio-percentage-medium);
  }
}

@media (min-width: 1024px) {
  .o-ratio-box {
    padding-bottom: var(--ratio-percentage-large);
  }
}

@media (min-width: 1280px) {
  .o-ratio-box {
    padding-bottom: var(--ratio-percentage-xlarge);
  }
}

@media (min-width: 1600px) {
  .o-ratio-box {
    padding-bottom: var(--ratio-percentage-full);
  }
}

.o-ratio-box__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* stylelint-disable */
.tax-product_cat .u-juc-sb > .o-ratio-box {
  padding: 0;
}

/* stylelint-enable */
.o-section {
  margin-bottom: clamp(4rem, 69vw, 10rem);
  margin-top: clamp(2.4rem, 38vw, 6rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section {
    margin-bottom: 2.4rem;
    margin-top: 4rem;
  }
  @media (min-width: 768px) {
    .o-section {
      margin-bottom: 10rem;
      margin-top: 6rem;
    }
  }
}

.o-section--upper {
  position: relative;
  z-index: 1;
}

.o-section--tall {
  margin-bottom: clamp(6.8rem, 10.8vw, 17rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--tall {
    margin-bottom: 6.8rem;
  }
  @media (min-width: 768px) {
    .o-section--tall {
      margin-bottom: 17rem;
    }
  }
}

.o-section--giant {
  margin-bottom: clamp(2.4rem, 18.89vw, 29.6rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--giant {
    margin-bottom: 2.4rem;
  }
  @media (min-width: 768px) {
    .o-section--giant {
      margin-bottom: 29.6rem;
    }
  }
}

.o-section--short {
  margin-bottom: clamp(2.4rem, 38vw, 6rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--short {
    margin-bottom: 2.4rem;
  }
  @media (min-width: 768px) {
    .o-section--short {
      margin-bottom: 6rem;
    }
  }
}

.o-section--more-top-space {
  margin-top: clamp(4rem, 69vw, 10rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--more-top-space {
    margin-top: 4rem;
  }
  @media (min-width: 768px) {
    .o-section--more-top-space {
      margin-top: 10rem;
    }
  }
}

.o-section--decorated {
  position: relative;
}

.o-section--image {
  position: relative;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  padding-bottom: clamp(4rem, 69vw, 10rem);
  padding-top: clamp(2.4rem, 38vw, 6rem);
  margin: 0;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--image {
    padding-bottom: 2.4rem;
    padding-top: 4rem;
  }
  @media (min-width: 768px) {
    .o-section--image {
      padding-bottom: 10rem;
      padding-top: 6rem;
    }
  }
}

.o-section--colored {
  padding-bottom: clamp(4rem, 69vw, 10rem);
  padding-top: clamp(2.4rem, 38vw, 6rem);
  margin: 0;
  background-color: var(--bg-color);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-section--colored {
    padding-bottom: 2.4rem;
    padding-top: 4rem;
  }
  @media (min-width: 768px) {
    .o-section--colored {
      padding-bottom: 10rem;
      padding-top: 6rem;
    }
  }
}

.o-section__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.o-section__headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b3;
  margin-bottom: 4.3rem;
}

.o-section__headline--white {
  color: white;
  border-color: white;
}

.o-section__decoration {
  position: absolute;
  display: none;
  transform: translate(var(--x), var(--y)) rotate(var(--rotate));
  width: var(--width);
  pointer-events: none;
}

.o-section__decoration--top-left {
  top: 0;
  left: 0;
}

.o-section__decoration--top-right {
  top: 0;
  right: 0;
}

.o-section__decoration--bottom-right {
  bottom: 0;
  right: 0;
}

.o-section__decoration--bottom-left {
  bottom: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .o-section__decoration {
    display: inherit;
  }
}

.home .o-section--upper .o-article {
  position: relative;
  z-index: 100;
  background-color: white;
}

@media (max-width: 767.98px) {
  .home .o-section--upper .o-layout__item:last-child {
    margin-top: clamp(4rem, 69vw, 10rem);
  }
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-table {
    font-size: 1.2rem;
  }
  @media (min-width: 768px) {
    .o-table {
      font-size: 1.7rem;
    }
  }
}

.o-table th {
  font-weight: 400;
  text-align: center;
  padding: 1rem;
}

.o-table__head th {
  border-bottom: 1px solid #cccccc;
}

.o-table__body td {
  border-bottom: 1px solid #cccccc;
}

.o-table__cell {
  text-align: center;
  padding: 1rem;
}

.o-table__cell--name {
  text-align: left;
}

.o-table__cell--green {
  background-color: #eaf7d6;
}

/* stylelint-disable max-nesting-depth */
@media (max-width: 767.98px) {
  .o-table--responsive {
    display: block;
  }
  .o-table--responsive .o-table__body,
  .o-table--responsive .o-table__foot {
    display: block;
  }
  .o-table--responsive .o-table__body td,
  .o-table--responsive .o-table__foot td {
    border: 0;
  }
  .o-table--responsive .o-table__head {
    display: none;
  }
  .o-table--responsive .o-table__row {
    display: block;
    margin-bottom: 2rem;
    border-top: 1px solid #cccccc;
  }
  .o-table--responsive .o-table__cell {
    display: block;
  }
  .o-table--responsive .o-table__cell:not(.o-table__cell--mobile-full) {
    padding-left: 35%;
    position: relative;
  }
  .o-table--responsive .o-table__cell:not(.o-table__cell--mobile-full)::before {
    content: attr(data-th) ": ";
    position: absolute;
    left: 1rem;
  }
  .o-table--responsive .o-table__cell--name {
    text-align: center;
  }
  .o-table--responsive .o-table__cell--empty {
    display: none;
  }
}

/* stylelint-enable max-nesting-depth */
/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: clamp(28rem, 90vw, 114rem);
  /* stylelint-disable length-zero-no-unit */
  /* stylelint-enable length-zero-no-unit */
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .o-wrapper {
    width: 90%;
    max-width: 114rem;
  }
}

.o-wrapper--narrow {
  padding-left: clamp(1px, 3.82vw, 6rem);
  padding-right: clamp(1px, 3.82vw, 6rem);
}

.c-account-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
}

.c-account-form--green {
  --background-color: 165, 204, 30;
  color: white;
}

.c-account-form--white {
  --background-color: 255, 255, 255;
  color: black;
}

.c-account-form__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3.3rem;
  margin-bottom: 0.9rem;
  font-family: "Gotham";
  font-size: clamp(2rem, 3vw, 2.5rem);
  background-color: rgba(var(--background-color), 0.45);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-account-form__head {
    font-size: 1.3rem;
  }
  @media (min-width: 1024px) {
    .c-account-form__head {
      font-size: 2.5rem;
    }
  }
}

.c-account-form__head h2 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

.c-account-form__form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: clamp(1.5rem, 3.83vw, 6rem);
  margin-bottom: 0.9rem;
  background-color: rgba(var(--background-color), 0.65);
}

.woocommerce .c-account-form__form {
  border-radius: 0 !important;
  border: 0 !important;
  margin-top: 0 !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-account-form__form {
    padding: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .c-account-form__form {
    padding: 4rem 6rem 3rem;
  }
}

.c-account-form--white .c-account-form__form {
  background-color: rgba(var(--background-color), 0.85);
}

.c-account-form__bottom {
  padding: clamp(1.5rem, 2.56vw, 3rem) clamp(1.5rem, 3vw, 6rem);
  font-size: 1.4rem;
  text-align: center;
  background-color: rgba(var(--background-color), 0.31);
}

@media (min-width: 480px) {
  .c-account-form__bottom {
    display: flex;
    align-items: center;
    text-align: inherit;
  }
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-account-form__bottom {
    padding: 1.5rem;
  }
  @media (min-width: 1024px) {
    .c-account-form__bottom {
      padding: 3rem 6rem;
    }
  }
}

.c-account-form__label {
  display: block;
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 300;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-account-form__label {
    font-size: 1.6rem;
  }
}

.c-account-form__input {
  width: 100%;
  padding: 0.9rem;
  border: 1px solid #b3b3b3;
  font-size: 1.6rem;
  background-color: transparent;
}

.c-account-form--green .c-account-form__input {
  color: white;
  border-color: white;
}

.c-account-form__link {
  font-size: 1.4rem;
}

.c-account-form__return {
  display: inline-block;
  margin-left: 1rem;
}

.c-account-form__links {
  display: flex;
  justify-content: center;
}

@media (min-width: 480px) {
  .c-account-form__links {
    margin-left: 1rem;
  }
}

@media (min-width: 1024px) {
  .c-account-form__links {
    margin-left: auto;
  }
}

.c-account-form__bottom-link {
  display: inline-block;
  margin: 0.4rem;
  padding: 1rem 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .c-account-form__bottom-link {
    padding: 0.7rem 3rem;
  }
}

.c-account-form__bottom-link--facebook {
  background-color: #2e5dac;
}

.c-account-form__bottom-link--google {
  background-color: #4094d4;
}

.c-account-form__row {
  width: 100% !important;
}

.c-carousel-aside-item {
  height: 100%;
  background-size: cover;
}

@media (min-width: 1024px) {
  .c-carousel-aside-item {
    margin: 0 0.5rem;
  }
}

.c-carousel-aside-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 100%;
  margin-left: auto;
  padding-right: 1rem;
  text-align: center;
}

.c-carousel-aside-item__heading {
  margin: 0;
  color: white;
}

.c-carousel-aside-item__code-label {
  font-size: clamp(1.2rem, 1.4vw, 2.2rem);
  color: white;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-carousel-aside-item__code-label {
    font-size: 1.2rem;
  }
  @media (min-width: 768px) {
    .c-carousel-aside-item__code-label {
      font-size: 2.2rem;
    }
  }
}

.c-carousel-aside-item__code {
  font-weight: 500;
  letter-spacing: inherit;
}

.c-carousel {
  position: relative;
}

@media (min-width: 1280px) {
  .c-carousel--aside {
    width: 200%;
  }
}

@media (min-width: 1280px) {
  .c-carousel--aside::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    background-color: white;
    pointer-events: none;
  }
}

.c-carousel__arrow {
  position: absolute;
  top: 50%;
  z-index: 5;
  padding: 1rem;
  border: 0;
  font-size: 1.8rem;
  background-color: transparent;
  appearance: none;
}

.c-carousel--regular .c-carousel__arrow {
  top: calc(50% - 4rem);
}

.c-carousel__arrow--prev {
  left: 0;
  transform: translate(-33%, -50%);
}

@media (min-width: 768px) {
  .c-carousel__arrow--prev {
    transform: translate(-100%, -50%);
  }
}

.c-carousel__arrow--next {
  right: 0;
  transform: translate(33%, -50%);
}

@media (min-width: 768px) {
  .c-carousel__arrow--next {
    transform: translate(100%, -50%);
  }
}

@media (min-width: 768px) {
  .c-carousel--aside .c-carousel__arrow--next {
    transform: translate(calc(100% + 0.5rem), -50%);
  }
}

@media (min-width: 1280px) {
  .c-carousel--aside .c-carousel__arrow--next {
    right: 50%;
  }
}

.c-cart__image {
  width: 18rem;
  margin: auto;
}

.c-cart__remove-button {
  margin: 0;
  font-size: 3rem;
  color: #fa5746;
}

.c-cart__additions {
  padding-bottom: clamp(2rem, 5.1vw, 8rem);
  margin-top: clamp(2rem, 3.82vw, 6rem);
  margin-bottom: clamp(1rem, 2.55vw, 4rem);
  border-bottom: 1px solid #cccccc;
  font-size: clamp(1.2rem, 0.89vw, 1.4rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-cart__additions {
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  @media (min-width: 1024px) {
    .c-cart__additions {
      padding-bottom: 8rem;
      margin-top: 6rem;
      margin-bottom: 4rem;
      font-size: 1.4rem;
    }
  }
}

.c-cart__coupon {
  display: flex;
  margin-bottom: 5rem;
  border: 1px solid #cccccc;
}

.c-cart__coupon-input {
  flex: 1 1 auto;
  width: 100%;
  padding: 2rem;
  border: 0;
  font-size: 1.6rem;
}

.c-cart__coupon-button {
  margin: 0;
  font-weight: 500;
}

.c-cart__additions-label {
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
  font-weight: 400;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-cart__additions-label {
    font-size: 1.5rem;
  }
}

.c-cart__buttons {
  text-align: right;
}

@media (min-width: 768px) {
  .c-cart__button {
    min-width: 15.6rem;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* stylelint-disable */
.gform_heading {
  display: none;
}

.c-contact-form_wrapper .c-contact-form,
.pum-container .c-contact-form,
.c-free-sample__form .c-contact-form {
  margin-top: 2em;
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.27vw, 2rem);
  letter-spacing: -0.02em;
  background-color: white;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-contact-form_wrapper .c-contact-form,
  .pum-container .c-contact-form,
  .c-free-sample__form .c-contact-form {
    font-size: 1.6rem;
  }
}

.c-contact-form_wrapper ul li.gfield,
.pum-container ul li.gfield,
.c-free-sample__form ul li.gfield {
  padding-right: 0 !important;
  margin-bottom: clamp(1rem, 3vw, 4rem);
  margin-top: 0 !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-contact-form_wrapper ul li.gfield,
  .pum-container ul li.gfield,
  .c-free-sample__form ul li.gfield {
    margin-bottom: 1.4rem;
  }
  @media (min-width: 768px) {
    .c-contact-form_wrapper ul li.gfield,
    .pum-container ul li.gfield,
    .c-free-sample__form ul li.gfield {
      margin-bottom: 4rem;
    }
  }
}

.c-contact-form_wrapper .gform_heading,
.pum-container .gform_heading,
.c-free-sample__form .gform_heading {
  display: none;
}

.c-contact-form_wrapper .gform_body,
.pum-container .gform_body,
.c-free-sample__form .gform_body {
  padding: 1.5rem;
  border: 1px solid #cccccc;
}

@media (min-width: 480px) {
  .c-contact-form_wrapper .gform_body,
  .pum-container .gform_body,
  .c-free-sample__form .gform_body {
    padding: 2.5em;
  }
}

.c-contact-form_wrapper.c-footer__newsletter-form .gform_body,
.pum-container.c-footer__newsletter-form .gform_body,
.c-free-sample__form.c-footer__newsletter-form .gform_body {
  padding: 0;
  border: none;
}

.c-contact-form_wrapper .gfield_label,
.pum-container .gfield_label,
.c-free-sample__form .gfield_label {
  display: block;
  margin-bottom: 0.4rem;
  font-weight: 400 !important;
}

.c-contact-form_wrapper input[type='text'],
.c-contact-form_wrapper select,
.pum-container input[type='text'],
.pum-container select,
.c-free-sample__form input[type='text'],
.c-free-sample__form select {
  width: 100% !important;
  border-radius: 0;
  border: 1px solid #cccccc;
  font-size: 1.6rem !important;
}

@media (min-width: 768px) {
  .c-contact-form_wrapper input[type='text'],
  .c-contact-form_wrapper select,
  .pum-container input[type='text'],
  .pum-container select,
  .c-free-sample__form input[type='text'],
  .c-free-sample__form select {
    padding: 1.4rem !important;
  }
}

.c-contact-form_wrapper .gfield_error [aria-invalid=true],
.pum-container .gfield_error [aria-invalid=true],
.c-free-sample__form .gfield_error [aria-invalid=true] {
  border: 1px solid #cccccc !important;
}

.c-contact-form_wrapper .gfield_error label,
.c-contact-form_wrapper .gfield_error legend,
.pum-container .gfield_error label,
.pum-container .gfield_error legend,
.c-free-sample__form .gfield_error label,
.c-free-sample__form .gfield_error legend {
  color: #000 !important;
}

.c-contact-form_wrapper input[type='text']:not([type='checkbox']),
.pum-container input[type='text']:not([type='checkbox']),
.c-free-sample__form input[type='text']:not([type='checkbox']) {
  appearance: none;
}

.c-contact-form_wrapper .gform_fields,
.pum-container .gform_fields,
.c-free-sample__form .gform_fields {
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'input1' 'input2' 'input3' 'message';
}

@media (min-width: 768px) {
  .c-contact-form_wrapper .gform_fields,
  .pum-container .gform_fields,
  .c-free-sample__form .gform_fields {
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
    grid-template-areas: 'input1 message' 'input2 message' 'input3 message';
  }
}

.c-contact-form_wrapper .gform_button,
.c-contact-form_wrapper .gform_page_footer .button,
.pum-container .gform_button,
.pum-container .gform_page_footer .button,
.c-free-sample__form .gform_button,
.c-free-sample__form .gform_page_footer .button {
  display: block !important;
  min-width: 7.37em;
  margin-top: 0.8em !important;
  margin-bottom: 0.8em !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  padding: 0.7em 1.56em;
  font-size: 1.6rem !important;
  font-weight: 400;
  letter-spacing: 0.034em;
  color: #fff;
  text-transform: none;
  background-color: #fa5746;
  cursor: pointer;
}

.c-contact-form_wrapper .gform_button.gform_previous_button,
.c-contact-form_wrapper .gform_page_footer .button.gform_previous_button,
.pum-container .gform_button.gform_previous_button,
.pum-container .gform_page_footer .button.gform_previous_button,
.c-free-sample__form .gform_button.gform_previous_button,
.c-free-sample__form .gform_page_footer .button.gform_previous_button {
  color: #000 !important;
}

.c-contact-form_wrapper .gform_button:hover, .c-contact-form_wrapper .gform_button:focus,
.c-contact-form_wrapper .gform_page_footer .button:hover,
.c-contact-form_wrapper .gform_page_footer .button:focus,
.pum-container .gform_button:hover,
.pum-container .gform_button:focus,
.pum-container .gform_page_footer .button:hover,
.pum-container .gform_page_footer .button:focus,
.c-free-sample__form .gform_button:hover,
.c-free-sample__form .gform_button:focus,
.c-free-sample__form .gform_page_footer .button:hover,
.c-free-sample__form .gform_page_footer .button:focus {
  background-color: #fa4937;
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.5);
}

.c-contact-form_wrapper .gform_button:disabled,
.c-contact-form_wrapper .gform_page_footer .button:disabled,
.pum-container .gform_button:disabled,
.pum-container .gform_page_footer .button:disabled,
.c-free-sample__form .gform_button:disabled,
.c-free-sample__form .gform_page_footer .button:disabled {
  background-color: #fa5746;
  box-shadow: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.c-ncd__popup .c-contact-form_wrapper .gform_button.gform_previous_button, .c-ncd__popup
.c-contact-form_wrapper .gform_page_footer .button.gform_previous_button, .c-ncd__popup
.pum-container .gform_button.gform_previous_button, .c-ncd__popup
.pum-container .gform_page_footer .button.gform_previous_button, .c-ncd__popup
.c-free-sample__form .gform_button.gform_previous_button, .c-ncd__popup
.c-free-sample__form .gform_page_footer .button.gform_previous_button {
  display: none !important;
}

.c-contact-form_wrapper .gform_footer,
.pum-container .gform_footer,
.c-free-sample__form .gform_footer {
  margin: 0 !important;
  padding: 0 !important;
}

.c-contact-form_wrapper .gform_wrapper .gfield_validation_message,
.c-contact-form_wrapper .gform_wrapper .validation_message,
.pum-container .gform_wrapper .gfield_validation_message,
.pum-container .gform_wrapper .validation_message,
.c-free-sample__form .gform_wrapper .gfield_validation_message,
.c-free-sample__form .gform_wrapper .validation_message {
  background: transparent;
  border: none !important;
  font-size: 13px !important;
  margin-top: 3px;
  margin-bottom: -5px;
  padding: 0 !important;
}

.c-contact-form_wrapper.c-footer__newsletter-form .gform_wrapper .gfield_validation_message,
.c-contact-form_wrapper.c-footer__newsletter-form .gform_wrapper .validation_message,
.pum-container.c-footer__newsletter-form .gform_wrapper .gfield_validation_message,
.pum-container.c-footer__newsletter-form .gform_wrapper .validation_message,
.c-free-sample__form.c-footer__newsletter-form .gform_wrapper .gfield_validation_message,
.c-free-sample__form.c-footer__newsletter-form .gform_wrapper .validation_message {
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.c-contact-form_wrapper .gform_wrapper .gform_validation_errors,
.pum-container .gform_wrapper .gform_validation_errors,
.c-free-sample__form .gform_wrapper .gform_validation_errors {
  border-radius: 0;
}

.c-contact-form_wrapper.c-footer__newsletter-form .gform_wrapper .gform_validation_errors,
.pum-container.c-footer__newsletter-form .gform_wrapper .gform_validation_errors,
.c-free-sample__form.c-footer__newsletter-form .gform_wrapper .gform_validation_errors {
  display: none;
}

@media only screen and (max-width: 641px) {
  .gform_wrapper.gravity-theme input:not([type=radio]):not([type='checkbox']):not([type=image]):not([type=file]) {
    line-height: 1.2 !important;
  }
}

.c-ncd__popup .gform_body {
  padding: 0;
  border: none;
}

.c-ncd__popup .gform_button {
  margin-left: 0 !important;
  width: 100%;
}

.gform_confirmation_wrapper {
  padding: 10px;
  background: #fff;
  color: #71a300 !important;
  font-weight: 500;
  font-size: 14px !important;
}

.c-contact-form__field {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
}

.c-contact-form__field:nth-child(1) {
  grid-area: input1;
}

.c-contact-form__field:nth-child(2) {
  grid-area: input2;
}

.c-contact-form__field:nth-child(3) {
  grid-area: input3;
}

.c-contact-form__message {
  display: flex;
  flex-direction: column;
  grid-area: message;
  margin-top: 0 !important;
  padding-right: 0 !important;
}

.c-contact-form__message .ginput_container {
  flex: 1 1 auto;
}

.c-contact-form__message textarea {
  height: 100% !important;
  border-radius: 0;
  border: 1px solid #cccccc;
  font-size: 1.6rem !important;
}

@media (min-width: 768px) {
  .c-contact-form__message textarea {
    padding: 1.4rem !important;
  }
}

.c-contact-form__message textarea:not([type='checkbox']) {
  appearance: none;
}

/* stylelint-enable */
/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 1.3rem;
  line-height: 1.63em;
  font-weight: 300;
  color: white;
}

.c-footer span,
.c-footer div,
.c-footer p {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

@media (min-width: 1024px) {
  .c-footer__row {
    flex-wrap: nowrap;
  }
}

.c-footer__top {
  padding: 4rem 0;
  background-color: #649142;
}

@media (min-width: 1024px) {
  .c-footer__lastcol {
    padding-left: 5rem;
    padding-right: 2rem;
  }
}

.c-footer__navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-footer__navigation-item {
  width: 50%;
}

@media (min-width: 768px) {
  .c-footer__navigation-item {
    width: 33.33%;
  }
}

.c-footer__navigation-link {
  padding-right: 1rem;
  font-weight: 300;
  line-height: 1.76em;
  color: inherit;
}

.c-footer__bottom {
  background-color: #4c6e32;
  padding: 2.8rem 0 3.9rem;
}

@media (min-width: 768px) {
  .c-footer__collection {
    display: flex;
    align-items: center;
  }
}

.c-footer__collection--payments {
  justify-content: flex-end;
}

.c-footer__collection-label {
  display: block;
  padding: 1rem 0;
  font-weight: 500;
}

@media (min-width: 768px) {
  .c-footer__collection-label {
    padding: 0 1rem 0 0;
  }
}

.c-footer__collection-item {
  margin: 0 5px;
}

.c-footer__collection-item:last-child {
  margin-right: 0;
}

.c-footer__collection-item--payment {
  max-height: 3rem;
  max-width: 25%;
}

.c-footer__collection-item--payment.is-long {
  max-width: 50%;
}

.c-footer__collection-item--social {
  display: inline-block;
  padding: 0.5em;
  margin: 0 0.3rem;
  border: 1px solid currentColor;
  border-radius: 50%;
  font-size: 1.2rem;
  color: #91c800;
}

.c-footer__separator {
  margin: 1rem 0;
  background-color: #91c800;
  height: 1px;
}

@media (min-width: 768px) {
  .c-footer__copyright {
    text-align: right;
  }
}

.c-footer__newsletter {
  margin-top: 16px;
}

@media (min-width: 1024px) {
  .c-footer__newsletter {
    margin-top: 0;
  }
}

.c-footer__newsletter + .c-footer__disclaimer {
  margin-top: 16px;
}

.c-form {
  margin-top: 2em;
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.27vw, 2rem);
  letter-spacing: -0.02em;
  background-color: white;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-form {
    font-size: 1.6rem;
  }
}

.c-form--plain {
  margin-top: 0;
  background-color: transparent;
}

.c-form__inner {
  padding: 1.5rem;
  border: 1px solid #cccccc;
}

.c-form--plain .c-form__inner {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 480px) {
  .c-form__inner {
    padding: 2.5em;
  }
}

.c-form__label {
  display: block;
  margin-bottom: 0.4rem;
}

.c-form__input {
  border-radius: 0;
  border: 1px solid #cccccc;
  width: 100%;
  padding: 1.4rem;
  font-size: 1.6rem;
}

.c-form__input:not([type='checkbox']) {
  appearance: none;
}

.c-form__input--message {
  min-height: 15rem;
  flex: 1 1 auto;
  resize: none;
}

.c-form__input--checkbox {
  width: 5rem;
  height: 5rem;
  border: 1px solid #cccccc;
  border-radius: 0;
}

.c-form__row {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .c-form__row {
    margin-bottom: 1.25em;
  }
}

@media (min-width: 768px) {
  .c-form__row:last-child {
    margin-bottom: 0;
  }
}

.c-form__row--full {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c-form__button {
  display: block;
  min-width: 7.37em;
  margin-left: auto;
  font-size: 1.2em;
  font-weight: 400;
}

.woocommerce .c-account-form__remember-box {
  padding-top: 1.5rem;
}

/* stylelint-disable */
.c-free-sample__popup.pum-active,
.c-ncd__popup.pum-active {
  display: flex !important;
  justify-content: center;
}

@media (max-width: 1023.98px) {
  .c-ncd__popup .pum-container,
  .c-free-sample__popup .pum-container {
    left: auto !important;
    right: auto !important;
    margin: 0 auto;
    min-width: 1px !important;
    max-width: 600px !important;
  }
}

@media (max-width: 479.98px) {
  .c-ncd__popup .pum-container,
  .c-free-sample__popup .pum-container {
    padding: 2rem 1rem;
  }
}

.c-free-sample__popup .pum-container .gfield_validation_message {
  background-color: transparent !important;
}

.c-free-sample__popup .pum-container .gform_validation_errors {
  display: none;
}

.c-free-sample__popup .pum-container .gform_confirmation_wrapper {
  background-color: transparent;
  color: #fff !important;
  font-size: 1.8rem !important;
}

.c-free-sample__popup .pum-container .gform_confirmation_wrapper h3 {
  font-size: 1.8rem;
}

.c-free-sample__popup .pum-container .gfield--type-gf-free-sms-verification .firebaseui-resend-container,
.c-free-sample__popup .pum-container .gfield--type-gf-free-sms-verification .firebaseui-id-resend-countdown {
  color: #fff !important;
}

.c-free-sample__popup .pum-container .c-free-sample__summary-section > h3 {
  color: #fff;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.c-free-sample-button__wrap {
  position: fixed;
  z-index: -1;
  bottom: 3%;
  left: 1%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.c-free-sample-button__wrap.active {
  visibility: visible;
  opacity: 1;
  z-index: 999;
}

.c-free-sample-button {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  appearance: none;
  border: none;
  background-color: #71a300;
  color: #fff;
  font-family: "Gotham";
  font-size: 2rem;
  line-height: 3rem;
  border-radius: 5rem;
  box-shadow: 1px 1px 13px -2px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

@media (max-width: 479.98px) {
  .c-free-sample-button {
    padding: 1rem;
  }
}

.c-free-sample-button img {
  width: 3rem;
  height: 3rem;
}

.c-free-sample-button span {
  display: inline-block;
  margin-left: 1rem;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}

@media (max-width: 479.98px) {
  .c-free-sample-button span {
    display: none;
  }
}

.c-free-sample-button-close {
  position: absolute;
  top: -4px;
  right: -6px;
  width: 20px;
  height: 20px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 300;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: #fff;
  color: #fff;
  border: none;
}

.c-free-sample-button-close .o-icon,
.c-free-sample-button-close .o-icon__symbol {
  height: 20px;
  width: 20px;
}

.c-free-sample-button-close svg {
  fill: #71a300;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_step {
  margin: 1rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form.is-page-2 .gform_previous_button {
  display: none !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_page_steps {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_step_number {
  border: 1px solid #fff;
  background-color: #fff;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_step_active .gf_step_number {
  background-color: #fa5746;
  color: #fff;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_step_completed .gf_step_number {
  background-color: #fff;
  color: #fff;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gf_step_completed .gf_step_number::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-width: 1px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gform-body {
  border: none;
  padding: 1rem 1rem 0 1rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gform_page_footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gform_page_footer .button {
  margin-top: 0 !important;
  margin-left: 2rem !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gform_page_footer .button:disabled {
  display: block !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html {
  text-align: center;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html p {
  margin: 0 0 5px;
  font-weight: 500;
  font-size: 1.6rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html p:last-child {
  margin-bottom: 0;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary {
  color: #000;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section {
  margin-bottom: 2.4rem;
  text-align: left;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section:last-child {
  margin-bottom: 0;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section > h3 {
  font-size: 1.8rem;
  text-align: center;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section > div {
  padding: 1rem;
  background-color: #fff;
  border-radius: 3px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section > div p {
  display: flex;
  font-size: 1.5rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section > div p strong {
  min-width: 60px;
  flex-shrink: 0;
  padding-right: 1rem;
}

@media (min-width: 480px) {
  .gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__summary-section > div p strong {
    min-width: 100px;
  }
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product {
  display: flex;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product p {
  display: block !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product p strong {
  min-width: 1px !important;
  padding-right: 0 !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product-image {
  width: 8rem;
  margin-right: 1rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product-image img {
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield_html .c-free-sample__product-title {
  font-size: 1.8rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gform_ajax_spinner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .gfield_label {
  display: block;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-card {
  min-height: 120px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-phone-number {
  margin-top: -1.5rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .gf_google_sms_otp > div {
  float: none;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  background-color: transparent;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .gf_google_sms_otp > div form {
  width: 100%;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-card-header,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-card-actions,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-card-content {
  padding: 0;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-card-header,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-card-footer {
  display: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-text {
  margin-top: 1.75rem;
  margin-bottom: -1rem;
  display: flex;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-text a {
  display: inline-block;
  margin-left: 0.5rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-id-country-selector {
  background-color: #fff;
  border-radius: 3px;
  border: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-error {
  font-size: 12px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-title {
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-phone-number input {
  padding: 1.4rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-info-bar {
  position: relative;
  left: 0;
  right: 0;
  margin-top: 1rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield__label {
  top: 32px;
  left: 15px;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield--floating-label {
  transition: all 300ms ease-in-out;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield--floating-label label {
  padding-top: 0.2rem;
  color: #000;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield--floating-label label::after {
  display: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield--floating-label.is-dirty label,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-textfield--floating-label.is-focused label {
  display: none;
  color: #fff;
  font-size: 1.6rem;
  margin-top: -0.8rem;
  margin-left: -0.5rem;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-resend-container,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-id-resend-countdown {
  color: #000 !important;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-error-wrapper {
  min-height: 0;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions {
  text-align: center;
  position: relative;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions.is-loading .c-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(70px);
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("../assets/images/loader.svg");
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button {
  background-color: #fa5746;
  color: #fff;
  text-transform: none;
  font-family: inherit;
  padding: 0.75em 1.8em !important;
  margin: 0 0.5rem;
  font-size: 1.6rem !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
  letter-spacing: 0.04em !important;
  height: auto !important;
  cursor: pointer;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button:hover, .gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button:focus {
  background-color: #fa4937;
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.5);
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions .c-sms-button-internal {
  display: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions.is-internal .firebaseui-id-submit {
  display: none;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions.is-internal .c-sms-button-internal {
  display: inline-block;
}

.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .mdl-progress.firebaseui-busy-indicator {
  top: 55px;
}

.firebaseui-dialog {
  max-height: 100px !important;
  padding: 1rem !important;
  position: fixed !important;
}

.firebaseui-dialog.firebaseui-list-box-dialog {
  max-height: 200px !important;
}

.firebaseui-dialog[open] {
  display: flex;
  align-items: center;
}

.firebaseui-dialog .firebaseui-list-box-actions {
  width: 100%;
}

/* stylelint-disable */
.gform_wrapper.gravity-theme input[type=color],
.gform_wrapper.gravity-theme input[type=date],
.gform_wrapper.gravity-theme input[type=datetime-local],
.gform_wrapper.gravity-theme input[type=datetime],
.gform_wrapper.gravity-theme input[type=email],
.gform_wrapper.gravity-theme input[type=month],
.gform_wrapper.gravity-theme input[type=number],
.gform_wrapper.gravity-theme input[type=password],
.gform_wrapper.gravity-theme input[type=search],
.gform_wrapper.gravity-theme input[type=tel],
.gform_wrapper.gravity-theme input[type=text],
.gform_wrapper.gravity-theme input[type=time],
.gform_wrapper.gravity-theme input[type=url],
.gform_wrapper.gravity-theme input[type=week],
.gform_wrapper.gravity-theme select,
.gform_wrapper.gravity-theme textarea {
  border: 1px solid #cccccc;
  border-radius: 3px;
  transition: all 300ms ease-in-out;
  background-color: #fff;
  font-size: 1.6rem;
  padding: 1.4rem !important;
}

.gform_wrapper.gravity-theme input[type=color]:hover, .gform_wrapper.gravity-theme input[type=color]:focus,
.gform_wrapper.gravity-theme input[type=date]:hover,
.gform_wrapper.gravity-theme input[type=date]:focus,
.gform_wrapper.gravity-theme input[type=datetime-local]:hover,
.gform_wrapper.gravity-theme input[type=datetime-local]:focus,
.gform_wrapper.gravity-theme input[type=datetime]:hover,
.gform_wrapper.gravity-theme input[type=datetime]:focus,
.gform_wrapper.gravity-theme input[type=email]:hover,
.gform_wrapper.gravity-theme input[type=email]:focus,
.gform_wrapper.gravity-theme input[type=month]:hover,
.gform_wrapper.gravity-theme input[type=month]:focus,
.gform_wrapper.gravity-theme input[type=number]:hover,
.gform_wrapper.gravity-theme input[type=number]:focus,
.gform_wrapper.gravity-theme input[type=password]:hover,
.gform_wrapper.gravity-theme input[type=password]:focus,
.gform_wrapper.gravity-theme input[type=search]:hover,
.gform_wrapper.gravity-theme input[type=search]:focus,
.gform_wrapper.gravity-theme input[type=tel]:hover,
.gform_wrapper.gravity-theme input[type=tel]:focus,
.gform_wrapper.gravity-theme input[type=text]:hover,
.gform_wrapper.gravity-theme input[type=text]:focus,
.gform_wrapper.gravity-theme input[type=time]:hover,
.gform_wrapper.gravity-theme input[type=time]:focus,
.gform_wrapper.gravity-theme input[type=url]:hover,
.gform_wrapper.gravity-theme input[type=url]:focus,
.gform_wrapper.gravity-theme input[type=week]:hover,
.gform_wrapper.gravity-theme input[type=week]:focus,
.gform_wrapper.gravity-theme select:hover,
.gform_wrapper.gravity-theme select:focus,
.gform_wrapper.gravity-theme textarea:hover,
.gform_wrapper.gravity-theme textarea:focus {
  border-color: #41760f;
  outline: none;
}

.c-hamburger-menu {
  position: relative;
  z-index: 101;
  width: 25px;
  height: 25px;
  margin-left: 1rem;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .c-hamburger-menu {
    display: none;
  }
}

.c-hamburger-menu__bar {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 25px;
  height: 2px;
  margin-left: -12.5px;
  margin-top: -1px;
  background: black;
  transition: transform 0.2s;
}

.c-hamburger-menu__bar:nth-child(1) {
  transform: translate3d(0, -8px, 0);
}

.c-hamburger-menu.is-active .c-hamburger-menu__bar:nth-child(1) {
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.c-hamburger-menu__bar:nth-child(2) {
  transform: translate3d(0, 0, 0);
}

.c-hamburger-menu.is-active .c-hamburger-menu__bar:nth-child(2) {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.c-hamburger-menu__bar:nth-child(3) {
  transform: translate3d(0, 8px, 0);
}

.c-hamburger-menu.is-active .c-hamburger-menu__bar:nth-child(3) {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

/* ==========================================================================
   #HEADER
========================================================================== */
.c-header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
}

@media (min-width: 1280px) {
  .c-header {
    position: relative;
    height: auto;
    box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.3);
  }
}

@media (min-width: 1600px) {
  .c-header {
    background-color: transparent;
  }
}

.c-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

@media (min-width: 1280px) {
  .c-header__inner {
    padding: 20px 0 0;
    align-items: inherit;
  }
}

.c-header__col {
  flex: 1 1 auto;
}

.c-header__col--content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 1280px) {
  .c-header__col--content {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.c-header__logo {
  display: block;
  width: clamp(10rem, 14.74vw, 19rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-header__logo {
    width: 100%;
    max-width: 19rem;
  }
}

@media (min-width: 1280px) {
  .c-header__logo {
    padding-bottom: 20px;
  }
}

@media (min-width: 1280px) {
  .c-header__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.c-header__list--account {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  padding-top: 1.3rem;
}

@media (min-width: 1280px) {
  .c-header__list--account {
    margin-left: 4rem;
    padding-top: 0;
  }
}

.c-header__list--contact {
  display: none;
  font-family: "Gotham";
}

@media (min-width: 1280px) {
  .c-header__list--contact {
    display: flex;
    width: 100%;
  }
}

.c-header__list-link {
  display: block;
  color: inherit;
}

.c-header__list--contact .c-header__list-link {
  display: flex;
  padding: 0 0.5rem;
  color: #71a300;
  font-size: 1.4rem;
}

.c-header__list-icon {
  margin-right: 1rem;
  color: #000;
}

.c-header__list--account .c-header__list-item {
  display: block;
  margin-right: 1rem;
}

.c-header__list-item--cart {
  position: relative;
}

.c-header__cart-label {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  line-height: 1em;
  background-color: #fce800;
  transform: translate(50%, -50%);
  border-radius: 50%;
  pointer-events: none;
}

.c-header__group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-hero {
  position: relative;
  background-size: cover;
  background-position: center;
}

.c-hero--decorated {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-hero--decorated {
    overflow: visible;
  }
}

.c-hero--decorated::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 2px;
  display: none;
  width: 100%;
  padding-bottom: 13.84%;
  background-image: url("../assets/images/home-hero-clear-overlap.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateY(100%);
}

@media (min-width: 768px) {
  .c-hero--decorated::after {
    display: block;
  }
}

.c-hero__inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@media (min-width: 1024px) {
  .c-hero--vast .c-hero__inner {
    padding: 5.74vw 0;
  }
}

.c-hero__heading-image {
  display: block;
  width: 83vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-47.1%, -55%);
}

@media (min-width: 480px) {
  .c-hero__heading-image {
    width: 59vw;
    transform: translate(-46.7%, -56%);
  }
}

@media (min-width: 1024px) {
  .c-hero__heading-image {
    width: 49vw;
    transform: translate(-46.4%, -58%);
  }
}

@media (min-width: 1280px) {
  .c-hero__heading-image {
    width: 50vw;
    transform: translate(-46%, -59%);
  }
}

.c-hero__heading-image-wrapper {
  height: 19vw;
}

@media (min-width: 768px) {
  .c-hero__heading-image-wrapper {
    height: 13vw;
  }
}

@media (min-width: 1024px) {
  .c-hero__heading-image-wrapper {
    height: 10vw;
  }
}

.c-hero__decoration {
  position: absolute;
  z-index: 1;
  display: none;
}

@media (min-width: 480px) {
  .c-hero__decoration {
    display: block;
  }
}

.c-hero__decoration--left {
  top: 0;
  left: 0;
  width: 52.71vw;
  transform: translate(-34.5%, -32%);
}

.c-hero__decoration--right {
  bottom: 0;
  right: 0;
  width: 38.75vw;
  transform: translate(0, 31%);
}

.c-hero__headings-content {
  text-align: center;
}

@media (min-width: 1024px) {
  .c-hero__headings-content {
    padding-top: 5.74vw;
  }
}

.c-hero__headings-content--modest {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: "Gotham";
  font-weight: 500;
  color: white;
}

.c-hero__headings-content--complex {
  width: 90vw;
}

@media (min-width: 480px) {
  .c-hero__headings-content--complex {
    width: 65vw;
  }
}

@media (min-width: 1024px) {
  .c-hero__headings-content--complex {
    width: 55vw;
  }
}

.c-hero__heading {
  font-family: "Gotham";
  font-size: clamp(1.6rem, 4.53vw, 7rem);
  color: white;
  font-weight: 500;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-hero__heading {
    font-size: 1.6rem;
  }
  @media (min-width: 768px) {
    .c-hero__heading {
      font-size: 5rem;
    }
  }
  @media (min-width: 1024px) {
    .c-hero__heading {
      font-size: 7rem;
    }
  }
}

.c-hero__heading--title {
  font-size: clamp(1.3rem, 2.5vw, 3.7rem);
  letter-spacing: 0.4em;
  text-transform: uppercase;
  margin-bottom: 0;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-hero__heading--title {
    font-size: 1.3rem;
  }
  @media (min-width: 768px) {
    .c-hero__heading--title {
      font-size: 2.5rem;
    }
  }
  @media (min-width: 1024px) {
    .c-hero__heading--title {
      font-size: 3.7rem;
    }
  }
}

.c-hero__headings-content--complex .c-hero__heading--title {
  margin-top: 5rem;
}

@media (min-width: 1024px) {
  .c-hero__headings-content--complex .c-hero__heading--title {
    margin-top: 0;
  }
}

.c-hero__heading--main {
  font-size: clamp(4rem, 15vw, 20rem);
  letter-spacing: -0.04em;
  font-weight: 300;
  line-height: 1em;
  text-transform: uppercase;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-hero__heading--main {
    font-size: 4rem;
  }
  @media (min-width: 768px) {
    .c-hero__heading--main {
      font-size: 15rem;
    }
  }
  @media (min-width: 1024px) {
    .c-hero__heading--main {
      font-size: 20rem;
    }
  }
}

.c-hero__heading--sub {
  font-size: clamp(1.3rem, 3vw, 3.1rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: 0.4em;
  text-align: right;
  text-transform: uppercase;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-hero__heading--sub {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  @media (min-width: 768px) {
    .c-hero__heading--sub {
      font-size: 2rem;
      margin-bottom: 2em;
    }
  }
  @media (min-width: 1280px) {
    .c-hero__heading--sub {
      font-size: 3.1rem;
      margin-bottom: 10rem;
    }
  }
}

@media (min-width: 1024px) {
  .c-hero__headings-content--complex .c-hero__heading--sub {
    margin-bottom: 3vw;
  }
}

@media (min-width: 1280px) {
  .c-hero__headings-content--complex .c-hero__heading--sub {
    margin-bottom: 5vw;
  }
}

@media (max-width: 767.98px) {
  .c-hero__button {
    font-size: 1rem;
  }
}

.c-info-box {
  height: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Gotham";
  font-size: clamp(1.2rem, 0.97vw, 1.5rem);
  font-weight: 400;
  letter-spacing: -0.02em;
  background-color: #eaf7d6;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-info-box {
    font-size: 1.5rem;
  }
}

@media (min-width: 480px) {
  .c-info-box {
    padding: 2em 2.66em;
  }
}

.c-info-box span,
.c-info-box p {
  font-size: inherit;
  font-weight: inherit;
}

.c-info-box .alignnone {
  max-width: 100%;
  height: auto;
}

.c-info-box p:last-child {
  margin-bottom: 0;
}

.c-info-box--vast {
  font-size: clamp(1.2rem, 1.27vw, 2rem);
  line-height: 1.4em;
}

.c-info-box__heading {
  font-size: clamp(1.5rem, 1.27vw, 2rem);
  font-weight: 500;
  line-height: 1.16em;
  color: #41760f;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-info-box__heading {
    font-size: 2rem;
  }
}

.c-info-box--vast .c-info-box__heading {
  font-size: clamp(1.5rem, 1.7vw, 2.4rem);
  color: #71a300;
}

.c-info-box__link {
  display: block;
  color: #71a300;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.14vw, 1.8rem);
}

.c-list {
  font-size: clamp(1.6rem, 2vw, 2.5rem);
  font-weight: 300;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-list {
    font-size: 1.6rem;
  }
  @media (min-width: 1024px) {
    .c-list {
      font-size: 2.5rem;
    }
  }
}

.c-list--secondary {
  font-family: "Gotham";
}

.c-list--decorated {
  list-style: none;
}

.c-list--inline .c-list__item {
  display: inline-block;
  margin-bottom: 0;
}

.c-list--separated .c-list__item {
  padding-right: 0.5em;
}

.c-list--separated .c-list__item:not(:last-child)::after {
  content: ',';
}

.c-list--decorated .c-list__item {
  display: flex;
}

.c-list--decorated .c-list__item::before {
  content: '•';
  display: block;
  padding-right: 1em;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  visibility: hidden;
  transition: visibility 0.3s ease;
}

.c-main-nav::after {
  content: '';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 1280px) {
  .c-main-nav::after {
    display: none;
  }
}

@media (min-width: 1280px) {
  .c-main-nav {
    position: static;
    visibility: visible;
  }
}

.c-main-nav.is-active {
  visibility: visible;
}

.c-main-nav.is-active::after {
  opacity: 1;
  visibility: visible;
}

.c-main-nav__list {
  height: 100%;
  width: 100%;
  max-width: 35rem;
  padding: 7rem 0 1rem;
  margin: 0 0 0 auto;
  transform: translateX(100%);
  transition: transform 0.5s ease;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #8abe52;
}

@media (min-width: 1280px) {
  .c-main-nav__list {
    display: flex;
    flex-direction: row;
    max-width: inherit;
    padding: 0;
    transform: translateX(0);
    overflow: visible;
    background-color: transparent;
  }
}

.c-main-nav.is-active .c-main-nav__list {
  transform: translateX(0);
}

.c-main-nav__list .mega-menu-wrap {
  flex-grow: 1;
}

.c-main-nav__list .mega-menu-wrap ul {
  min-height: 100% !important;
}

.c-main-nav__item {
  position: relative;
  font-weight: bold;
  list-style: none;
  /* stylelint-disable-next-line selector-class-pattern */
}

@media (min-width: 1280px) {
  .c-main-nav__item {
    padding: 10px 0;
    margin: 0 1em 0 0;
  }
}

.c-main-nav__item.current_page_item, .c-main-nav__item.current-menu-ancestor {
  color: #71a300;
}

.c-main-nav__item.current_page_item::after, .c-main-nav__item.current-menu-ancestor::after {
  position: absolute;
  left: calc(50% - 1.5rem / 2);
  bottom: -0.75rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fce800;
  border-radius: 50%;
  z-index: 1;
}

@media (min-width: 1280px) {
  .c-main-nav__item.current_page_item::after, .c-main-nav__item.current-menu-ancestor::after {
    content: '';
  }
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  display: block;
  padding: 0.5em;
  font-family: "Gotham";
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}

@media (min-width: 1280px) {
  .c-main-nav__link--home {
    display: none;
  }
}

.c-main-nav__item.menu-item-has-children .c-main-nav__link {
  display: flex;
  justify-content: space-between;
}

.c-main-nav__item.menu-item-has-children .c-main-nav__link::after {
  content: '+';
}

@media (min-width: 1280px) {
  .c-main-nav__item.menu-item-has-children .c-main-nav__link::after {
    content: '';
  }
}

.c-main-nav__item.is-open .c-main-nav__link::after {
  content: '-';
}

@media (min-width: 1280px) {
  .c-main-nav__item.is-open .c-main-nav__link::after {
    content: '';
  }
}

.c-main-nav__item:hover .c-main-nav__link, .c-main-nav__link:hover {
  color: #91c800;
}

.c-main-nav__dropdown {
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

@media (max-width: 1279.98px) {
  .c-main-nav__item:not(.is-open) .c-main-nav__dropdown {
    height: 0 !important;
  }
}

@media (min-width: 1280px) {
  .c-main-nav__dropdown {
    position: absolute;
    left: 50%;
    z-index: 1;
    display: none;
    background-color: rgba(80, 121, 33, 0.6);
    white-space: nowrap;
    transform: translate(-50%, 10px);
  }
}

.c-main-nav__dropdown-list {
  margin: 0;
}

@media (min-width: 1280px) {
  .c-main-nav__dropdown-list {
    padding-top: 1rem;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em 1.5em;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 1280px) {
  .c-main-nav__sublink {
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
  }
}

.c-main-nav__sublink:hover {
  background-color: #cff071;
  color: black;
}

/* stylelint-disable selector-class-pattern */
.c-ncd__popup h2 {
  font-size: 2.5rem;
}

.c-ncd__popup h3 {
  font-size: 1.75rem;
}

.c-ncd__popup h4 {
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .c-ncd__popup h2 {
    font-size: 3rem;
  }
  .c-ncd__popup h3 {
    font-size: 2rem;
  }
  .c-ncd__popup h4 {
    font-size: 1.75rem;
  }
}

.c-ncd__popup a {
  text-decoration: underline;
}

.c-ncd__popup a:hover {
  text-decoration: none;
}

.c-ncd__popup .gform_confirmation_message p:last-child {
  margin-bottom: 0;
}

.c-ncd-bar {
  position: relative;
  z-index: -1;
  padding: 0 32px;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.c-ncd-bar.has-multiple-lines {
  padding: 0 50px;
}

@media (min-width: 1024px) {
  .c-ncd-bar {
    padding: 0 48px;
  }
  .c-ncd-bar.has-multiple-lines {
    padding: 0 60px;
  }
}

.c-ncd-bar.is-active {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

.c-ncd-bar__nav {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}

@media (min-width: 1024px) {
  .c-ncd-bar__nav {
    padding: 0 6px;
  }
}

.c-ncd-bar__nav-arrow {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}

.c-ncd-bar__lines {
  position: relative;
  padding: 8px 0;
  display: block;
  text-align: center;
  font-size: 14px;
  z-index: 3;
  font-weight: 500;
  text-decoration: none;
}

@media (min-width: 480px) {
  .c-ncd-bar__lines {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .c-ncd-bar__lines {
    font-size: 18px;
  }
}

.c-ncd-bar__line {
  display: none;
}

.c-ncd-bar__line.is-active {
  display: block;
}

.c-ncd-bar__button-close {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 7px;
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  color: #fff;
}

@media (min-width: 1024px) {
  .c-ncd-bar__button-close {
    right: 15px;
  }
}

.c-ncd-bar__button-close .o-icon,
.c-ncd-bar__button-close .o-icon__symbol {
  height: 22px;
  width: 22px;
}

.c-ncd-bar__button-close svg {
  fill: #000;
}

.has-multiple-lines .c-ncd-bar__button-close {
  right: 25px;
}

@media (min-width: 1024px) {
  .has-multiple-lines .c-ncd-bar__button-close {
    right: 35px;
  }
}

.c-number-input {
  position: relative;
  border: 1px solid #cccccc;
  flex: 1 1 auto;
}

.c-number-input__input {
  appearance: textfield;
  font-family: "Gotham";
  font-size: clamp(1.6rem, 2vw, 2rem);
  border: 0;
  padding: 0.5em 2.25em 0.5em 0.5em;
  width: 100%;
}

.c-number-input__input input {
  width: 100%;
  text-align: left;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-number-input__input {
    font-size: 1.8rem;
  }
}

.c-number-input__input::-webkit-inner-spin-button, .c-number-input__input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.c-number-input__controls {
  position: absolute;
  top: 1px;
  right: 0;
  height: calc(100% - 2px);
}

.c-number-input__control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  width: 4rem;
  height: 2.1rem;
  max-height: 50%;
  cursor: pointer;
}

.c-offer__inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.c-offer__inner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  mix-blend-mode: multiply;
  transition: 0.3s ease;
}

.c-offer__inner:hover::after {
  background-color: var(--hover-color);
}

.c-offer__icon {
  align-self: center;
  font-size: 2.4rem;
  color: #fce800;
  transition: 0.3s ease;
  transform-origin: top;
}

.c-offer:hover .c-offer__icon {
  color: #cff071;
  transform: scale(1.5);
}

.c-offer__heading {
  margin-bottom: 0.3em;
  font-family: "Gotham";
  font-size: clamp(1.4rem, 1.14vw, 1.8rem);
  font-weight: 400;
  line-height: 1em;
  text-transform: uppercase;
  color: white;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-offer__heading {
    font-size: 1.6rem;
  }
}

.c-offer__background-image {
  opacity: 1;
  transition: 0.3s ease;
}

.c-offer:hover .c-offer__background-image {
  opacity: 0.3;
}

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1.6rem;
  margin-top: 1rem;
  border-top: 1px solid #cccccc;
}

.c-pagination__button {
  padding: 0.7em 1em;
  border-radius: 19px;
  font-size: 1.6rem;
  background-color: #cff071;
}

.c-pagination__pages {
  margin: 0 1rem;
}

.c-pagination__page-link {
  padding: 0.5rem;
  margin: 0 0.5rem;
  font-family: "Gotham";
  font-size: 1.7rem;
  color: rgba(0, 0, 0, 0.3);
}

.c-pagination__page-link.is-active {
  color: #000;
}

.c-pdp__form {
  max-width: 31rem;
}

.c-pdp__label {
  display: inline-block;
  width: 6em;
  flex-shrink: 0;
  font-family: "Gotham";
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-weight: 500;
  text-transform: capitalize;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-pdp__label {
    font-size: 2rem;
  }
}

.c-pdp__row {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.product-type-simple .quantity.c-pdp__row {
  float: none !important;
  width: 310px;
}

.c-pdp__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2.8rem;
  cursor: pointer;
  padding: 0.6em 1.95em;
  font-size: clamp(1.5rem, 2vw, 2rem);
  letter-spacing: 0.02em;
  line-height: 1em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-pdp__button {
    font-size: 2rem;
  }
}

.c-pdp__button-icon {
  margin-right: 1.37em;
  font-size: 1.45em;
}

.c-pdp__price {
  margin-bottom: 1.33em;
  font-family: "Gotham";
  font-size: clamp(1.8rem, 1.91vw, 3rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: #8ccd00;
  padding-top: 3rem;
  border-top: 1px solid #cccccc;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-pdp__price {
    font-size: 1.8rem;
  }
  @media (min-width: 768px) {
    .c-pdp__price {
      font-size: 3rem;
    }
  }
}

.c-pdp__price .woocommerce-Price-amount,
.c-pdp__price .woocommerce-Price-currencySymbol,
.c-pdp__price ins {
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;
}

.c-pdp__price del {
  margin-right: 1rem;
}

/* stylelint-disable */
.c-pdp__title {
  padding-bottom: 3rem;
}

.c-pdp__title .product_title {
  margin-bottom: 0.5em;
}

/* stylelint-enable */
.c-pdp__form-wrapper {
  padding-bottom: 3rem;
  border-bottom: 1px solid #cccccc;
}

@media (min-width: 768px) {
  .c-pdp__gallery.woocommerce-product-gallery {
    width: 43% !important;
  }
}

.c-pdp__summary:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (min-width: 768px) {
  .c-pdp__summary {
    width: 49% !important;
  }
}

.c-pdp__content {
  clear: both;
}

.woocommerce .related.products {
  clear: both;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.gallery-icon img {
  max-width: 100%;
  height: auto;
}

.c-product-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-product-feature.extended {
  padding-bottom: 2.5rem;
}

.c-product-feature__inner {
  position: relative;
  height: 100%;
  width: 83%;
  margin: 0 0.5rem 1.6rem;
  padding: 2rem;
  border: 1px solid #cccccc;
  background-color: #fff;
  transition: 0.5s ease;
}

@media (min-width: 768px) {
  .c-product-feature__inner {
    width: calc(100% - 3rem);
  }
}

.c-product-feature__inner:hover {
  border-color: transparent;
  box-shadow: 0 1.5rem 3.2rem rgba(204, 204, 204, 0.6);
}

.c-product-feature__details {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.c-product-feature__name {
  margin: 0;
  font-family: "Gotham";
  font-size: 1.4rem;
  font-weight: 400;
  color: #71a300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-product-feature__name h2 {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
  margin: inherit;
}

.c-product-feature__detail {
  font-family: "Gotham";
  font-size: 1.2rem;
  line-height: 1.5em;
}

.c-product-feature__image-wrapper {
  margin-bottom: 1.4rem;
  opacity: 0.4;
  transition: all 0.3s ease;
  pointer-events: none;
}

.c-product-feature.instock .c-product-feature__image-wrapper {
  opacity: 1;
}

.c-product-feature__inner:hover .c-product-feature__image-wrapper {
  opacity: 1;
  background-color: #f0f6e4;
}

.c-product-feature__image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.c-product-feature__cart {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.05em;
  height: 2.05em;
  background-color: #fce800;
  border-radius: 50%;
  font-size: 1.7rem;
  color: #4d4d4d;
  transform: translate(50%, 50%);
  transition: 0.3s ease;
  cursor: pointer;
}

.c-product-feature__inner:hover .c-product-feature__cart {
  background-color: #71a300;
  color: white;
}

.c-product-feature:not(.instock) .c-product-feature__cart {
  background-color: #cccccc;
  color: #fff;
}

.c-product-feature__icons {
  position: relative;
}

.c-product-feature__cart-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
}

.c-product-feature.available .c-product-feature__inner:hover .c-product-feature__cart-icon {
  opacity: 0;
}

.c-product-feature.available .c-product-feature__inner:hover .c-product-feature__cart-icon--active {
  opacity: 1;
}

.c-product-feature__yotpo {
  min-height: 3.5rem;
}

.c-product-feature__yotpo .yotpo-bottomline {
  flex-direction: column-reverse;
}

.c-product-feature__yotpo .text-m {
  color: black !important;
}

.c-product-feature__yotpo .yotpo-icon {
  color: #ffc400 !important;
}

.c-product-placeholder {
  width: 83%;
  margin: auto;
  text-align: center;
  color: #cccccc;
  font-family: "Gotham";
  text-transform: uppercase;
  line-height: 1em;
  font-size: 2.6rem;
}

@media (min-width: 768px) {
  .c-product-placeholder {
    width: calc(100% - 3rem);
  }
}

.c-product-placeholder__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  border: 2px solid #cccccc;
}

.c-product-rating {
  display: flex;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: #ffc400;
}

.c-product-rating--big {
  font-size: clamp(1.5rem, 2vw, 2.5rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-product-rating--big {
    font-size: 1.5rem;
  }
  @media (min-width: 768px) {
    .c-product-rating--big {
      font-size: 2.5rem;
    }
  }
}

.c-product-rating__star {
  margin-right: 0.3rem;
}

.c-product-rating__star.is-empty {
  color: #cccccc;
}

.c-promo-box {
  padding: 2rem;
  background-color: #91c800;
}

.c-promo-box h3 {
  margin-bottom: 1em;
  font-size: clamp(1.5rem, 2.5vw, 3.5rem);
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.1em;
  color: white;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-promo-box h3 {
    font-size: 1.5rem;
  }
  @media (min-width: 1024px) {
    .c-promo-box h3 {
      font-size: 3.5rem;
    }
  }
}

.c-promo-box p {
  font-size: clamp(1.3rem, 2vw, 2.5rem);
  font-weight: 400;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-promo-box p {
    font-size: 1.3rem;
  }
  @media (min-width: 1024px) {
    .c-promo-box p {
      font-size: 2.5rem;
    }
  }
}

.c-rte {
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  line-height: 1.8em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-rte {
    font-size: 1.5rem;
  }
}

.c-rte h1 {
  margin-bottom: 0.37em;
  font-family: "Gotham";
  font-size: clamp(2.5rem, 3.25vw, 5.1rem);
  line-height: 1em;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: clamp(1.5rem, 2vw, 3rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-rte h1 {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    .c-rte h1 {
      font-size: 5.1rem;
    }
  }
}

.c-rte h1--medium {
  font-size: clamp(2.5rem, 3.25vw, 4rem);
  margin-bottom: 1em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-rte h1--medium {
    font-size: 2.5rem;
  }
  @media (min-width: 1024px) {
    .c-rte h1--medium {
      font-size: 4rem;
    }
  }
}

.c-rte h1 em {
  font-style: normal;
  color: #8ccd00;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-rte h1 {
    font-size: 1.5rem;
  }
  @media (min-width: 1024px) {
    .c-rte h1 {
      font-size: 3rem;
    }
  }
}

.c-search-bar {
  position: relative;
  z-index: 1;
  display: flex;
  width: 90%;
  max-width: 76rem;
  margin: auto;
  border-radius: 3.2rem;
  border: 1px solid #cccccc;
  box-shadow: 0 31px 38px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-search-bar {
    font-size: 2rem;
  }
}

.c-search-bar__input {
  flex: 1 1 auto;
  padding: 0.5em;
  border: 0;
  font-family: "Gotham";
  font-size: 1em;
  font-weight: 300;
  line-height: inherit;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .c-search-bar__input {
    padding: 1em;
  }
}

.c-search-bar__input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.c-search-bar__button {
  padding: 0.5em;
  margin: 0;
  font-size: 1.2em;
}

@media (min-width: 1024px) {
  .c-search-bar__button {
    font-size: 1.4em;
  }
}

.c-search-bar__icon {
  margin-left: 0.3em;
}

.c-search-modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
}

.c-search-modal.is-open {
  display: flex;
}

.c-search-modal__dialog {
  position: relative;
  max-width: 86rem;
  width: 100%;
  margin: auto;
}

.c-search-modal__content {
  width: 100%;
}

.c-search-modal__content .o-section {
  margin: 4rem 0 2rem;
}

.c-search-modal__close {
  position: absolute;
  top: 5px;
  right: 5%;
  appearance: none;
  border: none;
  padding: 0;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.c-search-modal__close .o-icon {
  width: 100%;
}

.c-search-modal__close svg {
  width: 100%;
  height: 100%;
  fill: #85b210;
}

.c-select {
  min-width: clamp(14rem, 20vw, 20rem);
  padding: 1rem 3rem 1rem 1.5rem;
  border-radius: 19px;
  border: 1px solid #cccccc;
  font-family: "Gotham";
  font-size: 1.2rem;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  appearance: none;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-select {
    min-width: 14rem;
  }
}

.c-select--square {
  min-width: inherit;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.5em;
  border-radius: 0;
  font-size: clamp(1.6rem, 2vw, 2rem);
  letter-spacing: -0.02em;
  text-transform: inherit;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-select--square {
    font-size: 1.8rem;
  }
}

.c-select__wrapper {
  position: relative;
  display: inline-block;
  flex: 1 0 auto;
}

.c-select__icon {
  position: absolute;
  top: 50%;
  right: 1.7rem;
  font-size: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.c-select--square ~ .c-select__icon {
  right: 1px;
  width: 4rem;
  height: 4.3rem;
  max-height: calc(100% - 2px);
  background-color: #f0f0f0;
}

.c-woocommerce-account__myaccount {
  padding: 3rem;
  background-color: white;
}

.c-woocommerce-account__navigation-list {
  list-style: none;
  color: white;
  font-size: 1.6rem;
}

/* stylelint-disable */
.c-woocommerce-cart__table.shop_table {
  border: 0 !important;
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-woocommerce-cart__table.shop_table {
    font-size: 1.2rem;
  }
  @media (min-width: 768px) {
    .c-woocommerce-cart__table.shop_table {
      font-size: 1.7rem;
    }
  }
}

.c-woocommerce-cart__table.shop_table th,
.c-woocommerce-cart__table.shop_table td {
  font-weight: 400 !important;
}

.c-woocommerce-cart__thumbnail.product-thumbnail {
  display: block !important;
}

.c-woocommerce-cart__thumbnail.product-thumbnail::before {
  content: '' !important;
}

.c-woocommerce-cart__thumbnail.product-thumbnail a {
  display: block;
  text-align: center;
}

.c-woocommerce-cart__thumbnail.product-thumbnail img {
  width: 18rem !important;
}

.woocommerce a.c-woocommerce-cart__remove-button.remove {
  font-size: 3rem !important;
  color: #fa5746 !important;
}

.woocommerce a.c-woocommerce-cart__remove-button.remove:hover, .woocommerce a.c-woocommerce-cart__remove-button.remove:active, .woocommerce a.c-woocommerce-cart__remove-button.remove:focus {
  color: #fa5746 !important;
  background: transparent !important;
}

.c-woocommerce-cart__table .product-quantity,
.c-woocommerce-cart__table .product-price {
  text-align: center;
}

@media (min-width: 1024px) {
  .c-woocommerce-cart__table .product-name {
    max-width: 40rem;
  }
}

@media (max-width: 768px) {
  .c-woocommerce-cart__empty-cell {
    display: none !important;
  }
}

.c-woocommerce-cart__empty-cell:before {
  content: '' !important;
}

.c-woocommerce-cart__table-footer {
  background-color: #e7f2cb;
}

.c-woocommerce-cart__table-footer span {
  font-weight: 400 !important;
}

.c-woocommerce-cart__total {
  text-align: right;
}

@media (min-width: 768px) {
  .c-woocommerce-cart__coupon-wrapper {
    width: 49%;
    margin-left: auto;
  }
}

.c-woocommerce-cart__coupon-label {
  font-family: "Gotham";
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
  font-weight: 400;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-woocommerce-cart__coupon-label {
    font-size: 1.5rem;
  }
}

.c-woocommerce-cart__coupon {
  display: flex;
  margin-bottom: 5rem;
  border: 1px solid #cccccc;
}

.c-woocommerce-cart__coupon-input {
  flex: 1 1 auto;
  width: 100%;
  padding: 2rem;
  border: 0;
  font-size: 1.6rem;
}

.c-woocommerce-cart__coupon-button {
  margin: 0;
  font-weight: 500;
}

.c-woocommerce-cart__button:disabled {
  background-color: #cccccc;
  opacity: 0.5;
}

.c-woocommerce-cart__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 4rem;
  border-top: 1px solid #cccccc;
}

@media (max-width: 768px) {
  .c-woocmmerce-cart__hide-on-mobile {
    display: none !important;
  }
}

/* stylelint-enable */
.c-woocommerce-checkout label {
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 300;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-woocommerce-checkout label {
    font-size: 1.6rem;
  }
}

.c-woocommerce-checkout .woocommerce-checkout-payment label {
  width: auto;
}

.c-woocommerce-checkout input[type='text'],
.c-woocommerce-checkout input[type='email'],
.c-woocommerce-checkout input[type='tel'],
.c-woocommerce-checkout textarea {
  width: 100%;
  padding: 0.9rem;
  border: 1px solid #b3b3b3;
  font-size: 1.6rem;
  background-color: transparent;
  margin-top: 0.5rem;
}

.c-woocommerce-checkout .select2-selection.select2-selection--single {
  padding: 4px;
  box-sizing: content-box;
}

.c-woocommerce-checkout .select2-container--default,
.c-woocommerce-checkout .select2-selection--single,
.c-woocommerce-checkout .select2-selection__arrow {
  top: calc(50% - 13px) !important;
}

.c-woocommerce-checkout .select2-container--default,
.c-woocommerce-checkout .select2-selection--single {
  box-sizing: content-box;
  padding: 0;
}

.c-woocommerce-checkout h3 {
  display: block;
  font-family: "Gotham";
  font-size: clamp(1.3rem, 1.6vw, 2.5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-woocommerce-checkout h3 {
    font-size: 2.5rem;
  }
}

.c-woocommerce-checkout h3--small {
  font-size: clamp(1.3rem, 1.28vw, 2rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-woocommerce-checkout h3--small {
    font-size: 2rem;
  }
}

.c-woocommerce-checkout .c-woocommcerce-checkout__customer-details {
  margin-bottom: 2rem;
}

/* stylelint-disable */
.woocommerce a.o-btn.added::after, .woocommerce .c-contact-form_wrapper a.added.gform_button::after, .c-contact-form_wrapper .woocommerce a.added.gform_button::after,
.woocommerce .c-contact-form_wrapper .gform_page_footer a.added.button::after, .c-contact-form_wrapper .gform_page_footer .woocommerce a.added.button::after,
.woocommerce .pum-container a.added.gform_button::after, .pum-container .woocommerce a.added.gform_button::after,
.woocommerce .pum-container .gform_page_footer a.added.button::after, .pum-container .gform_page_footer .woocommerce a.added.button::after,
.woocommerce .c-free-sample__form a.added.gform_button::after, .c-free-sample__form .woocommerce a.added.gform_button::after,
.woocommerce .c-free-sample__form .gform_page_footer a.added.button::after, .c-free-sample__form .gform_page_footer .woocommerce a.added.button::after,
.woocommerce a.added.added_to_cart::after,
a.o-btn.added::after,
.c-contact-form_wrapper a.added.gform_button::after,
.c-contact-form_wrapper .gform_page_footer a.added.button::after,
.pum-container a.added.gform_button::after,
.pum-container .gform_page_footer a.added.button::after,
.c-free-sample__form a.added.gform_button::after,
.c-free-sample__form .gform_page_footer a.added.button::after,
a.added.added_to_cart::after,
button.o-btn.added::after,
.c-contact-form_wrapper button.added.gform_button::after,
.c-contact-form_wrapper .gform_page_footer button.added.button::after,
.pum-container button.added.gform_button::after,
.pum-container .gform_page_footer button.added.button::after,
.c-free-sample__form button.added.gform_button::after,
.c-free-sample__form .gform_page_footer button.added.button::after,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button.added::after {
  font-family: WooCommerce;
  content: "\e017";
  margin-left: 0.53em;
  vertical-align: bottom;
}

.woocommerce a.o-btn.loading, .woocommerce .c-contact-form_wrapper a.loading.gform_button, .c-contact-form_wrapper .woocommerce a.loading.gform_button,
.woocommerce .c-contact-form_wrapper .gform_page_footer a.loading.button, .c-contact-form_wrapper .gform_page_footer .woocommerce a.loading.button,
.woocommerce .pum-container a.loading.gform_button, .pum-container .woocommerce a.loading.gform_button,
.woocommerce .pum-container .gform_page_footer a.loading.button, .pum-container .gform_page_footer .woocommerce a.loading.button,
.woocommerce .c-free-sample__form a.loading.gform_button, .c-free-sample__form .woocommerce a.loading.gform_button,
.woocommerce .c-free-sample__form .gform_page_footer a.loading.button, .c-free-sample__form .gform_page_footer .woocommerce a.loading.button,
.woocommerce a.loading.added_to_cart,
a.o-btn.loading,
.c-contact-form_wrapper a.loading.gform_button,
.c-contact-form_wrapper .gform_page_footer a.loading.button,
.pum-container a.loading.gform_button,
.pum-container .gform_page_footer a.loading.button,
.c-free-sample__form a.loading.gform_button,
.c-free-sample__form .gform_page_footer a.loading.button,
a.loading.added_to_cart,
button.o-btn.loading,
.c-contact-form_wrapper button.loading.gform_button,
.c-contact-form_wrapper .gform_page_footer button.loading.button,
.pum-container button.loading.gform_button,
.pum-container .gform_page_footer button.loading.button,
.c-free-sample__form button.loading.gform_button,
.c-free-sample__form .gform_page_footer button.loading.button,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button.loading {
  position: relative;
}

.woocommerce a.o-btn.loading::after, .woocommerce .c-contact-form_wrapper a.loading.gform_button::after, .c-contact-form_wrapper .woocommerce a.loading.gform_button::after,
.woocommerce .c-contact-form_wrapper .gform_page_footer a.loading.button::after, .c-contact-form_wrapper .gform_page_footer .woocommerce a.loading.button::after,
.woocommerce .pum-container a.loading.gform_button::after, .pum-container .woocommerce a.loading.gform_button::after,
.woocommerce .pum-container .gform_page_footer a.loading.button::after, .pum-container .gform_page_footer .woocommerce a.loading.button::after,
.woocommerce .c-free-sample__form a.loading.gform_button::after, .c-free-sample__form .woocommerce a.loading.gform_button::after,
.woocommerce .c-free-sample__form .gform_page_footer a.loading.button::after, .c-free-sample__form .gform_page_footer .woocommerce a.loading.button::after,
.woocommerce a.loading.added_to_cart::after,
a.o-btn.loading::after,
.c-contact-form_wrapper a.loading.gform_button::after,
.c-contact-form_wrapper .gform_page_footer a.loading.button::after,
.pum-container a.loading.gform_button::after,
.pum-container .gform_page_footer a.loading.button::after,
.c-free-sample__form a.loading.gform_button::after,
.c-free-sample__form .gform_page_footer a.loading.button::after,
a.loading.added_to_cart::after,
button.o-btn.loading::after,
.c-contact-form_wrapper button.loading.gform_button::after,
.c-contact-form_wrapper .gform_page_footer button.loading.button::after,
.pum-container button.loading.gform_button::after,
.pum-container .gform_page_footer button.loading.button::after,
.c-free-sample__form button.loading.gform_button::after,
.c-free-sample__form .gform_page_footer button.loading.button::after,
.gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button.loading::after {
  font-family: WooCommerce;
  content: "\e01c";
  font-weight: 400;
  line-height: 13px;
  animation: spin 2s linear infinite;
  transform-origin: center;
  width: 14px;
  height: 14px;
  font-size: 13px;
  padding: 0 0 1px 0;
  border: solid 1px transparent;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.53em;
}

.woocommerce a.add_to_cart_button,
.woocommerce button.add_to_cart_button {
  font-size: 1.1rem;
  line-height: 1.1em;
}

.variable-add-to-cart {
  padding: 0 1.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .variable-add-to-cart {
    padding: 0 2rem;
  }
}

.variable-add-to-cart > div {
  width: 100%;
  text-align: left;
}

.variable-add-to-cart label {
  font-size: 13px;
}

.variable-add-to-cart .c-select {
  padding: 0.35rem 2.5rem 0.35rem 1rem;
  outline: none;
  font-size: 15px;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position */
  background-position: right 0.5em top 50%;
  /* icon size, then gradient */
  background-size: 0.75em auto;
}

.variable-add-to-cart .c-select.is-invalid {
  border-color: #fa5746;
}

.variable-add-to-cart .o-btn, .variable-add-to-cart .c-contact-form_wrapper .gform_button, .c-contact-form_wrapper .variable-add-to-cart .gform_button,
.variable-add-to-cart .c-contact-form_wrapper .gform_page_footer .button, .c-contact-form_wrapper .gform_page_footer .variable-add-to-cart .button,
.variable-add-to-cart .pum-container .gform_button, .pum-container .variable-add-to-cart .gform_button,
.variable-add-to-cart .pum-container .gform_page_footer .button, .pum-container .gform_page_footer .variable-add-to-cart .button,
.variable-add-to-cart .c-free-sample__form .gform_button, .c-free-sample__form .variable-add-to-cart .gform_button,
.variable-add-to-cart .c-free-sample__form .gform_page_footer .button, .c-free-sample__form .gform_page_footer .variable-add-to-cart .button, .variable-add-to-cart .gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions button, .gform_wrapper.gravity-theme .c-free-sample__form .gfield--type-gf-free-sms-verification .firebaseui-form-actions .variable-add-to-cart button,
.variable-add-to-cart a.added_to_cart {
  cursor: pointer;
}

/* stylelint-disable */
.c-yotpo__pdp-bottom-line .yotpo-bottomline {
  display: flex;
  flex-direction: column-reverse;
}

.c-yotpo__pdp-bottom-line .yotpo-icon-star,
.c-yotpo__pdp-bottom-line .yotpo-icon-empty-star,
.c-yotpo__pdp-bottom-line .yotpo-icon-half-star {
  color: #ffc400 !important;
  font-size: clamp(1.5rem, 2vw, 2.5rem) !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-yotpo__pdp-bottom-line .yotpo-icon-star,
  .c-yotpo__pdp-bottom-line .yotpo-icon-empty-star,
  .c-yotpo__pdp-bottom-line .yotpo-icon-half-star {
    font-size: 1.5rem !important;
  }
  @media (min-width: 768px) {
    .c-yotpo__pdp-bottom-line .yotpo-icon-star,
    .c-yotpo__pdp-bottom-line .yotpo-icon-empty-star,
    .c-yotpo__pdp-bottom-line .yotpo-icon-half-star {
      font-size: 2.5rem !important;
    }
  }
}

.c-yotpo__pdp-bottom-line a.text-m {
  display: block;
  font-family: "Gotham";
  font-size: clamp(1.3rem, 1.6vw, 2.5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: black !important;
  margin-bottom: 0.5rem;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-yotpo__pdp-bottom-line a.text-m {
    font-size: 2.5rem;
  }
}

.c-yotpo__pdp-bottom-line a.text-m--small {
  font-size: clamp(1.3rem, 1.28vw, 2rem);
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .c-yotpo__pdp-bottom-line a.text-m--small {
    font-size: 2rem;
  }
}

.c-yotpo__reviews .yotpo-icon-star,
.c-yotpo__reviews .yotpo-icon-empty-star,
.c-yotpo__reviews .yotpo-icon-half-star {
  color: #ffc400 !important;
}

.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon {
  color: #ffc400 !important;
  font-size: clamp(1.5rem, 2vw, 2.5rem) !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon {
    font-size: 1.5rem !important;
  }
  @media (min-width: 768px) {
    .yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
    .yotpo .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
    .yotpo .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
    .yotpo .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon {
      font-size: 2.5rem !important;
    }
  }
}

.yotpo .yotpo-bottomline .yotpo-icon-star,
.yotpo .yotpo-bottomline .yotpo-icon-half-star,
.yotpo .yotpo-bottomline .yotpo-icon-empty-star {
  color: #ffc400 !important;
  font-size: clamp(1.5rem, 2vw, 2.5rem) !important;
}

@supports not (width: clamp(1rem, 1rem, 1rem)) {
  .yotpo .yotpo-bottomline .yotpo-icon-star,
  .yotpo .yotpo-bottomline .yotpo-icon-half-star,
  .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
    font-size: 1.5rem !important;
  }
  @media (min-width: 768px) {
    .yotpo .yotpo-bottomline .yotpo-icon-star,
    .yotpo .yotpo-bottomline .yotpo-icon-half-star,
    .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
      font-size: 2.5rem !important;
    }
  }
}

/* stylelint-enable */
/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-align-left {
  margin-right: auto;
  margin-left: 0;
}

.u-align-right {
  margin-right: 0;
  margin-left: auto;
}

.u-align-center {
  margin-left: auto;
  margin-right: auto;
}

.u-ali-c {
  align-items: center;
}

.u-ali-fe {
  align-items: flex-end;
}

.u-juc-fe {
  justify-content: flex-end;
}

.u-juc-sb {
  justify-content: space-between;
}

.u-bd {
  border: 1px solid #cccccc;
}

.u-bdb {
  border-bottom: 1px solid #cccccc;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.u-d-flex {
  display: flex;
}

.u-d-flex-c {
  display: flex;
  flex-direction: column;
}

.u-f-400 {
  font-weight: 400;
}

.u-f-500 {
  font-weight: 500;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-c-black {
  color: black;
}

.u-c-white {
  color: white;
}

.u-c-red {
  color: #fa5746;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-1em {
  margin-bottom: 1em;
}

.u-mb-2 {
  margin-bottom: 2rem;
}

.u-mb-2-5 {
  margin-bottom: 2.5rem;
}

.u-mb-3 {
  margin-bottom: 3rem;
}

.u-mb-3-5 {
  margin-bottom: 3.5rem;
}

.u-mb-4em {
  margin-bottom: 4em;
}

.u-mt-2em {
  margin-top: 2em;
}

.u-mt-4 {
  margin-top: 4rem;
}

.u-mr-0-5 {
  margin-right: 0.5rem;
}

.u-mr-1 {
  margin-right: 1rem;
}

.u-mr-1-5 {
  margin-right: 1.5rem;
}

.u-mr-2 {
  margin-right: 2rem;
}

.u-ml-2 {
  margin-left: 2rem;
}

.u-mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.u-mv-1em {
  margin-top: 1em;
  margin-bottom: 1em;
}

.u-mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.u-mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.u-pb-0-5em {
  padding-bottom: 0.5em;
}

.u-pb-1 {
  padding-bottom: 1rem;
}

.u-pb-3 {
  padding-bottom: 3rem;
}

.u-pb-3-5 {
  padding-bottom: 3.5rem;
}

.u-pt-2em {
  padding-top: 2em;
}

.u-pt-3 {
  padding-top: 3rem;
}

.u-pt-5 {
  padding-top: 5rem;
}

.u-pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.u-pl-1 {
  padding-left: 1rem;
}

.u-not-visible {
  visibility: hidden;
}

.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-mxw-50 {
  max-width: 50rem;
}
