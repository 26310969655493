.c-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1.6rem;
  margin-top: 1rem;
  border-top: 1px solid $color-border;
}

.c-pagination__button {
  padding: 0.7em 1em;
  border-radius: $size-radius-button;
  font-size: 1.6rem;
  background-color: $color-green-2;
}

.c-pagination__pages {
  margin: 0 1rem;
}

.c-pagination__page-link {
  padding: 0.5rem;
  margin: 0 0.5rem;
  font-family: $font-secondary;
  font-size: 1.7rem;
  color: rgba($color-black, 0.3);

  &.is-active {
    color: $color-black;
  }
}
