.c-product-rating {
  display: flex;
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: $color-review-star;

  &--big {
    font-size: clamp(1.5rem, 2vw, 2.5rem);

    @include clamp-supply {
      font-size: 1.5rem;

      @include bp(medium) {
        font-size: 2.5rem;
      }
    }
  }
}

.c-product-rating__star {
  margin-right: 0.3rem;

  &.is-empty {
    color: $color-border;
  }
}
