.u-f-400 {
  font-weight: 400;
}

.u-f-500 {
  font-weight: 500;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-c-black {
  color: black;
}

.u-c-white {
  color: white;
}

.u-c-red {
  color: $color-red;
}
