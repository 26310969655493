/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-align-left {
  margin-right: auto;
  margin-left: 0;
}

.u-align-right {
  margin-right: 0;
  margin-left: auto;
}

.u-align-center {
  margin-left: auto;
  margin-right: auto;
}

.u-ali-c {
  align-items: center;
}

.u-ali-fe {
  align-items: flex-end;
}

.u-juc-fe {
  justify-content: flex-end;
}

.u-juc-sb {
  justify-content: space-between;
}
