.c-promo-box {
  padding: 2rem;
  background-color: $color-green-1;

  h3 {
    margin-bottom: 1em;
    font-size: clamp(1.5rem, 2.5vw, 3.5rem);
    letter-spacing: 0.02em;
    font-weight: 500;
    line-height: 1.1em;
    color: white;

    @include clamp-supply {
      font-size: 1.5rem;

      @include bp(large) {
        font-size: 3.5rem;
      }
    }
  }

  p {
    font-size: clamp(1.3rem, 2vw, 2.5rem);
    font-weight: 400;

    @include clamp-supply {
      font-size: 1.3rem;

      @include bp(large) {
        font-size: 2.5rem;
      }
    }
  }
}
