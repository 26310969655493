.c-rte {
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  line-height: 1.8em;

  @include clamp-supply {
    font-size: 1.5rem;
  }

  h1 {
    @include h1;
    font-size: clamp(1.5rem, 2vw, 3rem);

    @include clamp-supply {
      font-size: 1.5rem;

      @include bp(large) {
        font-size: 3rem;
      }
    }
  }
}
