/* ==========================================================================
   #TABLE
   ========================================================================== */

/**
 * A simple object for manipulating the structure of HTML `table`s.
 */

.o-table {
  width: 100%;
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);

  @include clamp-supply {
    font-size: 1.2rem;

    @include bp(medium) {
      font-size: 1.7rem;
    }
  }

  th {
    font-weight: 400;
    text-align: center;
    padding: 1rem;
  }
}

.o-table__head {
  th {
    border-bottom: 1px solid $color-border;
  }
}

.o-table__body {
  td {
    border-bottom: 1px solid $color-border;
  }
}

.o-table__cell {
  text-align: center;
  padding: 1rem;

  &--name {
    text-align: left;
  }

  &--green {
    background-color: $color-table-accent;
  }
}

/* stylelint-disable max-nesting-depth */
.o-table--responsive {
  @include bp-down(medium) {
    display: block;

    .o-table__body,
    .o-table__foot {
      display: block;

      td {
        border: 0;
      }
    }

    .o-table__head {
      display: none;
    }

    .o-table__row {
      display: block;
      margin-bottom: 2rem;
      border-top: 1px solid $color-border;
    }

    .o-table__cell {
      display: block;

      &:not(.o-table__cell--mobile-full) {
        padding-left: 35%;
        position: relative;

        &::before {
          content: attr(data-th) ': ';
          position: absolute;
          left: 1rem;
        }
      }

      &--name {
        text-align: center;
      }

      &--empty {
        display: none;
      }
    }
  }
}
/* stylelint-enable max-nesting-depth */

/* Equal-width table cells.
   ========================================================================== */

/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */

.o-table--fixed {
  table-layout: fixed;
}
