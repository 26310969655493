.c-hero {
  position: relative;
  background-size: cover;
  background-position: center;

  // Decorations
  &--decorated {
    overflow: hidden;

    @include bp(large) {
      overflow: visible;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 2px;
      display: none;
      width: 100%;
      padding-bottom: 13.84%;
      background-image: url('../assets/images/home-hero-clear-overlap.jpg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(100%);

      @include bp(medium) {
        display: block;
      }
    }
  }
}

.c-hero__inner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .c-hero--vast & {
    @include bp(large) {
      padding: 5.74vw 0;
    }
  }
}

.c-hero__heading-image {
  display: block;
  width: 83vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-47.1%, -55%);

  @include bp(small) {
    width: 59vw;
    transform: translate(-46.7%, -56%);
  }

  @include bp(large) {
    width: 49vw;
    transform: translate(-46.4%, -58%);
  }

  @include bp(xlarge) {
    width: 50vw;
    transform: translate(-46%, -59%);
  }
}

.c-hero__heading-image-wrapper {
  height: 19vw;

  @include bp(medium) {
    height: 13vw;
  }

  @include bp(large) {
    height: 10vw;
  }
}

.c-hero__decoration {
  position: absolute;
  z-index: 1;
  display: none;

  @include bp(small) {
    display: block;
  }

  &--left {
    top: 0;
    left: 0;
    width: 52.71vw;
    transform: translate(-34.5%, -32%);
  }

  &--right {
    bottom: 0;
    right: 0;
    width: 38.75vw;
    transform: translate(0, 31%);
  }
}

.c-hero__headings-content {
  text-align: center;

  @include bp(large) {
    padding-top: 5.74vw;
  }

  &--modest {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-family: $font-secondary;
    font-weight: 500;
    color: white;
  }

  &--complex {
    width: 90vw;

    @include bp(small) {
      width: 65vw;
    }

    @include bp(large) {
      width: 55vw;
    }
  }
}

.c-hero__heading {
  font-family: $font-secondary;
  font-size: clamp(1.6rem, 4.53vw, 7rem);
  color: white;
  font-weight: 500;

  @include clamp-supply {
    font-size: 1.6rem;

    @include bp(medium) {
      font-size: 5rem;
    }

    @include bp(large) {
      font-size: 7rem;
    }
  }

  &--title {
    font-size: clamp(1.3rem, 2.5vw, 3.7rem);
    letter-spacing: 0.4em;
    text-transform: uppercase;
    margin-bottom: 0;

    @include clamp-supply {
      font-size: 1.3rem;

      @include bp(medium) {
        font-size: 2.5rem;
      }

      @include bp(large) {
        font-size: 3.7rem;
      }
    }

    .c-hero__headings-content--complex & {
      margin-top: 5rem;

      @include bp(large) {
        margin-top: 0;
      }
    }
  }

  &--main {
    font-size: clamp(4rem, 15vw, 20rem);
    letter-spacing: -0.04em;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;

    @include clamp-supply {
      font-size: 4rem;

      @include bp(medium) {
        font-size: 15rem;
      }

      @include bp(large) {
        font-size: 20rem;
      }
    }
  }

  &--sub {
    font-size: clamp(1.3rem, 3vw, 3.1rem);
    font-weight: 300;
    line-height: 1em;
    letter-spacing: 0.4em;
    text-align: right;
    text-transform: uppercase;

    @include clamp-supply {
      font-size: 1.3rem;
      margin-bottom: 1rem;

      @include bp(medium) {
        font-size: 2rem;
        margin-bottom: 2em;
      }

      @include bp(xlarge) {
        font-size: 3.1rem;
        margin-bottom: 10rem;
      }
    }

    .c-hero__headings-content--complex & {
      @include bp(large) {
        margin-bottom: 3vw;
      }

      @include bp(xlarge) {
        margin-bottom: 5vw;
      }
    }
  }
}

.c-hero__button {
  @include bp-down(medium) {
    font-size: 1rem;
  }
}
