.c-offer__inner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    mix-blend-mode: multiply;
    transition: 0.3s ease;
  }

  &:hover {
    &::after {
      background-color: var(--hover-color);
    }
  }
}

.c-offer__icon {
  align-self: center;
  font-size: 2.4rem;
  color: $color-secondary;
  transition: 0.3s ease;
  transform-origin: top;

  .c-offer:hover & {
    color: $color-green-2;
    transform: scale(1.5);
  }
}

.c-offer__heading {
  margin-bottom: 0.3em;
  font-family: $font-secondary;
  font-size: clamp(1.4rem, 1.14vw, 1.8rem);
  font-weight: 400;
  line-height: 1em;
  text-transform: uppercase;
  color: white;

  @include clamp-supply {
    font-size: 1.6rem;
  }
}

.c-offer__background-image {
  opacity: 1;
  transition: 0.3s ease;

  .c-offer:hover & {
    opacity: 0.3;
  }
}
