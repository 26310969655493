.c-cart__image {
  width: 18rem;
  margin: auto;
}

.c-cart__remove-button {
  margin: 0;
  font-size: 3rem;
  color: $color-tertiary;
}

.c-cart__additions {
  padding-bottom: clamp(2rem, 5.1vw, 8rem);
  margin-top: clamp(2rem, 3.82vw, 6rem);
  margin-bottom: clamp(1rem, 2.55vw, 4rem);
  border-bottom: 1px solid $color-border;
  font-size: clamp(1.2rem, 0.89vw, 1.4rem);

  @include clamp-supply {
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;

    @include bp(large) {
      padding-bottom: 8rem;
      margin-top: 6rem;
      margin-bottom: 4rem;
      font-size: 1.4rem;
    }
  }
}

.c-cart__coupon {
  display: flex;
  margin-bottom: 5rem;
  border: 1px solid $color-border;
}

.c-cart__coupon-input {
  flex: 1 1 auto;
  width: 100%;
  padding: 2rem;
  border: 0;
  font-size: 1.6rem;
}

.c-cart__coupon-button {
  margin: 0;
  font-weight: 500;
}

.c-cart__additions-label {
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
  font-weight: 400;

  @include clamp-supply {
    font-size: 1.5rem;
  }
}

.c-cart__buttons {
  text-align: right;
}

.c-cart__button {
  @include bp(medium) {
    min-width: 15.6rem;
  }
}
