.c-form {
  margin-top: 2em;
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 1.27vw, 2rem);
  letter-spacing: -0.02em;
  background-color: white;

  @include clamp-supply {
    font-size: 1.6rem;
  }

  &--plain {
    margin-top: 0;
    background-color: transparent;
  }
}

.c-form__inner {
  padding: 1.5rem;
  border: 1px solid $color-border;

  .c-form--plain & {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include bp(small) {
    padding: 2.5em;
  }
}

.c-form__label {
  display: block;
  margin-bottom: 0.4rem;
}

.c-form__input {
  border-radius: 0;
  border: 1px solid $color-border;
  width: 100%;
  padding: 1.4rem;
  font-size: 1.6rem;

  &:not([type='checkbox']) {
    appearance: none;
  }

  &--message {
    min-height: 15rem;
    flex: 1 1 auto;
    resize: none;
  }

  &--checkbox {
    width: 5rem;
    height: 5rem;
    border: 1px solid $color-border;
    border-radius: 0;
  }
}

.c-form__row {
  margin-bottom: 1.5rem;

  @include bp(medium) {
    margin-bottom: 1.25em;
  }

  &:last-child {
    @include bp(medium) {
      margin-bottom: 0;
    }
  }

  &--full {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.c-form__button {
  display: block;
  min-width: 7.37em;
  margin-left: auto;
  font-size: 1.2em;
  font-weight: 400;
}

.woocommerce .c-account-form__remember-box {
  padding-top: 1.5rem;
}
