/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

$active-marker-size: 1.5rem;

.c-main-nav {
  position: fixed;
  z-index: $index-header;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  visibility: hidden;
  transition: visibility 0.3s ease;

  &::after {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.8);
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;

    @include bp(xlarge) {
      display: none;
    }
  }

  @include bp(xlarge) {
    position: static;
    visibility: visible;
  }

  &.is-active {
    visibility: visible;

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-main-nav__list {
  height: 100%;
  width: 100%;
  max-width: 35rem;
  padding: 7rem 0 1rem;
  margin: 0 0 0 auto;
  transform: translateX(100%);
  transition: transform 0.5s ease;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #8abe52;

  @include bp(xlarge) {
    display: flex;
    flex-direction: row;
    max-width: inherit;
    padding: 0;
    transform: translateX(0);
    overflow: visible;
    background-color: transparent;
  }

  .c-main-nav.is-active & {
    transform: translateX(0);
  }

  .mega-menu-wrap {
    flex-grow: 1;

    ul {
      min-height: 100% !important;
    }
  }
}

.c-main-nav__item {
  position: relative;
  font-weight: bold;
  list-style: none;

  @include bp(xlarge) {
    padding: $size-header-padding / 2 0;
    margin: 0 1em 0 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.current_page_item,
  &.current-menu-ancestor {
    color: $color-primary;

    &::after {
      position: absolute;
      left: calc(50% - #{$active-marker-size} / 2);
      bottom: -$active-marker-size / 2;
      display: block;
      width: $active-marker-size;
      height: $active-marker-size;
      background-color: $color-secondary;
      border-radius: 50%;
      z-index: 1;

      @include bp(xlarge) {
        content: '';
      }
    }
  }

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  display: block;
  padding: 0.5em;
  font-family: $font-secondary;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;

  &--home {
    @include bp(xlarge) {
      display: none;
    }
  }

  .c-main-nav__item.menu-item-has-children & {
    display: flex;
    justify-content: space-between;

    &::after {
      content: '+';

      @include bp(xlarge) {
        content: '';
      }
    }
  }

  .c-main-nav__item.is-open & {
    &::after {
      content: '-';

      @include bp(xlarge) {
        content: '';
      }
    }
  }

  .c-main-nav__item:hover &,
  &:hover {
    color: $color-active-color;
  }
}

.c-main-nav__dropdown {
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  transition: height 0.3s ease;

  .c-main-nav__item:not(.is-open) & {
    @include bp-down(xlarge) {
      height: 0 !important;
    }
  }

  @include bp(xlarge) {
    position: absolute;
    left: 50%;
    z-index: 1;
    display: none;
    background-color: rgba($color-nav-dropdown, 0.6);
    white-space: nowrap;
    transform: translate(-50%, $size-header-padding / 2);
  }
}

.c-main-nav__dropdown-list {
  margin: 0;

  @include bp(xlarge) {
    padding-top: 1rem;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em 1.5em;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgba($color-black, 0.5);

  @include bp(xlarge) {
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
  }

  &:hover {
    background-color: $color-nav-active;
    color: black;
  }
}
