/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: clamp(28rem, 90vw, 114rem);

  @include clamp-supply {
    width: 90%;
    max-width: 114rem;
  }

  /* stylelint-disable length-zero-no-unit */
  &--narrow {
    padding-left: clamp(1px, 3.82vw, 6rem);
    padding-right: clamp(1px, 3.82vw, 6rem);
  }
  /* stylelint-enable length-zero-no-unit */
}
