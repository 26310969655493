.c-product-placeholder {
  width: 83%;
  margin: auto;
  text-align: center;
  color: $color-border;
  font-family: $font-secondary;
  text-transform: uppercase;
  line-height: 1em;
  font-size: 2.6rem;

  @include bp(medium) {
    width: calc(100% - 3rem);
  }
}

.c-product-placeholder__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  border: 2px solid $color-border;
}
