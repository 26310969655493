/* stylelint-disable */

.c-free-sample__popup.pum-active,
.c-ncd__popup.pum-active {
  display: flex !important;
  justify-content: center;
}

.c-ncd__popup .pum-container,
.c-free-sample__popup .pum-container {
  @include bp-down(large) {
    left: auto !important;
    right: auto !important;
    margin: 0 auto;
    min-width: 1px !important;
    max-width: 600px !important;
  }

  @include bp-down(small) {
    padding: 2rem 1rem;
  }
}

.c-free-sample__popup .pum-container {
  // display: block !important;
  // visibility: hidden;
  // //transition: visibility 1s ease-in-out !important;
  // transition: none !important;
  // animation: none;

  .gfield_validation_message {
    background-color: transparent !important;
  }

  .gform_validation_errors {
    display: none;
  }

  .gform_confirmation_wrapper {
    background-color: transparent;
    color: $color-white !important;
    font-size: 1.8rem !important;

    h3 {
      font-size: 1.8rem;
    }
  }

  .gfield--type-gf-free-sms-verification {
    .firebaseui-resend-container,
    .firebaseui-id-resend-countdown {
      color: $color-white !important;
    }
  }

  .c-free-sample__summary-section {
    > h3 {
      color: $color-white;
    }
  }
}

.c-free-sample__popup.pum-active .pum-container {
  //opacity: 1 !important;
  // visibility: visible;
  // animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.c-free-sample-button__wrap {
  position: fixed;
  z-index: -1;
  bottom: 3%;
  left: 1%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }
}

.c-free-sample-button {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  appearance: none;
  border: none;
  background-color: $color-green;
  color: $color-white;
  font-family: $font-secondary;
  font-size: 2rem;
  line-height: 3rem;
  border-radius: 5rem;
  box-shadow: 1px 1px 13px -2px rgba(0, 0, 0, 0.35);
  cursor: pointer;

  @include bp-down(small) {
    padding: 1rem;
  }

  img {
    width: 3rem;
    height: 3rem;
  }

  span {
    display: inline-block;
    margin-left: 1rem;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    @include bp-down(small) {
      display: none;
    }
  }
}

.c-free-sample-button-close {
  position: absolute;
  top: -4px;
  right: -6px;
  width: 20px;
  height: 20px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 300;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: $color-white;
  color: $color-white;
  border: none;

  .o-icon,
  .o-icon__symbol {
    height: 20px;
    width: 20px;
  }

  svg {
    fill: $color-green;
  }
}

.gform_wrapper.gravity-theme {
  .c-free-sample__form {
    .gf_step {
      margin: 1rem;
    }

    &.is-page-2 {
      .gform_previous_button {
        display: none !important;
      }
    }

    .gf_page_steps {
      display: flex;
      justify-content: center;
      border-bottom: none;
    }

    .gf_step_number {
      border: 1px solid $color-white;
      background-color: $color-white;
    }

    .gf_step_active .gf_step_number {
      background-color: $color-button-tertiary;
      color: $color-white;
    }

    .gf_step_completed .gf_step_number {
      background-color: $color-white;
      color: $color-white;

      &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-width: 1px;
      }
    }

    .gform-body {
      border: none;
      padding:  1rem 1rem 0 1rem;
    }

    .gform_page_footer {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .button {
        margin-top: 0 !important;
        margin-left: 2rem !important;
      }

      .button:disabled {
        display: block !important;
      }
    }

    .gfield_html {
      text-align: center;

      p {
        margin: 0 0 5px;
        font-weight: 500;
        font-size: 1.6rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .c-free-sample__summary {
        color: $color-text;
      }

      .c-free-sample__summary-section {
        margin-bottom: 2.4rem;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }

        > h3 {
          font-size: 1.8rem;
          text-align: center;
        }

        > div {
          padding: 1rem;
          background-color: $color-white;
          border-radius: $global-radius;

          p {
            display: flex;
            font-size: 1.5rem;

            strong {
              min-width: 60px;
              flex-shrink: 0;
              padding-right: 1rem;

              @include bp(small) {
                min-width: 100px;
              }
            }
          }
        }
      }

      .c-free-sample__product {
        display: flex;

        p {
          display: block !important;

          strong {
            min-width: 1px !important;
            padding-right: 0 !important;
          }
        }
      }

      .c-free-sample__product-image {
        width: 8rem;
        margin-right: 1rem;

        img {
          border-radius: $global-radius;
          max-width: 100%;
          height: auto;
        }
      }

      .c-free-sample__product-title {
        font-size: 1.8rem;
      }
    }

    .gform_ajax_spinner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -20px;
    }

    .gfield--type-gf-free-sms-verification {
      .gfield_label {
        //display: none;
        display: block;
      }

      .mdl-card {
        min-height: 120px;
      }

      .firebaseui-phone-number {
        margin-top: -1.5rem;
      }

      .gf_google_sms_otp > div {
        float: none;
        width: 100%;
        max-width: 100%;
        box-shadow: none;
        background-color: transparent;

        form {
          width: 100%;
        }
      }

      .firebaseui-card-header,
      .firebaseui-card-actions,
      .firebaseui-card-content {
        padding: 0;
      }

      .firebaseui-card-header,
      .firebaseui-card-footer {
        display: none;
      }

      .firebaseui-text {
        margin-top: 1.75rem;
        margin-bottom: -1rem;
        display: flex;

        a {
          display: inline-block;
          margin-left: 0.5rem;
        }
      }

      .firebaseui-id-country-selector {
        background-color: $color-white;
        border-radius: $global-radius;
        border: none;
      }

      .firebaseui-error {
        font-size: 12px;
      }

      .firebaseui-title {
        margin: 0;
        padding: 0;
        color: $color-white;
        text-align: center;
      }

      .firebaseui-phone-number {
        input {
          padding: 1.4rem;
        }
      }

      .firebaseui-info-bar {
        position: relative;
        left: 0;
        right: 0;
        margin-top: 1rem;
      }

      .mdl-textfield__label {
        top: 32px;
        left: 15px;
      }

      .mdl-textfield--floating-label {
        transition: $global-transition;

        label {
          padding-top: 0.2rem;
          color: $color-black;

          &::after {
            display: none;
          }
        }

        // &.is-dirty,
        // &.is-focused {
        //   margin-top: 0.5rem;
        // }

        &.is-dirty label,
        &.is-focused label {
          display: none;
          color: $color-white;
          font-size: 1.6rem;
          margin-top: -0.8rem;
          margin-left: -0.5rem;
        }
      }

      .firebaseui-resend-container,
      .firebaseui-id-resend-countdown {
        color: $color-text !important;
      }

      .firebaseui-error-wrapper {
        min-height: 0;
      }

      .firebaseui-form-actions {
        text-align: center;
        position: relative;

        &.is-loading .c-loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(70px);
          width: 15px;
          height: 15px;
          display: block;
          background-image: url('../assets/images/loader.svg');
        }

        button {
          @extend .o-btn;
          background-color: $color-button-tertiary;
          color: $color-white;
          text-transform: none;
          font-family: inherit;
          padding: 0.75em 1.8em !important;
          margin: 0 0.5rem;
          font-size: 1.6rem !important;
          line-height: 1.2 !important;
          font-weight: 400 !important;
          letter-spacing: 0.04em !important;
          height: auto !important;
          cursor: pointer;

          &:hover,
          &:focus {
            background-color: darken($color-button-tertiary, 3%);
            box-shadow: 0 1rem 1rem -1rem rgba($color-black, 0.5);
          }
        }

        .c-sms-button-internal {
          display: none;
        }

        &.is-internal {
          .firebaseui-id-submit {
            display: none;
          }

          .c-sms-button-internal {
            display: inline-block;
          }
        }
      }

      .mdl-progress.firebaseui-busy-indicator {
        top: 55px;
      }
    }
  }
}

.firebaseui-dialog {
  max-height: 100px !important;
  padding: 1rem !important;
  position: fixed !important;

  &.firebaseui-list-box-dialog {
    max-height: 200px !important;
  }

  &[open] {
    display: flex;
    align-items: center;
  }

  .firebaseui-list-box-actions {
    width: 100%;
  }
}
