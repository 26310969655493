$carousel-item-padding: 0.5rem;

.c-carousel-aside-item {
  height: 100%;
  background-size: cover;

  @include bp(large) {
    margin: 0 $carousel-item-padding;
  }
}

.c-carousel-aside-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 100%;
  margin-left: auto;
  padding-right: 1rem;
  text-align: center;
}

.c-carousel-aside-item__heading {
  margin: 0;
  color: white;
}

.c-carousel-aside-item__code-label {
  font-size: clamp(1.2rem, 1.4vw, 2.2rem);
  color: white;

  @include clamp-supply {
    font-size: 1.2rem;

    @include bp(medium) {
      font-size: 2.2rem;
    }
  }
}

.c-carousel-aside-item__code {
  font-weight: 500;
  letter-spacing: inherit;
}
