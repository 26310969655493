/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 1.3rem;
  line-height: 1.63em;
  font-weight: 300;
  color: white;

  span,
  div,
  p {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}

.c-footer__row {
  @include bp(large) {
    flex-wrap: nowrap;
  }
}

.c-footer__top {
  padding: 4rem 0;
  background-color: $color-footer;
}

.c-footer__lastcol {
  @include bp(large) {
    padding-left: 5rem;
    padding-right: 2rem;
  }
}

.c-footer__navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-footer__navigation-item {
  width: 50%;

  @include bp(medium) {
    width: 33.33%;
  }
}

.c-footer__navigation-link {
  padding-right: 1rem;
  font-weight: 300;
  line-height: 1.76em;
  color: inherit;
}

.c-footer__bottom {
  background-color: darken($color-footer, 10%);
  padding: 2.8rem 0 3.9rem;
}

.c-footer__collection {
  @include bp(medium) {
    display: flex;
    align-items: center;
  }

  &--payments {
    justify-content: flex-end;
  }
}

.c-footer__collection-label {
  display: block;
  padding: 1rem 0;
  font-weight: 500;

  @include bp(medium) {
    padding: 0 1rem 0 0;
  }
}

.c-footer__collection-item {
  margin: 0 5px;

  &:last-child {
    margin-right: 0;
  }

  &--payment {
    max-height: 3rem;
    max-width: 25%;

    &.is-long {
      max-width: 50%;
    }
  }

  &--social {
    display: inline-block;
    padding: 0.5em;
    margin: 0 0.3rem;
    border: 1px solid currentColor;
    border-radius: 50%;
    font-size: 1.2rem;
    color: $color-green-1;
  }
}

.c-footer__separator {
  margin: 1rem 0;
  background-color: $color-green-1;
  height: 1px;
}

.c-footer__copyright {
  @include bp(medium) {
    text-align: right;
  }
}

.c-footer__newsletter {
  margin-top: 16px;

  @include bp(large) {
    margin-top: 0;
  }
}

.c-footer__newsletter + .c-footer__disclaimer {
  margin-top: 16px;
}
