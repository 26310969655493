.c-carousel {
  position: relative;

  &--aside {
    @include bp(xlarge) {
      width: 200%;
    }

    &::after {
      @include bp(xlarge) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 50%;
        height: 100%;
        // background: linear-gradient(
        //   90deg,
        //   transparent 0%,
        //   rgba($color-white, 0.8) 35%,
        //   $color-white 50%
        // );
        background-color: white;
        pointer-events: none;
      }
    }
  }
}

.c-carousel__arrow {
  position: absolute;
  top: 50%;
  z-index: 5;
  padding: 1rem;
  border: 0;

  font-size: 1.8rem;
  background-color: transparent;
  appearance: none;

  .c-carousel--regular & {
    top: calc(50% - 4rem);
  }

  &--prev {
    left: 0;
    transform: translate(-33%, -50%);

    @include bp(medium) {
      transform: translate(-100%, -50%);
    }
  }

  &--next {
    right: 0;
    transform: translate(33%, -50%);

    @include bp(medium) {
      transform: translate(100%, -50%);
    }

    .c-carousel--aside & {
      @include bp(medium) {
        transform: translate(calc(100% + #{$carousel-item-padding}), -50%);
      }

      @include bp(xlarge) {
        right: 50%;
      }
    }
  }
}
