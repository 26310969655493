.c-search-bar {
  position: relative;
  z-index: 1;
  display: flex;
  width: 90%;
  max-width: 76rem;
  margin: auto;
  border-radius: 3.2rem;
  border: 1px solid $color-border;
  box-shadow: 0 31px 38px 0 rgba($color-black, 0.2);
  font-size: 1.6rem;
  overflow: hidden;

  @include bp(large) {
    font-size: 2rem;
  }
}

.c-search-bar__input {
  flex: 1 1 auto;
  padding: 0.5em;
  border: 0;
  font-family: $font-secondary;
  font-size: 1em;
  font-weight: 300;
  line-height: inherit;
  text-transform: uppercase;

  @include bp(large) {
    padding: 1em;
  }

  &::placeholder {
    color: rgba($color-black, 0.2);
  }
}

.c-search-bar__button {
  padding: 0.5em;
  margin: 0;
  font-size: 1.2em;

  @include bp(large) {
    font-size: 1.4em;
  }
}

.c-search-bar__icon {
  margin-left: 0.3em;
}
