$item-inner-padding: 2rem;

.c-product-feature {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.extended {
    padding-bottom: 2.5rem;
  }
}

.c-product-feature__inner {
  position: relative;
  height: 100%;
  width: 83%;
  margin: 0 $carousel-item-padding 1.6rem;
  padding: $item-inner-padding;
  border: 1px solid $color-border;
  background-color: $color-white;
  transition: 0.5s ease;

  @include bp(medium) {
    width: calc(100% - 3rem);
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0 1.5rem 3.2rem rgba($color-border, 0.6);
  }
}

.c-product-feature__details {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.c-product-feature__name {
  margin: 0;
  font-family: $font-secondary;
  font-size: 1.4rem;
  font-weight: 400;
  color: $color-primary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  h2 {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    margin: inherit;
  }
}

.c-product-feature__detail {
  font-family: $font-secondary;
  font-size: 1.2rem;
  line-height: 1.5em;
}

.c-product-feature__image-wrapper {
  margin-bottom: 1.4rem;
  opacity: 0.4;
  transition: all 0.3s ease;
  pointer-events: none;

  .c-product-feature.instock & {
    opacity: 1;
  }

  .c-product-feature__inner:hover & {
    opacity: 1;
    background-color: $color-product-hover;
  }
}

.c-product-feature__image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.c-product-feature__cart {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.05em;
  height: 2.05em;
  background-color: $color-secondary;
  border-radius: 50%;
  font-size: 1.7rem;
  color: lighten($color: $color-black, $amount: 30%);
  transform: translate(50%, 50%);
  transition: 0.3s ease;
  cursor: pointer;

  .c-product-feature__inner:hover & {
    background-color: $color-primary;
    color: white;
  }

  .c-product-feature:not(.instock) & {
    background-color: lighten($color: $color-black, $amount: 80%);
    color: $color-white;
  }
}

.c-product-feature__icons {
  position: relative;
}

.c-product-feature__cart-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;

  .c-product-feature.available .c-product-feature__inner:hover & {
    opacity: 0;

    &--active {
      opacity: 1;
    }
  }
}

.c-product-feature__yotpo {
  min-height: 3.5rem;

  .yotpo-bottomline {
    flex-direction: column-reverse;
  }

  .text-m {
    color: black !important;
  }

  .yotpo-icon {
    color: $color-review-star !important;
  }
}
