.c-select {
  min-width: clamp(14rem, 20vw, 20rem);
  padding: 1rem 3rem 1rem 1.5rem;
  border-radius: $size-radius-button;
  border: 1px solid $color-border;
  font-family: $font-secondary;
  font-size: 1.2rem;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  appearance: none;

  @include clamp-supply {
    min-width: 14rem;
  }

  &--square {
    min-width: inherit;
    width: 100%;
    padding: 0.5em 2.25em 0.5em 0.5em;
    border-radius: 0;
    font-size: clamp(1.6rem, 2vw, 2rem);
    letter-spacing: -0.02em;
    text-transform: inherit;

    @include clamp-supply {
      font-size: 1.8rem;
    }
  }
}

.c-select__wrapper {
  position: relative;
  display: inline-block;
  flex: 1 0 auto;
}

.c-select__icon {
  position: absolute;
  top: 50%;
  right: 1.7rem;
  font-size: 1rem;
  transform: translateY(-50%);
  pointer-events: none;

  .c-select--square ~ & {
    right: 1px;
    width: 4rem;
    height: 4.3rem;
    max-height: calc(100% - 2px);
    background-color: lighten($color: $color-border, $amount: 14%);
  }
}
