.o-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.o-article__content {
  &--narrow {
    max-width: 79rem;
  }

  &--super-narrow {
    max-width: 66rem;
  }
}

.o-article__verse {
  position: relative;
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  line-height: 1.8em;

  @include clamp-supply {
    font-size: 1.5rem;
  }

  p,
  span,
  div {
    line-height: inherit;
  }

  &--decorated {
    &::after {
      content: '';
      position: absolute;
      bottom: -4.5rem;
      display: block;
      width: 5.2rem;
      height: 1.1rem;
      background-color: $color-primary;
    }
  }
}

/* stylelint-disable */

// .tax-product_cat .o-article__content--super-narrow + .o-article__content--super-narrow {
//   display: none;
// }

// .tax-product_cat .o-article__content--super-narrow > h3 {
//   display: none;
// }

/* stylelint-enable */
