.c-search-modal {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);

  &.is-open {
    display: flex;
  }
}

.c-search-modal__dialog {
  position: relative;
  max-width: 86rem;
  width: 100%;
  margin: auto;
}

.c-search-modal__content {
  width: 100%;

  .o-section {
    margin: 4rem 0 2rem;
  }
}

.c-search-modal__close {
  position: absolute;
  top: 5px;
  right: 5%;
  appearance: none;
  border: none;
  padding: 0;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  .o-icon {
    width: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $color-button-octonary;
  }
}
