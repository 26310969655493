/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: Gotham;
  src: url('../assets/fonts/Gotham-Book.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url('../assets/fonts/Gotham-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url('../assets/fonts/Gotham-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url('../assets/fonts/GothamBold.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
}
