/* stylelint-disable */
.gform_heading {
  display: none;
}

.c-contact-form_wrapper,
.pum-container,
.c-free-sample__form {
  .c-contact-form {
    margin-top: 2em;
    font-family: $font-secondary;
    font-size: clamp(1.2rem, 1.27vw, 2rem);
    letter-spacing: -0.02em;
    background-color: white;

    @include clamp-supply {
      font-size: 1.6rem;
    }
  }

  ul li.gfield {
    padding-right: 0 !important;
    margin-bottom: clamp(1rem, 3vw, 4rem);
    margin-top: 0 !important;

    @include clamp-supply {
      margin-bottom: 1.4rem;

      @include bp(medium) {
        margin-bottom: 4rem;
      }
    }
  }

  .gform_heading {
    display: none;
  }

  .gform_body {
    padding: 1.5rem;
    border: 1px solid $color-border;

    @include bp(small) {
      padding: 2.5em;
    }
  }

  &.c-footer__newsletter-form {
    .gform_body {
      padding: 0;
      border: none;
    }
  }

  .gfield_label {
    display: block;
    margin-bottom: 0.4rem;
    font-weight: 400 !important;
  }

  input[type='text'],
  select {
    width: 100% !important;
    border-radius: 0;
    border: 1px solid $color-border;
    font-size: 1.6rem !important;

    @include bp(medium) {
      padding: 1.4rem !important;
    }
  }

  .gfield_error [aria-invalid=true] {
    border: 1px solid $color-border !important;
  }

  .gfield_error label,
  .gfield_error legend {
    color: $color-text !important;
  }

  input[type='text'] {
    &:not([type='checkbox']) {
      appearance: none;
    }
  }

  .gform_fields {
    display: grid !important;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'input1'
      'input2'
      'input3'
      'message';

    @include bp(medium) {
      grid-template-columns: 1fr 1fr;
      column-gap: 2em;
      grid-template-areas:
        'input1 message'
        'input2 message'
        'input3 message';
    }
  }

  .gform_button,
  .gform_page_footer .button {
    @extend .o-btn;

    display: block !important;
    min-width: 7.37em;
    margin-top: 0.8em !important;
    margin-bottom: 0.8em !important;
    margin-left: auto !important;
    margin-right: 0 !important;
    padding: 0.7em 1.56em;
    font-size: 1.6rem !important;
    font-weight: 400;
    letter-spacing: 0.034em;
    color: $color-white;
    text-transform: none;
    background-color: $color-button-tertiary;
    cursor: pointer;

    &.gform_previous_button {
      color: $color-black !important;
    }

    &:hover,
    &:focus {
      background-color: darken($color-button-tertiary, 3%);
      box-shadow: 0 1rem 1rem -1rem rgba($color-black, 0.5);
    }

    &:disabled {
      background-color: $color-button-tertiary;
      box-shadow: none;
      opacity: 0.6;
      cursor: not-allowed;
    }

    .c-ncd__popup &.gform_previous_button {
      display: none !important;
    }
  }

  .gform_footer {
    margin: 0 !important;
    padding: 0 !important;
  }

  .gform_wrapper .gfield_validation_message,
  .gform_wrapper .validation_message {
    background: transparent;
    border: none !important;
    font-size: 13px !important;
    margin-top: 3px;
    margin-bottom: -5px;
    padding: 0 !important;
  }

  &.c-footer__newsletter-form {
    .gform_wrapper .gfield_validation_message,
    .gform_wrapper .validation_message {
      text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    }
  }

  .gform_wrapper .gform_validation_errors {
    border-radius: 0;
  }

  &.c-footer__newsletter-form {
    .gform_wrapper .gform_validation_errors {
      display: none;
    }
  }
}

@media only screen and (max-width: 641px) {
  .gform_wrapper.gravity-theme input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    line-height: 1.2 !important;
  }
}

.c-ncd__popup {
  .gform_body {
    padding: 0;
    border: none;
  }

  .gform_button {
    margin-left: 0 !important;
    width: 100%;
  }
}

.gform_confirmation_wrapper {
  padding: 10px;
  background: $color-white;
  color: $color-green !important;
  font-weight: 500;
  font-size: 14px !important;
}

.c-contact-form__field {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      grid-area: input#{$i};
    }
  }
}

.c-contact-form__message {
  display: flex;
  flex-direction: column;
  grid-area: message;
  margin-top: 0 !important;
  padding-right: 0 !important;

  .ginput_container {
    flex: 1 1 auto;
  }

  textarea {
    height: 100% !important;
    border-radius: 0;
    border: 1px solid $color-border;
    font-size: 1.6rem !important;

    @include bp(medium) {
      padding: 1.4rem !important;
    }

    &:not([type='checkbox']) {
      appearance: none;
    }
  }
}
/* stylelint-enable */
