/* stylelint-disable */

.c-woocommerce-cart__table.shop_table {
  border: 0 !important;
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);

  @include clamp-supply {
    font-size: 1.2rem;

    @include bp(medium) {
      font-size: 1.7rem;
    }
  }

  th,
  td {
    font-weight: 400 !important;
  }
}

.c-woocommerce-cart__thumbnail.product-thumbnail {
  display: block !important;

  &::before {
    content: '' !important;
  }

  a {
    display: block;
    text-align: center;
  }

  img {
    width: 18rem !important;
  }
}

.woocommerce a.c-woocommerce-cart__remove-button.remove {
  font-size: 3rem !important;
  color: $color-tertiary !important;

  &:hover,
  &:active,
  &:focus {
    color: $color-tertiary !important;
    background: transparent !important;
  }
}

.c-woocommerce-cart__table {
  .product-quantity,
  .product-price {
    text-align: center;
  }

  .product-name {
    @include bp(large) {
      max-width: 40rem;
    }
  }
}

.c-woocommerce-cart__empty-cell {
  @media (max-width: 768px) {
    display: none !important;
  }

  &:before {
    content: '' !important;
  }
}

.c-woocommerce-cart__table-footer {
  background-color: #e7f2cb;
  span {
    font-weight: 400 !important;
  }
}

.c-woocommerce-cart__total {
  text-align: right;
}

.c-woocommerce-cart__coupon-wrapper {
  @include bp(medium) {
    width: 49%;
    margin-left: auto;
  }
}

.c-woocommerce-cart__coupon-label {
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 1.08vw, 1.7rem);
  font-weight: 400;

  @include clamp-supply {
    font-size: 1.5rem;
  }
}

.c-woocommerce-cart__coupon {
  display: flex;
  margin-bottom: 5rem;
  border: 1px solid $color-border;
}

.c-woocommerce-cart__coupon-input {
  flex: 1 1 auto;
  width: 100%;
  padding: 2rem;
  border: 0;
  font-size: 1.6rem;
}

.c-woocommerce-cart__coupon-button {
  margin: 0;
  font-weight: 500;
}

.c-woocommerce-cart__button {
  &:disabled {
    background-color: $color-border;
    opacity: 0.5;
  }
}

.c-woocommerce-cart__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 4rem;
  border-top: 1px solid $color-border;
}

.c-woocmmerce-cart__hide-on-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}
/* stylelint-enable */
