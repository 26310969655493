/* stylelint-disable */

.gform_wrapper.gravity-theme {
  input[type=color],
  input[type=date],
  input[type=datetime-local],
  input[type=datetime],
  input[type=email],
  input[type=month],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  input[type=week],
  select,
  textarea {
    border: 1px solid $color-border;
    border-radius: $global-radius;
    transition: $global-transition;
    background-color: $color-white;
    font-size: 1.6rem;
    padding: 1.4rem !important;

    &:hover,
    &:focus {
      border-color: $color-green-3;
      outline: none;
    }
  }
}
