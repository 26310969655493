/* stylelint-disable */
.c-yotpo__pdp-bottom-line {
  .yotpo-bottomline {
    display: flex;
    flex-direction: column-reverse;
  }

  .yotpo-icon-star,
  .yotpo-icon-empty-star,
  .yotpo-icon-half-star {
    color: $color-review-star !important;
    font-size: clamp(1.5rem, 2vw, 2.5rem) !important;

    @include clamp-supply {
      font-size: 1.5rem !important;

      @include bp(medium) {
        font-size: 2.5rem !important;
      }
    }
  }

  a.text-m {
    @include h6;
    color: black !important;
    margin-bottom: 0.5rem;
  }
}

.c-yotpo__reviews {
  .yotpo-icon-star,
  .yotpo-icon-empty-star,
  .yotpo-icon-half-star {
    color: $color-review-star !important;
  }
}

.yotpo {
  .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
  .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon {
    color: $color-review-star !important;
    font-size: clamp(1.5rem, 2vw, 2.5rem) !important;

    @include clamp-supply {
      font-size: 1.5rem !important;

      @include bp(medium) {
        font-size: 2.5rem !important;
      }
    }
  }

  .yotpo-bottomline {
    .yotpo-icon-star,
    .yotpo-icon-half-star,
    .yotpo-icon-empty-star {
      color: $color-review-star !important;
      font-size: clamp(1.5rem, 2vw, 2.5rem) !important;

      @include clamp-supply {
        font-size: 1.5rem !important;

        @include bp(medium) {
          font-size: 2.5rem !important;
        }
      }
    }
  }
}
/* stylelint-enable */
