p,
span,
article {
  font-size: clamp(1.3rem, 1vw, 1.5rem);
  font-weight: 300;

  @include clamp-supply {
    font-size: 1.4rem;
  }
}
