.c-number-input {
  position: relative;
  border: 1px solid $color-border;
  flex: 1 1 auto;
}

.c-number-input__input {
  appearance: textfield;
  font-family: $font-secondary;
  font-size: clamp(1.6rem, 2vw, 2rem);
  border: 0;
  padding: 0.5em 2.25em 0.5em 0.5em;
  width: 100%;

  input {
    width: 100%;
    text-align: left;
  }

  @include clamp-supply {
    font-size: 1.8rem;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
}

.c-number-input__controls {
  position: absolute;
  top: 1px;
  right: 0;
  height: calc(100% - 2px);
}

.c-number-input__control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lighten($color: $color-border, $amount: 14%);
  width: 4rem;
  height: 2.1rem;
  max-height: 50%;
  cursor: pointer;
}
