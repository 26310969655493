.c-list {
  font-size: clamp(1.6rem, 2vw, 2.5rem);
  font-weight: 300;
  letter-spacing: -0.02em;

  @include clamp-supply {
    font-size: 1.6rem;

    @include bp(large) {
      font-size: 2.5rem;
    }
  }

  &--secondary {
    font-family: $font-secondary;
  }

  &--decorated {
    list-style: none;
  }
}

.c-list__item {
  .c-list--inline & {
    display: inline-block;
    margin-bottom: 0;
  }

  .c-list--separated & {
    padding-right: 0.5em;

    &:not(:last-child)::after {
      content: ',';
    }
  }

  .c-list--decorated & {
    display: flex;

    &::before {
      content: '•';
      display: block;
      padding-right: 1em;
    }
  }
}
