/* stylelint-disable */

.woocommerce a.o-btn.added::after,
a.o-btn.added::after,
button.o-btn.added::after {
  font-family: WooCommerce;
  content: "\e017";
  margin-left: 0.53em;
  vertical-align: bottom;
}

.woocommerce a.o-btn.loading,
a.o-btn.loading,
button.o-btn.loading {
  position: relative;
}

.woocommerce a.o-btn.loading::after,
a.o-btn.loading::after,
button.o-btn.loading::after {
  font-family: WooCommerce;
  content: "\e01c";
  font-weight: 400;
  line-height: 13px;
  animation: spin 2s linear infinite;
  transform-origin: center;
  width: 14px;
  height: 14px;
  font-size: 13px;
  padding: 0 0 1px 0;
  border: solid 1px transparent;

  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.53em;
}

.woocommerce a.added_to_cart,
a.added_to_cart {
  @extend .o-btn;
}

.woocommerce a.add_to_cart_button,
.woocommerce button.add_to_cart_button {
  font-size: 1.1rem;
  line-height: 1.1em;
}

.variable-add-to-cart {
  padding: 0 1.5rem;
  text-align: center;

  @include bp(medium) {
    padding: 0 2rem;
  }

  > div {
    width: 100%;
    text-align: left;
  }

  label {
    font-size: 13px;
  }

  .c-select {
    padding: 0.35rem 2.5rem 0.35rem 1rem;
    outline: none;
    font-size: 15px;

    box-shadow: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position */
    background-position: right 0.5em top 50%;
    /* icon size, then gradient */
    background-size: 0.75em auto;

    &.is-invalid {
      border-color: $color-red;
    }
  }

  .o-btn {
    cursor: pointer;
  }
}
