/* ==========================================================================
   #HEADER
========================================================================== */
.c-header {
  position: sticky;
  z-index: $index-header;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;

  @include bp(xlarge) {
    position: relative;
    height: auto;
    box-shadow: 0 0 4rem 0 rgba($color-black, 0.3);
  }

  @include bp(full) {
    background-color: transparent;
  }
}

.c-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $size-header-padding / 2 0;

  @include bp(xlarge) {
    padding: $size-header-padding 0 0;
    align-items: inherit;
  }
}

.c-header__col {
  flex: 1 1 auto;

  &--content {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include bp(xlarge) {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

.c-header__logo {
  display: block;
  width: clamp(10rem, 14.74vw, 19rem);

  @include clamp-supply {
    width: 100%;
    max-width: 19rem;
  }

  @include bp(xlarge) {
    padding-bottom: $size-header-padding;
  }
}

.c-header__list {
  @include bp(xlarge) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &--account {
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    padding-top: 1.3rem;

    @include bp(xlarge) {
      margin-left: 4rem;
      padding-top: 0;
    }
  }

  &--contact {
    display: none;
    font-family: $font-secondary;

    @include bp(xlarge) {
      display: flex;
      width: 100%;
    }
  }
}

.c-header__list-link {
  display: block;
  color: inherit;

  .c-header__list--contact & {
    display: flex;
    padding: 0 0.5rem;
    color: $color-green;
    font-size: 1.4rem;
  }
}

.c-header__list-icon {
  margin-right: 1rem;
  color: $color-black;
}

.c-header__list-item {
  .c-header__list--account & {
    display: block;
    margin-right: 1rem;
  }

  &--cart {
    position: relative;
  }
}

.c-header__cart-label {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 2rem;
  height: 2rem;

  line-height: 1em;
  background-color: $color-secondary;
  transform: translate(50%, -50%);
  border-radius: 50%;
  pointer-events: none;
}

.c-header__group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
