.c-pdp__form {
  max-width: 31rem;
}

.c-pdp__label {
  display: inline-block;
  width: 6em;
  flex-shrink: 0;
  font-family: $font-secondary;
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-weight: 500;
  text-transform: capitalize;

  @include clamp-supply {
    font-size: 2rem;
  }
}

.c-pdp__row {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.product-type-simple .quantity.c-pdp__row {
  float: none !important;
  width: 310px;
}

.c-pdp__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2.8rem;
  cursor: pointer;
  padding: 0.6em 1.95em;
  font-size: clamp(1.5rem, 2vw, 2rem);
  letter-spacing: 0.02em;
  line-height: 1em;

  @include clamp-supply {
    font-size: 2rem;
  }
}

.c-pdp__button-icon {
  margin-right: 1.37em;
  font-size: 1.45em;
}

.c-pdp__price {
  @include h2;

  padding-top: 3rem;
  border-top: 1px solid $color-border;
  font-weight: 500;
  color: black;
  text-decoration: none;

  .woocommerce-Price-amount,
  .woocommerce-Price-currencySymbol,
  ins {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: inherit;
  }

  del {
    margin-right: 1rem;
  }
}

/* stylelint-disable */
.c-pdp__title {
  padding-bottom: 3rem;

  .product_title {
    margin-bottom: 0.5em;
  }
}
/* stylelint-enable */

.c-pdp__form-wrapper {
  padding-bottom: 3rem;
  border-bottom: 1px solid $color-border;
}

.c-pdp__gallery.woocommerce-product-gallery {
  @include bp(medium) {
    width: 43% !important;
  }
}

.c-pdp__summary {
  @include clearfix();

  @include bp(medium) {
    width: 49% !important;
  }
}

.c-pdp__content {
  clear: both;
}

.woocommerce .related.products {
  clear: both;
}
