.o-picture {
  position: relative;
  width: 100%;
  height: 100%;

  &--ratio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.o-picture__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &--contain {
    object-fit: contain;
  }
}
