$o-icon__icons: (
  back-arrow: 44/28,
  forward-arrow: 44/28,
);

.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;

  &--inline {
    display: inline-flex;
  }

  @each $icon, $width-ratio in $o-icon__icons {
    &--icon-#{$icon} {
      width: $width-ratio * 1em;
    }
  }
}

.o-icon__symbol {
  display: block;
  width: 100%;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
