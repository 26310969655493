.c-info-box {
  height: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  font-family: $font-secondary;
  font-size: clamp(1.2rem, 0.97vw, 1.5rem);
  font-weight: 400;
  letter-spacing: -0.02em;
  background-color: $color-info-box;

  @include clamp-supply {
    font-size: 1.5rem;
  }

  @include bp(small) {
    padding: 2em 2.66em;
  }

  span,
  p {
    font-size: inherit;
    font-weight: inherit;
  }

  .alignnone {
    max-width: 100%;
    height: auto;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &--vast {
    font-size: clamp(1.2rem, 1.27vw, 2rem);
    line-height: 1.4em;
  }
}

.c-info-box__heading {
  font-size: clamp(1.5rem, 1.27vw, 2rem);
  font-weight: 500;
  line-height: 1.16em;
  color: $color-green-3;

  @include clamp-supply {
    font-size: 2rem;
  }

  .c-info-box--vast & {
    font-size: clamp(1.5rem, 1.7vw, 2.4rem);
    color: $color-primary;
  }
}

.c-info-box__link {
  display: block;
  color: $color-primary;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.14vw, 1.8rem);
}
