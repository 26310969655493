.o-btn {
  display: inline-block;
  padding: 1.1em 1.56em;
  margin: 0.8em 0;
  border: 0;

  font-family: $font-secondary;
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: 0.34em;
  color: currentColor;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  appearance: none;
  transition: 0.3s ease;
  vertical-align: middle;

  // Colors
  &--primary {
    background-color: $color-button-primary;
  }

  &--secondary {
    background-color: $color-button-secondary;
  }

  &--tertiary {
    background-color: $color-button-tertiary;
    color: white;
  }

  &--quaternary {
    background-color: $color-button-quaternary;
    color: white;
  }

  &--quinary {
    background-color: $color-button-quinary;
  }

  &--septenary {
    font-family: $font-sans-serif;
    font-size: clamp(1.2rem, 0.9vw, 1.4rem);
    font-weight: 300;
    letter-spacing: 0.08em;
    background-color: $color-button-septenary;
    color: $color-black;

    @include clamp-supply {
      font-size: 1.4rem;
    }
  }

  &--octonary {
    font-family: $font-sans-serif;
    font-size: clamp(1.2rem, 0.9vw, 1.4rem);
    font-weight: 300;
    letter-spacing: 0.08em;
    background-color: $color-button-octonary;
    color: $color-white;

    @include clamp-supply {
      font-size: 1.4rem;
    }
  }

  // Shapes
  &--round {
    border-radius: $size-radius-button;
  }

  // Displays
  &--humble {
    padding: 0.7em 1.3em;
    font-size: clamp(1.2rem, 1.3vw, 1.6rem);
    font-weight: 300;
    text-transform: unset;
    letter-spacing: unset;

    @include clamp-supply {
      font-size: 1.6rem;
    }
  }

  // Sizes
  &--narrow {
    padding: 0.5rem;
  }

  &--short {
    padding: 0.7em 1.56em;
  }

  &--big {
    font-size: clamp(1.4rem, 1.27vw, 2rem);

    @include clamp-supply {
      font-size: 1.4rem;

      @include bp(medium) {
        font-size: 2rem;
      }
    }
  }
}

// Hover
/* stylelint-disable selector-no-qualifying-type */
a.o-btn,
button.o-btn,
input[type='submit'].o-btn {
  &:hover,
  &:active,
  &:focus {
    border-radius: 0;
    box-shadow: 0 1rem 1rem -1rem rgba($color-black, 0.5);
  }
}
/* stylelint-enable selector-no-qualifying-type */
