@mixin h1 {
  margin-bottom: 0.37em;
  font-family: $font-secondary;
  font-size: clamp(2.5rem, 3.25vw, 5.1rem);
  line-height: 1em;
  font-weight: 500;
  letter-spacing: -0.02em;

  @include clamp-supply {
    font-size: 2.5rem;

    @include bp(large) {
      font-size: 5.1rem;
    }
  }

  &--medium {
    font-size: clamp(2.5rem, 3.25vw, 4rem);
    margin-bottom: 1em;

    @include clamp-supply {
      font-size: 2.5rem;

      @include bp(large) {
        font-size: 4rem;
      }
    }
  }

  em {
    font-style: normal;
    color: $color-heading-accent;
  }
}

@mixin h2 {
  margin-bottom: 1.33em;
  font-family: $font-secondary;
  font-size: clamp(1.8rem, 1.91vw, 3rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: $color-heading-accent;

  @include clamp-supply {
    font-size: 1.8rem;

    @include bp(medium) {
      font-size: 3rem;
    }
  }
}

@mixin h3 {
  font-family: $font-secondary;
  font-size: clamp(2rem, 3.25vw, 5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;

  @include clamp-supply {
    font-size: 2rem;

    @include bp(large) {
      font-size: 5em;
    }
  }

  &--medium {
    font-size: clamp(2.5rem, 3.25vw, 4rem);

    @include clamp-supply {
      font-size: 2.5rem;

      @include bp(large) {
        font-size: 4rem;
      }
    }
  }

  em,
  b {
    color: $color-red;
    font-weight: 500;
  }
}

@mixin h4 {
  display: block;
  font-size: clamp(1.3rem, 1.28vw, 2rem);
  font-weight: 400;
  letter-spacing: -0.02em;

  @include clamp-supply {
    font-size: 2rem;
  }
}

@mixin h5 {
  font-size: clamp(1.3rem, 1.47vw, 2.7rem);
  font-weight: 400;
  color: $color-green-3;
  letter-spacing: 0.02em;

  @include clamp-supply {
    font-size: 2.7rem;
  }

  &--small {
    font-size: clamp(1.3rem, 1.47vw, 2rem);
    line-height: 1.3em;
    letter-spacing: -0.02em;

    @include clamp-supply {
      font-size: 2rem;
    }
  }
}

@mixin h6 {
  display: block;
  font-family: $font-secondary;
  font-size: clamp(1.3rem, 1.6vw, 2.5rem);
  font-weight: 300;
  line-height: 1em;
  letter-spacing: -0.02em;

  @include clamp-supply {
    font-size: 2.5rem;
  }

  &--small {
    font-size: clamp(1.3rem, 1.28vw, 2rem);

    @include clamp-supply {
      font-size: 2rem;
    }
  }
}
