/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */
$bpname: map_keys($breakpoints);
$size-gap: 0.5rem;

.o-layout {
  display: block;
  flex-wrap: wrap;
  min-width: 100%;

  &--mobile {
    display: flex;
  }

  &--mobile-c-reversed {
    display: flex;

    @include bp-down(medium) {
      flex-direction: column-reverse;
    }
  }

  @include bp(medium) {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;

  @for $i from 0 through 100 {
    &--#{$i} {
      width: round(percentage($i/100));
    }
  }

  @each $bp in $bpname {
    &--#{$bp}-down-hide {
      @include bp-down($bp) {
        display: none;
      }
    }

    @for $i from 0 through 100 {
      &--#{$bp}-#{$i} {
        /* stylelint-disable-next-line max-nesting-depth */
        @include bp($bp) {
          width: round(percentage($i/100));
        }
      }
    }
  }
}

.o-layout--guttered {
  justify-content: space-between;
  margin: 0 -#{$size-gap};

  > .o-layout__item {
    flex: none;
    padding: $size-gap;
  }
}
