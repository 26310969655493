$section-basic-top-spacing: clamp(2.4rem, 38vw, 6rem);
$section-basic-bottom-spacing: clamp(4rem, 69vw, 10rem);

.o-section {
  margin-bottom: $section-basic-bottom-spacing;
  margin-top: $section-basic-top-spacing;

  @include clamp-supply {
    margin-bottom: 2.4rem;
    margin-top: 4rem;

    @include bp(medium) {
      margin-bottom: 10rem;
      margin-top: 6rem;
    }
  }

  // z-index positioning
  &--upper {
    position: relative;
    z-index: 1;
  }

  // Sizes
  &--tall {
    margin-bottom: clamp(6.8rem, 10.8vw, 17rem);

    @include clamp-supply {
      margin-bottom: 6.8rem;

      @include bp(medium) {
        margin-bottom: 17rem;
      }
    }
  }

  &--giant {
    margin-bottom: clamp(2.4rem, 18.89vw, 29.6rem);

    @include clamp-supply {
      margin-bottom: 2.4rem;

      @include bp(medium) {
        margin-bottom: 29.6rem;
      }
    }
  }

  &--short {
    margin-bottom: clamp(2.4rem, 38vw, 6rem);

    @include clamp-supply {
      margin-bottom: 2.4rem;

      @include bp(medium) {
        margin-bottom: 6rem;
      }
    }
  }

  &--more-top-space {
    margin-top: clamp(4rem, 69vw, 10rem);

    @include clamp-supply {
      margin-top: 4rem;

      @include bp(medium) {
        margin-top: 10rem;
      }
    }
  }

  // Decorations
  &--decorated {
    position: relative;
  }

  &--image {
    position: relative;
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    padding-bottom: $section-basic-bottom-spacing;
    padding-top: $section-basic-top-spacing;
    margin: 0;

    @include clamp-supply {
      padding-bottom: 2.4rem;
      padding-top: 4rem;

      @include bp(medium) {
        padding-bottom: 10rem;
        padding-top: 6rem;
      }
    }
  }

  &--colored {
    padding-bottom: $section-basic-bottom-spacing;
    padding-top: $section-basic-top-spacing;
    margin: 0;
    background-color: var(--bg-color);

    @include clamp-supply {
      padding-bottom: 2.4rem;
      padding-top: 4rem;

      @include bp(medium) {
        padding-bottom: 10rem;
        padding-top: 6rem;
      }
    }
  }
}

.o-section__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.o-section__headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid darken($color-border, 10%);
  margin-bottom: 4.3rem;

  &--white {
    color: white;
    border-color: white;
  }
}

.o-section__decoration {
  position: absolute;
  display: none;
  transform: translate(var(--x), var(--y)) rotate(var(--rotate));
  width: var(--width);
  pointer-events: none;

  &--top-left {
    top: 0;
    left: 0;
  }

  &--top-right {
    top: 0;
    right: 0;
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
  }

  @include bp(large) {
    display: inherit;
  }
}

.home .o-section--upper .o-article {
  position: relative;
  z-index: 100;
  background-color: white;
}

.home .o-section--upper .o-layout__item {
  &:last-child {
    @include bp-down(medium) {
      margin-top: clamp(4rem, 69vw, 10rem);
    }
  }
}
