.c-woocommerce-account__myaccount {
  padding: 3rem;
  background-color: white;
}

.c-woocommerce-account__navigation-list {
  list-style: none;
  color: white;
  font-size: 1.6rem;
}
