.o-ratio-box {
  position: relative;
  z-index: 1;
  height: 0;
  padding-bottom: var(--ratio-percentage-basic);

  @include bp(small) {
    padding-bottom: var(--ratio-percentage-small);
  }

  @include bp(medium) {
    padding-bottom: var(--ratio-percentage-medium);
  }

  @include bp(large) {
    padding-bottom: var(--ratio-percentage-large);
  }

  @include bp(xlarge) {
    padding-bottom: var(--ratio-percentage-xlarge);
  }

  @include bp(full) {
    padding-bottom: var(--ratio-percentage-full);
  }
}

.o-ratio-box__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

 /* stylelint-disable */

.tax-product_cat .u-juc-sb > .o-ratio-box {
  padding: 0;
}

/* stylelint-enable */
