$hamburger-spacing: 8px;
$bar-width: 25px;
$bar-height: 2px;

.c-hamburger-menu {
  position: relative;
  z-index: $index-header + 1;
  width: $bar-width;
  height: $bar-width;
  margin-left: 1rem;
  cursor: pointer;

  @include bp(xlarge) {
    display: none;
  }
}

.c-hamburger-menu__bar {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: $bar-width;
  height: $bar-height;
  margin-left: -$bar-width/2;
  margin-top: -$bar-height/2;
  background: black;
  transition: transform 0.2s;
}

.c-hamburger-menu__bar:nth-child(1) {
  transform: translate3d(0, -$hamburger-spacing, 0);

  .c-hamburger-menu.is-active & {
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
}

.c-hamburger-menu__bar:nth-child(2) {
  transform: translate3d(0, 0, 0);

  .c-hamburger-menu.is-active & {
    transform: translate3d(0, 0, 0) scale(0.1, 1);
  }
}

.c-hamburger-menu__bar:nth-child(3) {
  transform: translate3d(0, $hamburger-spacing, 0);

  .c-hamburger-menu.is-active & {
    transform: translate3d(0, 0, 0) rotate(-45deg);
  }
}
