/* stylelint-disable selector-class-pattern */
.c-ncd__popup {
  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  @include bp(large) {
    h2 {
      font-size: 3rem;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.75rem;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .gform_confirmation_message {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.c-ncd-bar {
  position: relative;
  z-index: -1;
  padding: 0 32px;
  visibility: hidden;
  opacity: 0;
  transition: $global-transition;

  &.has-multiple-lines {
    padding: 0 50px;
  }

  @include bp(large) {
    padding: 0 48px;

    &.has-multiple-lines {
      padding: 0 60px;
    }
  }

  &.is-active {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}

.c-ncd-bar__nav {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 2px;

  @include bp(large) {
    padding: 0 6px;
  }
}

.c-ncd-bar__nav-arrow {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: $color-text;
  cursor: pointer;
}

.c-ncd-bar__lines {
  position: relative;
  padding: 8px 0;
  display: block;
  text-align: center;
  font-size: 14px;
  z-index: 3;
  font-weight: 500;
  text-decoration: none;

  @include bp(small) {
    font-size: 16px;
  }

  @include bp(large) {
    font-size: 18px;
  }
}

.c-ncd-bar__line {
  display: none;

  &.is-active {
    display: block;
  }
}

.c-ncd-bar__button-close {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  right: 7px;
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  color: $color-white;

  @include bp(large) {
    right: 15px;
  }

  .o-icon,
  .o-icon__symbol {
    height: 22px;
    width: 22px;
  }

  svg {
    fill: $color-black;
  }

  .has-multiple-lines & {
    right: 25px;

    @include bp(large) {
      right: 35px;
    }
  }
}
