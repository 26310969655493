.c-woocommerce-checkout label {
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 300;

  @include clamp-supply {
    font-size: 1.6rem;
  }
}

.c-woocommerce-checkout .woocommerce-checkout-payment label {
  width: auto;
}

.c-woocommerce-checkout {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    width: 100%;
    padding: 0.9rem;
    border: 1px solid darken($color-border, 10%);
    font-size: 1.6rem;
    background-color: transparent;
    margin-top: 0.5rem;
  }

  .select2-selection.select2-selection--single {
    padding: 4px;
    box-sizing: content-box;
  }

  .select2-container--default,
  .select2-selection--single,
  .select2-selection__arrow {
    top: calc(50% - 13px) !important;
  }

  .select2-container--default,
  .select2-selection--single {
    box-sizing: content-box;
    padding: 0;
  }

  h3 {
    @include h6;
  }

  .c-woocommcerce-checkout__customer-details {
    margin-bottom: 2rem;
  }
}
