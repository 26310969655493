// Margin bottom

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-1em {
  margin-bottom: 1em;
}

.u-mb-2 {
  margin-bottom: 2rem;
}

.u-mb-2-5 {
  margin-bottom: 2.5rem;
}

.u-mb-3 {
  margin-bottom: 3rem;
}

.u-mb-3-5 {
  margin-bottom: 3.5rem;
}

.u-mb-4em {
  margin-bottom: 4em;
}

// Margin top

.u-mt-2em {
  margin-top: 2em;
}

.u-mt-4 {
  margin-top: 4rem;
}

// Margin right

.u-mr-0-5 {
  margin-right: 0.5rem;
}

.u-mr-1 {
  margin-right: 1rem;
}

.u-mr-1-5 {
  margin-right: 1.5rem;
}

.u-mr-2 {
  margin-right: 2rem;
}

// Margin left

.u-ml-2 {
  margin-left: 2rem;
}

// Margin vertical

.u-mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.u-mv-1em {
  margin-top: 1em;
  margin-bottom: 1em;
}

.u-mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.u-mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

// Padding bottom

.u-pb-0-5em {
  padding-bottom: 0.5em;
}

.u-pb-1 {
  padding-bottom: 1rem;
}

.u-pb-3 {
  padding-bottom: 3rem;
}

.u-pb-3-5 {
  padding-bottom: 3.5rem;
}

// Padding top

.u-pt-2em {
  padding-top: 2em;
}

.u-pt-3 {
  padding-top: 3rem;
}

.u-pt-5 {
  padding-top: 5rem;
}

// Padding vertical

.u-pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

// Padding left

.u-pl-1 {
  padding-left: 1rem;
}
