.c-account-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;

  &--green {
    --background-color: #{hex-to-rgb($color-green-form)};
    color: white;
  }

  &--white {
    --background-color: #{hex-to-rgb($color-white-form)};
    color: black;
  }
}

.c-account-form__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3.3rem;
  margin-bottom: 0.9rem;
  font-family: $font-secondary;
  font-size: clamp(2rem, 3vw, 2.5rem);
  background-color: rgba(var(--background-color), 0.45);

  @include clamp-supply {
    font-size: 1.3rem;

    @include bp(large) {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
  }
}

.c-account-form__form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: clamp(1.5rem, 3.83vw, 6rem);
  margin-bottom: 0.9rem;
  background-color: rgba(var(--background-color), 0.65);

  .woocommerce & {
    border-radius: 0 !important;
    border: 0 !important;
    margin-top: 0 !important;
  }

  @include clamp-supply {
    padding: 1.5rem;
  }

  @include bp(xlarge) {
    padding: 4rem 6rem 3rem;
  }

  .c-account-form--white & {
    background-color: rgba(var(--background-color), 0.85);
  }
}

.c-account-form__bottom {
  padding: clamp(1.5rem, 2.56vw, 3rem) clamp(1.5rem, 3vw, 6rem);
  font-size: 1.4rem;
  text-align: center;
  background-color: rgba(var(--background-color), 0.31);

  @include bp(small) {
    display: flex;
    align-items: center;
    text-align: inherit;
  }

  @include clamp-supply {
    padding: 1.5rem;

    @include bp(large) {
      padding: 3rem 6rem;
    }
  }
}

.c-account-form__label {
  display: block;
  width: 100%;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 300;

  @include clamp-supply {
    font-size: 1.6rem;
  }
}

.c-account-form__input {
  width: 100%;
  padding: 0.9rem;
  border: 1px solid darken($color-border, 10%);
  font-size: 1.6rem;
  background-color: transparent;

  .c-account-form--green & {
    color: white;
    border-color: white;
  }
}

.c-account-form__link {
  font-size: 1.4rem;
}

.c-account-form__return {
  display: inline-block;
  margin-left: 1rem;
}

.c-account-form__links {
  display: flex;
  justify-content: center;

  @include bp(small) {
    margin-left: 1rem;
  }

  @include bp(large) {
    margin-left: auto;
  }
}

.c-account-form__bottom-link {
  display: inline-block;
  margin: 0.4rem;
  padding: 1rem 2.3rem;
  font-size: 1.4rem;
  font-weight: 500;

  @include bp(medium) {
    padding: 0.7rem 3rem;
  }

  &--facebook {
    background-color: #2e5dac;
  }

  &--google {
    background-color: #4094d4;
  }
}

.c-account-form__row {
  width: 100% !important;
}
